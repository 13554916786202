import React, { forwardRef, useMemo, useState, useEffect, useCallback } from 'react'
import { useSelector } from 'react-redux'

import PropTypes from 'prop-types'
import { v4 as uuidv4 } from 'uuid'

import debounce from 'lodash/debounce'
import first from 'lodash/first'
import isEmpty from 'lodash/isEmpty'
import isNil from 'lodash/isNil'
import map from 'lodash/map'
import round from 'lodash/round'
import split from 'lodash/split'
import times from 'lodash/times'
import toNumber from 'lodash/toNumber'

import { reloadSchema } from '@smartcoop/forms'
import correctionCropmanagementSchema from '@smartcoop/forms/schemas/management/correctionCropmanagement.schema'
import { useT } from '@smartcoop/i18n'
import { getProducts as getProductsService } from '@smartcoop/services/apis/smartcoopApi/resources/product'
import { getProductGroups as getProductGroupsService } from '@smartcoop/services/apis/smartcoopApi/resources/productGroup'
import { selectModuleIsTechnical } from '@smartcoop/stores/module/selectorModule'
import { selectFamilyGroupAccess, selectFamilyGroupFinancialDataAccess } from '@smartcoop/stores/property/selectorProperty'
import { selectCurrentOwnerExtraAttributes, selectTechnicalFinancialDataAccess } from '@smartcoop/stores/technical/selectorTechnical'
import { selectUserExtraAttributes } from '@smartcoop/stores/user/selectorUser'
import { convertValues, getBaseVolume, getExtenseBaseVolume } from '@smartcoop/utils/functions'
import Button from '@smartcoop/web-components/Button'
import Form from '@smartcoop/web-components/Form'
import InputFloat from '@smartcoop/web-components/InputFloat'
import InputSelect from '@smartcoop/web-components/InputSelect'
import InputUnit from '@smartcoop/web-components/InputUnit'
import RadioGroup from '@smartcoop/web-components/RadioGroup'

import { Divider } from './styles'

const CorrectionForm = forwardRef(({ defaultValues, handleSubmit, readOnly, area }, formRef) => {
  const t = useT()
  const [productGroup, setProductGroup] = useState({})
  const [product, setProduct] = useState('')
  const [items, setItems] = useState(!isEmpty(defaultValues?.formFields) ? defaultValues?.formFields.length: 1)
  const [dose, setDose] = useState({})

  const userExtraAttributes = useSelector(selectUserExtraAttributes)
  const currentOwnerExtraAttributes = useSelector(selectCurrentOwnerExtraAttributes)

  const technicalFinancialDataAccess = useSelector(selectTechnicalFinancialDataAccess)
  const familyGroupFinancialDataAccess = useSelector(selectFamilyGroupFinancialDataAccess)

  const familyGroupAccess = useSelector(selectFamilyGroupAccess)
  const isTechnical = useSelector(selectModuleIsTechnical)

  const extraAttributes = useMemo(() => userExtraAttributes || {}, [userExtraAttributes])

  // eslint-disable-next-line no-nested-ternary
  const shouldDisplayCosts = useMemo(() => familyGroupAccess ? familyGroupFinancialDataAccess : isTechnical ? technicalFinancialDataAccess && currentOwnerExtraAttributes?.enabledCosts : extraAttributes?.enabledCosts,[currentOwnerExtraAttributes, extraAttributes, familyGroupAccess, familyGroupFinancialDataAccess, isTechnical, technicalFinancialDataAccess])

  const applicationModeOptions = useMemo(
    () => (
      [
        {
          value:'linha',
          label: t('line')
        },
        {
          value:'lanco',
          label: t('haul')
        },
        {
          value:'incorporado',
          label:'Incorporado'
        }
      ]
    ),
    [t]
  )

  const unitsOptions = useMemo(
    () => (
      [
        {
          label: 'g/ha',
          value: 'g/ha'
        },
        {
          label: 'kg/ha',
          value: 'kg/ha'
        },
        {
          label: 'mg/ha',
          value: 'mg/ha'
        },
        {
          label: 'l/ha',
          value: 'l/ha'
        },
        {
          label: 'ml/ha',
          value: 'ml/ha'
        },
        {
          label: 'ton/ha',
          value: 'ton/ha'
        }
      ]
    ),
    []
  )

  const productGroupId = useMemo(
    () => (
      productGroup.data?.data.find(item => (
        item.slug === 'corretivos'
      ))
    )?.id
    ,[productGroup]
  )

  const productQueryParams = useMemo(
    () => (!isEmpty(productGroupId) ? { productGroupId, digitalization: true } : { digitalization: true  }),
    [productGroupId]
  )

  useEffect(() => {
    (async () => {
      setProductGroup(await getProductGroupsService({ slug: 'corretivos' }))
    })()
  }, [])

  const onSubmit = useCallback((data) => {
    const updatedData = {
      ...defaultValues,
      ...data,
      formFields: map(data.formFields, (item, key) => ({
        ...item,
        value: item?.value || null,
        valueHA: !isEmpty(item?.valueHA) ? toNumber(item?.valueHA) : null,
        productId: product?.[key]?.id,
        referenceId: defaultValues?.formFields?.[key]?.referenceId || uuidv4()
      }))
    }

    handleSubmit(updatedData)
  }, [defaultValues, handleSubmit, product])

  const handleProductChange = useCallback(
    (selectedItem, item, index) => {
      setProduct(old => {
        const updatedData = { ...old }
        updatedData[index] = item
        return updatedData
      })
    },
    []
  )

  const updateCostValues = useCallback(debounce((value, item, from) => {
    const formUnit = formRef.current.getFieldValue(`formFields[${ item }].unit`)
    const dosePhyntosanitary = formRef.current.getFieldValue(`formFields[${ item }].dose`)

    if(isEmpty(formUnit) || isEmpty(dosePhyntosanitary)) {
      return
    }

    const unit = first(split(formUnit, '/'))
    const convertedCost = convertValues(dosePhyntosanitary, unit, getBaseVolume(unit))

    switch(from) {
      case 'ha':
        formRef.current.getFieldRef(`formFields[${ item }].valueDose`).setValue(round(value/convertedCost, 2))
        formRef.current.getFieldRef(`formFields[${ item }].value`).setValue(round(value*area, 2))
        break
      case 'dose':
        formRef.current.getFieldRef(`formFields[${ item }].valueHA`).setValue(round(value*convertedCost, 2))
        formRef.current.getFieldRef(`formFields[${ item }].value`).setValue(round((value*convertedCost )*area, 2))
        break
      case 'unit':
      case 'formDose':
        // eslint-disable-next-line no-case-declarations
        const doseValue = formRef.current.getFieldValue(`formFields[${ item }].valueDose`)
        formRef.current.getFieldRef(`formFields[${ item }].valueHA`).setValue(round(doseValue*convertedCost, 2))
        break
      default:
        break
    }
  }, 500), [area, formRef])

  return (
    <Form
      ref={ formRef }
      schemaConstructor={ correctionCropmanagementSchema }
      containerStyle={ { height: 'auto' } }
      onSubmit={ onSubmit }
    >
      { times(items, (item) => (
        <div key={ item }>
          {item === 0 ? (
            <div/>
          ): (
            <Divider />
          )}

          {!isEmpty(productGroup) && (
            <>
              <InputSelect
                label={ t('product') }
                name={ `formFields[${ item }].product` }
                options={ getProductsService }
                queryParams={ productQueryParams }
                onChange={ (selectedItem, value) => handleProductChange(selectedItem, value, item) }
                asyncOptionLabelField="shortDescription"
                asyncOptionValueField="slug"
                defaultValue={ defaultValues?.formFields?.[item]?.product || product || '' }
                disabled={ readOnly }
              />

              <div style={ { display: 'flex' } }>
                <div style={ { flex: 1, marginRight: 10 } }>
                  <InputFloat
                    name={ `formFields[${ item }].dose` }
                    label={ t('dose') }
                    maxLength={ 8 }
                    defaultValue={ defaultValues?.formFields?.[item]?.dose || 0 }
                    fullWidth
                    onFieldValueChange={ () => {
                      reloadSchema(formRef, true)
                    } }
                    style={ { marginRight: '5px' } }
                    disabled={ readOnly }
                    onChange={ ({ target: { value } }) => setDose(old => {
                      const currentDose = { ...old }
                      currentDose[item] = old?.[item] || {}
                      currentDose[item].value = value
                      updateCostValues(null, item, 'formDose')
                      return currentDose
                    }) }
                  />
                </div>
                <div style={ { flex: 1 } }>
                  <InputSelect
                    label={ t('unit') }
                    name={ `formFields[${ item }].unit` }
                    options={ unitsOptions }
                    fullWidth
                    defaultValue={ defaultValues?.formFields?.[item]?.unit || '' }
                    style={ { marginLeft: '5px' } }
                    disabled={ readOnly }
                    onChange={ ({ target: { value } }) => setDose(old => {
                      const currentDose = { ...old }
                      currentDose[item].unit = value
                      updateCostValues(null, item, 'unit')
                      return currentDose
                    }) }
                  />
                </div>
              </div>

              <RadioGroup
                style={ { marginBottom: 10 } }
                label={ t('application mode') }
                name={ `formFields[${ item }].applicationMode` }
                options={ applicationModeOptions }
                variant="row"
                defaultValue={ defaultValues?.formFields?.[item]?.applicationMode }
                disabled={ readOnly }
              />
              {shouldDisplayCosts ? (
                <div style={ { display: 'flex', flexDirection: 'column' } }>
                  <div style={ { display: 'flex', flexDirection: 'row' } }>
                    <InputUnit
                      name={ `formFields[${ item }].valueDose` }
                      label={ `${ t('cost by') } ${ getExtenseBaseVolume(dose?.[item]?.unit || defaultValues?.formFields?.[item]?.unit) || 'dose' }` }
                      unit={ `R$/${ getExtenseBaseVolume(dose?.[item]?.unit || defaultValues?.formFields?.[item]?.unit) || 'dose' }` }
                      type="float"
                      fullWidth
                      onChange={ ({ target: { value } }) => updateCostValues(value, item, 'dose') }
                      disabled={ !dose?.[item]?.unit || !dose?.[item]?.value }
                      defaultValue={ !isNil(defaultValues?.formFields) && defaultValues?.formFields[item]?.valueDose }
                      style={ { marginRight: 10 } }
                    />
                    <InputUnit
                      name={ `formFields[${ item }].valueHA` }
                      label={ t('cost by ha') }
                      unit="R$/ha"
                      type="float"
                      fullWidth
                      onChange={ ({ target: { value } }) => updateCostValues(value, item, 'ha') }
                      disabled={ !dose?.[item]?.unit || !dose?.[item]?.value }
                      defaultValue={ !isNil(defaultValues?.formFields) && round(defaultValues?.formFields[item]?.value/area, 2) }
                    />
                  </div>

                  <InputUnit
                    name={ `formFields[${ item }].value` }
                    disabled
                    label={ t('total cost') }
                    unit="R$"
                    type="float"
                    fullWidth
                    defaultValue={ !isNil(defaultValues?.formFields) && defaultValues?.formFields[item]?.value }
                  />
                </div>
              ) : null }
            </>
          )}
        </div>
      ))}
      <div style={ { display: 'flex', justifyContent: 'space-between', marginBottom: '10px' } }>
        <Button
          id="button-remove-item"
          onClick={ () => {
            setItems(items - 1)
            setProduct(old => {
              const current = { ...old }
              delete current[items - 1]
              return current
            })
          } }
          variant="outlined"
          disabled={ items === 1 || readOnly }
          style={ { marginRight: '60px' } }
        >
        -
        </Button>

        <Button
          id="button-add-item"
          onClick={ () => {
            setItems(items + 1)
          } }
          style={ { marginLeft: '60px' } }
          disabled={ readOnly }
        >
        +
        </Button>
      </div>
    </Form>
  )
})

CorrectionForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  area: PropTypes.number.isRequired,
  readOnly: PropTypes.bool,
  defaultValues: PropTypes.object
}

CorrectionForm.defaultProps = {
  defaultValues: {},
  readOnly: false
}

export default CorrectionForm
