/* eslint-disable no-unused-expressions */

import React, { useCallback, forwardRef, useMemo, useState, useEffect } from 'react'
import { useSelector } from 'react-redux'

import moment from 'moment'
import PropTypes from 'prop-types'

import isArray from 'lodash/isArray'
import isEmpty from 'lodash/isEmpty'
import round from 'lodash/round'
import toNumber from 'lodash/toNumber'
import toString from 'lodash/toString'

import registerMonthlyDataHistory from '@smartcoop/forms/schemas/dairyFarm/registerMonthlyDataHistory.schema'
import I18n, { useT } from '@smartcoop/i18n'
import { selectCurrentProperty } from '@smartcoop/stores/property/selectorProperty'
import { colors } from '@smartcoop/styles'
import { momentBackDateFormat, momentBackMonthYearFormat } from '@smartcoop/utils/dates'
import Button from '@smartcoop/web-components/Button'
import Form from '@smartcoop/web-components/Form'
import InputMonthYear from '@smartcoop/web-components/InputMonthYear'
import InputUnit from '@smartcoop/web-components/InputUnit'

import {
  Container,
  FormContainer,
  ButtonsContainer,
  Row
} from './styles'

const RegisterMonthlyDataHistoryForm = forwardRef((props, formRef) => {
  const {
    withoutSubmitButton,
    defaultValues,
    loading,
    onSubmit,
    onCancel,
    shouldDisplayCosts
  } = props

  const t = useT()
  const currentProperty = useSelector(selectCurrentProperty)

  const [calfMonthlyCost, setCalfMonthlyCost] = useState(defaultValues?.rationData?.calfRation?.totalCost ? defaultValues?.rationData?.calfRation?.totalCost/defaultValues?.rationData?.calfRation?.monthlyRation : 0)
  const [calfRation, setCalfRation] = useState(defaultValues?.rationData?.calfRation?.monthlyRation)
  const [prepartumMonthlyCost, setPrepartumMonthlyCost] = useState(defaultValues?.rationData?.prepartumRation?.totalCost ? defaultValues?.rationData?.prepartumRation?.totalCost/defaultValues?.rationData?.prepartumRation?.monthlyRation : 0)
  const [prepartumRation, setPrepartumRation] = useState(defaultValues?.rationData?.prepartumRation?.monthlyRation)
  const [lactationMonthlyCost, setLactationMonthlyCost] = useState(defaultValues?.rationData?.lactationRation?.totalCost ? defaultValues?.rationData?.lactationRation?.totalCost/defaultValues?.rationData?.lactationRation?.monthlyRation : 0)
  const [lactationRation, setLactationRation] = useState(defaultValues?.rationData?.lactationRation?.monthlyRation)

  const dairyFarmId = useMemo(
    () => currentProperty?.dairyFarm[0]?.id,
    [currentProperty]
  )

  const handleSubmit = useCallback(
    (data) => {
      onSubmit({
        propertyId: currentProperty?.id,
        dairyFarmId,
        ...data,
        date: isArray(data.date) ? data.date[0] : data.date,
        rationData: {
          ...data?.rationData,
          calfRation: shouldDisplayCosts ? {
            monthlyRation: toNumber(data?.rationData?.calfRation?.monthlyRation || 0),
            totalCost: toNumber(data?.rationData?.calfRation?.totalCost || 0)
          } : {
            monthlyRation: toNumber(data?.rationData?.calfRation?.monthlyRation || 0)
          },
          prepartumRation: shouldDisplayCosts ? {
            monthlyRation: toNumber(data?.rationData?.prepartumRation?.monthlyRation || 0),
            totalCost: toNumber(data?.rationData?.prepartumRation?.totalCost || 0)
          } : {
            monthlyRation: toNumber(data?.rationData?.prepartumRation?.monthlyRation || 0)
          },
          lactationRation: shouldDisplayCosts ? {
            monthlyRation: toNumber(data?.rationData?.lactationRation?.monthlyRation || 0),
            totalCost: toNumber(data?.rationData?.lactationRation?.totalCost || 0)
          } : {
            monthlyRation: toNumber(data?.rationData?.lactationRation?.monthlyRation || 0)
          }
        }
      })
    },
    [currentProperty, dairyFarmId, onSubmit, shouldDisplayCosts]
  )

  useEffect(() => {
    if(shouldDisplayCosts) {
      const fieldRef = formRef?.current?.getFieldRef('rationData.calfRation.monthlyRation')
      const totalRef = formRef?.current?.getFieldRef('rationData.calfRation.totalCost')
      if(totalRef) {
        if(isEmpty(calfRation) || isEmpty(calfMonthlyCost) || toString(calfRation) === '0' || toString(calfMonthlyCost) === '0') {
          formRef?.current?.getFieldRef('rationData.calfRation.totalCost')?.setValue(0)
        }
        if(fieldRef?.value && calfMonthlyCost) {
          formRef.current.getFieldRef('rationData.calfRation.totalCost')?.setValue(calfRation && calfMonthlyCost ? round(calfRation*calfMonthlyCost, 2) : 0)
        }
      }
    }
  },[calfMonthlyCost, calfRation, formRef, shouldDisplayCosts])

  useEffect(() => {
    if(shouldDisplayCosts) {
      const fieldRef = formRef?.current?.getFieldRef('rationData.prepartumRation.monthlyRation')
      const totalRef = formRef?.current?.getFieldRef('rationData.prepartumRation.totalCost')
      if(totalRef) {
        if(isEmpty(prepartumRation) || isEmpty(prepartumMonthlyCost) || toString(prepartumRation) === '0' || toString(prepartumMonthlyCost) === '0') {
          formRef?.current?.getFieldRef('rationData.prepartumRation.totalCost')?.setValue(0)
        }
        if(fieldRef?.value) {
          formRef.current.getFieldRef('rationData.prepartumRation.totalCost')?.setValue(prepartumRation && prepartumMonthlyCost ? round(prepartumRation*prepartumMonthlyCost, 2) : 0)
        }
      }
    }
  },[formRef, prepartumMonthlyCost, prepartumRation, shouldDisplayCosts])

  useEffect(() => {
    if(shouldDisplayCosts) {
      const fieldRef = formRef?.current?.getFieldRef('rationData.lactationRation.monthlyRation')
      const totalRef = formRef?.current?.getFieldRef('rationData.lactationRation.totalCost')
      if(totalRef) {
        if(isEmpty(lactationRation) || isEmpty(lactationMonthlyCost) || toString(lactationRation) === '0' || toString(lactationMonthlyCost) === '0') {
          formRef?.current?.getFieldRef('rationData.lactationRation.totalCost')?.setValue(0)
        }
        if(fieldRef?.value) {
          formRef.current.getFieldRef('rationData.lactationRation.totalCost')?.setValue(lactationRation && lactationMonthlyCost ? round(lactationRation*lactationMonthlyCost, 2) : 0)
        }
      }
    }
  },[formRef, lactationMonthlyCost, lactationRation, shouldDisplayCosts])

  return (
    <Container>
      <Form
        style={ { display: 'flex', flexDirection: 'column', width: '100%' } }
        ref={ formRef }
        schemaConstructor={ registerMonthlyDataHistory }
        onSubmit={ handleSubmit }
      >
        <FormContainer>
          <Row>
            <InputMonthYear
              name="date"
              label={ `${ t('month', { howMany: 1 }) }/${ t('year', { howMany: 1 }) }` }
              defaultValue={ moment(defaultValues?.date, momentBackDateFormat).format(momentBackMonthYearFormat) }
              disabled={ defaultValues?.id }
              style={ { width: '100%' } }
              fullWidth
            />
          </Row>
          <Row>
            <InputUnit
              name="rationData.calfRation.monthlyRation"
              label={ t('ration for calves') }
              unit="kg/mês"
              type="integer"
              defaultValue={ defaultValues?.rationData?.calfRation?.monthlyRation ? toString(defaultValues?.rationData?.calfRation?.monthlyRation) : null }
              onChange={ ({ target: { value } }) => setCalfRation(value) }
              maxLength={ 8 }
              fullWidth
              style={ { marginRight: 10 } }
            />
            {shouldDisplayCosts ? (
              <InputUnit
                detached
                label={ t('unitary cost') }
                unit="R$/kg"
                type="float"
                value={ calfMonthlyCost }
                onChange={ ({ target: { value } }) => setCalfMonthlyCost(value) }
                fullWidth
                style={ { marginRight: 10 } }
              />
            ) : null}
            {shouldDisplayCosts ? (
              <InputUnit
                name="rationData.calfRation.totalCost"
                label={ t('total cost') }
                unit="R$"
                type="float"
                defaultValue={ toString(defaultValues?.rationData?.calfRation?.totalCost) }
                disabled
                fullWidth
              />
            ) : null}
          </Row>
          <Row>
            <InputUnit
              name="rationData.prepartumRation.monthlyRation"
              label={ t('ration for heifer') }
              unit="kg/mês"
              type="integer"
              defaultValue={ toString(defaultValues?.rationData?.prepartumRation?.monthlyRation) }
              onChange={ ({ target: { value } }) => setPrepartumRation(value) }
              maxLength={ 8 }
              fullWidth
              style={ { marginRight: 10 } }
            />
            {shouldDisplayCosts ? (
              <InputUnit
                detached
                label={ t('unitary cost') }
                unit="R$/kg"
                type="float"
                value={ prepartumMonthlyCost }
                onChange={ ({ target: { value } }) => setPrepartumMonthlyCost(value) }
                fullWidth
                style={ { marginRight: 10 } }
              />
            ) : null}
            {shouldDisplayCosts ? (
              <InputUnit
                name="rationData.prepartumRation.totalCost"
                label={ t('total cost') }
                unit="R$"
                type="float"
                defaultValue={ toString(defaultValues?.rationData?.prepartumRation?.totalCost) }
                disabled
                fullWidth
              />
            ) : null}
          </Row>
          <Row>
            <InputUnit
              name="rationData.lactationRation.monthlyRation"
              label={ t('ration for lactation') }
              unit="kg/mês"
              type="integer"
              defaultValue={ toString(defaultValues?.rationData?.lactationRation?.monthlyRation) }
              onChange={ ({ target: { value } }) => setLactationRation(value) }
              maxLength={ 8 }
              fullWidth
              style={ { marginRight: 10 } }
            />
            {shouldDisplayCosts ? (
              <InputUnit
                detached
                label={ t('unitary cost') }
                unit="R$/kg"
                type="float"
                value={ lactationMonthlyCost }
                onChange={ ({ target: { value } }) => setLactationMonthlyCost(value) }
                fullWidth
                style={ { marginRight: 10 } }
              />
            ) : null}
            {shouldDisplayCosts ? (
              <InputUnit
                name="rationData.lactationRation.totalCost"
                label={ t('total cost') }
                unit="R$"
                type="float"
                defaultValue={ toString(defaultValues?.rationData?.lactationRation?.totalCost) }
                disabled
                fullWidth
              />
            ) : null}
          </Row>
          <Row>
            <InputUnit
              name="monthlyMilkForCalves"
              label={ t('milk destined for calves') }
              unit="litros/mês"
              type="integer"
              defaultValue={ toString(defaultValues?.monthlyMilkForCalves) }
              maxLength={ 8 }
              fullWidth
            />
          </Row>
          <Row>
            <InputUnit
              name="monthlyDiscardedMilk"
              label={ t('milk discarded on the property or family consumption') }
              unit="litros/mês"
              type="integer"
              defaultValue={ toString(defaultValues?.monthlyDiscardedMilk) }
              maxLength={ 8 }
              fullWidth
            />
          </Row>
        </FormContainer>

        {!withoutSubmitButton && (
          <ButtonsContainer>
            <Button
              id="web-cancel-form-button"
              onClick={ onCancel }
              color={ colors.white }
              disabled={ loading }
              style={ { marginRight: 10, marginLeft: 40 } }
            >
              <I18n>cancel</I18n>
            </Button>
            <Button
              id="web-save-form-button"
              onClick={ () => formRef.current.submit() }
              disabled={ loading }
              style={ { marginRight: 40, marginLeft: 10 } }
            >
              <I18n>save</I18n>
            </Button>
          </ButtonsContainer>
        )}
      </Form>
    </Container>
  )
})

RegisterMonthlyDataHistoryForm.propTypes = {
  loading: PropTypes.bool,
  onSubmit: PropTypes.func,
  onCancel: PropTypes.func,
  withoutSubmitButton: PropTypes.bool,
  shouldDisplayCosts: PropTypes.bool,
  defaultValues: PropTypes.object.isRequired
}

RegisterMonthlyDataHistoryForm.defaultProps = {
  loading: false,
  shouldDisplayCosts: false,
  onSubmit: () => {},
  onCancel: () => {},
  withoutSubmitButton: false
}

export default RegisterMonthlyDataHistoryForm
