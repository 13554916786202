/* eslint-disable no-shadow */
import React, { useRef, useCallback, useState, useMemo, useEffect } from 'react'
import { Popup } from 'react-leaflet'
import { useSelector, useDispatch } from 'react-redux'
import { useHistory, useLocation } from 'react-router-dom'


import booleanPointInPolygon from '@turf/boolean-point-in-polygon'
import { point, polygon } from '@turf/helpers'
import lineIntersect from '@turf/line-intersect'
import Leaflet from 'leaflet'
import polygonSplitter from 'polygon-splitter'
import { v4 as uuidv4 } from 'uuid'

import filter from 'lodash/filter'
import findLastIndex from 'lodash/findLastIndex'
import head from 'lodash/head'
import isEmpty from 'lodash/isEmpty'
import isEqual from 'lodash/isEqual'
import last from 'lodash/last'
import map from 'lodash/map'
import round from 'lodash/round'
import slice from 'lodash/slice'
import toString from 'lodash/toString'

import { useDialog } from '@smartcoop/dialog'
import I18n, { useT } from '@smartcoop/i18n'
import { polygonEdge , field, trash } from '@smartcoop/icons'
import { useSnackbar } from '@smartcoop/snackbar'
import { FieldActions } from '@smartcoop/stores/field'
import { selectFields } from '@smartcoop/stores/field/selectorField'
import { selectCurrentProperty } from '@smartcoop/stores/property/selectorProperty'
import { selectUserCanWrite } from '@smartcoop/stores/user/selectorUser'
import colors from '@smartcoop/styles/colors'
import { getPolygonArea , findMapZoom , getCenterCoordinates, validateIntersectLine } from '@smartcoop/utils/maps'
import Button from '@smartcoop/web-components/Button'
import Icon from '@smartcoop/web-components/Icon'
import InputFile from '@smartcoop/web-components/InputFile'
import InputText from '@smartcoop/web-components/InputText'
import Maps from '@smartcoop/web-components/Maps'
import Control from '@smartcoop/web-components/Maps/components/Control'
import MarkersList from '@smartcoop/web-components/Maps/components/MarkersList'
import Polygon from '@smartcoop/web-components/Maps/components/Polygon'
import Polyline from '@smartcoop/web-components/Maps/components/Polyline'
import ConfirmModal from '@smartcoop/web-components/Modal/ConfirmModal'
import Tooltip from '@smartcoop/web-components/Tooltip'
import FieldForm from '@smartcoop/web-containers/forms/digitalProperty/field/FieldForm/FieldForm'
import useFile from '@smartcoop/web-containers/hooks/useFile'
import { ButtonsContainer } from '@smartcoop/web-containers/layouts/AuthenticatedLayout/theme'
import SplitedScreenLayout from '@smartcoop/web-containers/layouts/SplitedScreenLayout'
import LoadingModal from '@smartcoop/web-containers/modals/LoadingModal'
import { useRoutes } from '@smartcoop/web-containers/routes/authenticated'

import { Container, Header, Title, IconContainer } from './styles'

const FieldRegisterScreen = () => {
  const activityFormRef = useRef(null)
  const polygonRef = useRef(null)

  const dispatch = useDispatch()

  const fields = useSelector(selectFields)

  const history = useHistory()
  const { routes } = useRoutes()
  const location = useLocation()
  const snackbar = useSnackbar()
  const t = useT()
  const { createDialog, removeDialog } = useDialog()

  const currentProperty = useSelector(selectCurrentProperty)
  const userWrite = useSelector(selectUserCanWrite)

  const isEditing = useMemo(() =>  location.pathname.includes('edit'),[location.pathname])

  const [loading, setLoading] = useState(false)
  const [mapZoom, setMapZoom] = useState(location?.state?.mapZoom)
  const [isSplit, setIsSplit] = useState(false)
  const [selectedPoint, setSelectedPoint] = useState(null)

  const [firstRegion, setFirstRegion] = useState()
  const [splitCoordinates, setSplitCoordinates] = useState(location.state?.field?.splitLines || [])
  const [isNewSplit, setIsNewSplit] = useState(false)
  const [selected, setSelected] = useState(null)
  const [splitedPolygons, setSplitedPolygons] = useState(map(location.state?.field?.childrenPolygons, item => item?.coordinates))
  const [oldPolygon, setOldPolygon] = useState(!isEmpty(location.state?.field?.childrenPolygons) ?location.state?.field?.polygonCoordinates : [])
  const [polygonNames, setPolygonNames] = useState(map(location.state?.field?.childrenPolygons, item => item?.name))
  const [firstSplitMarkerPoint, setFirstSplitMarkerPoint] = useState([])
  const [saveAfterConfirm, setSaveAfterConfirm] = useState(false)

  const [polygonCoordinates, setPolygonCoordinates] = useState(
    (() => {
      if (location.state?.field) {
        return isEmpty(location.state?.field?.childrenPolygons) ? location.state?.field?.polygonCoordinates: []
      }
      return []
    })()
  )
  const editFields = useMemo(() => location?.state?.editFields, [location])

  const growingSeasons = useMemo(() => location.state?.field?.growingSeasons, [location])

  const noAction = useMemo(() => (isEmpty(polygonCoordinates) && !isEmpty(oldPolygon) && !isSplit), [isSplit, oldPolygon, polygonCoordinates])
  const {
    selectedFiles,
    handleAdd,
    createFormData,
    clearFiles
  } = useFile([], location.state?.field?.fieldFiles ?? [])

  useEffect(() => {
    if(!isEqual(splitCoordinates, location.state?.field?.splitLines)) {
      setPolygonNames(map(splitedPolygons, (_, index) => `Area ${ index + 1 }`))
    }
  }, [location, splitCoordinates, splitedPolygons])

  const mapCenter = useMemo(
    () => {
      if (!isEmpty(firstRegion)) {
        return firstRegion
      }

      if (!isEmpty(polygonCoordinates)) {
        setMapZoom(findMapZoom(polygonCoordinates))
        const coord = getCenterCoordinates(polygonCoordinates)
        setFirstRegion(coord)
        return coord
      }

      if (location.state && location?.state?.region) {
        setFirstRegion(location?.state?.region)
        return location?.state?.region
      }

      if (location.state?.mapCenterPosition) {
        setFirstRegion(location?.state?.mapCenterPosition)
        return location?.state?.mapCenterPosition
      }

      setFirstRegion(currentProperty?.geolocalization)
      return currentProperty?.geolocalization
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [currentProperty, location, polygonCoordinates, firstRegion]
  )

  const onSuccess = useCallback(
    (fieldId) => {
      const operationType = isEditing ? 'edited' : 'registered'
      if(editFields) {
        history.replace(routes.fieldList.path, { editFields })
      } else {
        history.replace(routes.fieldDetails.path.replace(':fieldId', fieldId) )
      }
      snackbar.success(
        t(`your {this} was ${ operationType }`, {
          howMany: 1,
          gender: 'male',
          this: t('field', { howMany: 1 })
        })
      )
    },
    [editFields, history, isEditing, routes, snackbar, t]
  )

  const setFormArea = useCallback(
    (value) => {
      activityFormRef.current.setFieldValue('area', toString(round(value,2)))
    },
    []
  )

  const handleClose = useCallback(
    () => (
      history.goBack()
    ),
    [history]
  )

  const onConfirmSplit = useCallback(() => {
    if(!isEmpty(splitCoordinates)) {
      const lastLineString = last(splitCoordinates)

      const currentSplittedPolygons = !isEmpty(splitedPolygons) ? splitedPolygons : [[...polygonCoordinates, polygonCoordinates[0]]]

      const polygonsResult = []

      map(currentSplittedPolygons, eachPolygon => {

        const firstLineIntersectIndex = validateIntersectLine(lastLineString, eachPolygon)
        const filteredLastLineString = filter(lastLineString, (item, index) => index >= firstLineIntersectIndex)
        const newSplitCoordinates = [...splitCoordinates]

        newSplitCoordinates[newSplitCoordinates.length - 1] = filteredLastLineString
        setSplitCoordinates(newSplitCoordinates)

        const polygonToSplit = {
          'type': 'Polygon',
          'coordinates': [eachPolygon]
        }

        const lineToSplit = {
          'type': 'LineString',
          'coordinates': filteredLastLineString
        }
        const doesIntersect = lineIntersect(lineToSplit, polygonToSplit)
        const isFirstPointInside = booleanPointInPolygon(point(head(filteredLastLineString)), polygon([eachPolygon]))
        const isLastPointInside = booleanPointInPolygon(point(last(filteredLastLineString)), polygon([eachPolygon]))

        if(!isEmpty(doesIntersect?.features) && !isFirstPointInside && !isLastPointInside) {
          const splitted = polygonSplitter(polygonToSplit, lineToSplit)

          const newCoordinates = map(splitted?.geometry?.coordinates, item => item[0])

          polygonsResult.push(...newCoordinates)
          setSplitedPolygons(polygonsResult)
          setPolygonCoordinates([])
        } else {
          setSplitCoordinates(splitCoordinates.slice(0, -1))
          polygonsResult.push(eachPolygon)
        }
      })
      if(!isEmpty(polygonCoordinates)) {
        setOldPolygon(polygonCoordinates)
        setFormArea(getPolygonArea(polygonCoordinates))
      }
      setFirstSplitMarkerPoint([])
      setIsSplit(false)
      if(isEmpty(polygonCoordinates)) {
        setFormArea(getPolygonArea(oldPolygon))
      }
    }

  },[oldPolygon, polygonCoordinates, setFormArea, splitCoordinates, splitedPolygons])

  const submitForms = useCallback(
    () => {
      if(!isEmpty(splitCoordinates) && isEmpty(splitedPolygons)) {
        createDialog({
          id: 'confirm-register-field',
          Component: ConfirmModal,
          props: {
            onConfirm: () => {
              onConfirmSplit()
              setTimeout(() => {
                setSaveAfterConfirm(true)
              }, 500)
            },
            onNegative: () => {
              setSplitCoordinates([...splitCoordinates.slice(0, -1)])
              setIsSplit(false)
            },
            textWarning: t('you have unconfirmed field split, do you wanna split and continue?')
          }
        })
      } else {
        activityFormRef.current.submit()
      }
    },
    [createDialog, onConfirmSplit, splitCoordinates, splitedPolygons, t]
  )

  const onReverseSplit = useCallback(() => {
    setFirstSplitMarkerPoint([])
    setSplitedPolygons([])
    setIsNewSplit(false)
    setSplitCoordinates([])
    setIsSplit(false)
    if(!isEmpty(oldPolygon)) {
      setPolygonCoordinates(oldPolygon)
      setOldPolygon([])
    }
  },[oldPolygon])

  const handleAddPolygonPoint = useCallback(
    (newPoint) => {
      if (!loading) {
        if(isSplit) {
          const currentSplitCoordinates = isNewSplit ? [[]] : last(splitCoordinates)

          const newLineSection = {
            'type': 'LineString',
            'coordinates': [last(currentSplitCoordinates), [newPoint.latitude, newPoint.longitude]]
          }

          const lineSections = {
            'type': 'LineString',
            'coordinates': [ ...currentSplitCoordinates.slice(0, -1) ]
          }
          let lineDoesIntersect = false
          if (!isEmpty(lineSections?.coordinates)) {
            lineDoesIntersect = lineIntersect(newLineSection, lineSections)
          }

          if(isNewSplit) {
            setFirstSplitMarkerPoint([newPoint.latitude, newPoint.longitude])
            const newSplitArray = !isEmpty(splitCoordinates[0]) ? [...splitCoordinates] : []
            newSplitArray.push([[newPoint.latitude, newPoint.longitude]])
            setSplitCoordinates(newSplitArray)
            setIsNewSplit(false)
          } else if(splitCoordinates.length > 1) {
            if(isEmpty(lineDoesIntersect?.features)) {
              setFirstSplitMarkerPoint([])
              setSplitCoordinates([...splitCoordinates.slice(0, -1), [...currentSplitCoordinates, [newPoint.latitude, newPoint.longitude]]])
            }
          } else if(isEmpty(lineDoesIntersect?.features)) {
            setFirstSplitMarkerPoint([])
            setSplitCoordinates([[...currentSplitCoordinates, [newPoint.latitude, newPoint.longitude]]])
          }
        } else {
          const newPoints = polygonRef.current.sortNewPoint([
            newPoint.latitude,
            newPoint.longitude
          ])
          setPolygonCoordinates(newPoints)
          setSelectedPoint(findLastIndex(newPoints))
        }
      }
    },
    [isNewSplit, isSplit, loading, splitCoordinates]
  )

  useEffect(() => {
    if(!isEmpty(selectedFiles)) {
      createDialog({
        id: '',
        Component: ConfirmModal,
        props: {
          onConfirm: () => {
            dispatch(FieldActions.uploadFieldShape(
              createFormData('upload'),
              (data) => {
                setFirstSplitMarkerPoint([])
                setSplitCoordinates([])
                setPolygonCoordinates([])
                setPolygonCoordinates(data)
                setFirstRegion({})
                polygonRef.current.sortNewPoint(last(data))
                clearFiles()
              }
            ))
          },
          textWarning: t('are you sure you want to import this file?')
        }
      })
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedFiles])

  useEffect(
    () => {
      if (isEditing && !mapZoom && !isEmpty(polygonCoordinates)) {
        setMapZoom(findMapZoom(polygonCoordinates))
      }
    },
    [isEditing, mapZoom, polygonCoordinates]
  )

  useEffect(() => {
    if(saveAfterConfirm) {
      submitForms()
    }
  }, [saveAfterConfirm, submitForms])

  const childrenPolygons = useMemo(() =>
    !isEqual(map(location.state?.field?.childrenPolygons, item => item?.coordinates), splitedPolygons)
      ? map(splitedPolygons, (item, index) => ({
        id: uuidv4(),
        area: getPolygonArea(item),
        name: polygonNames[index],
        coordinates: item
      }))
      : map(location.state?.field?.childrenPolygons, (item, index) => ({ ...item, name: polygonNames[index] }))

  ,[location, polygonNames, splitedPolygons])

  const firstSplitMarker = useMemo(
    () => new Leaflet.DivIcon({
      html: polygonEdge({ color: '#4287f5', size: 15 }),
      iconSize: new Leaflet.Point(16, 16),
      iconAnchor: [8, 25]
    }),
    []
  )

  const deleteField = useCallback(() => {
    createDialog({
      id: 'confirm-delete',
      Component: ConfirmModal,
      props: {
        onConfirm: () => {
          if (isEmpty(growingSeasons)) {
            createDialog({
              id: 'loader',
              Component: LoadingModal
            })
            dispatch(FieldActions.deleteField(location.state?.field?.id, () => {
              snackbar.success(
                t('your {this} was deleted', {
                  howMany: 1,
                  gender: 'male',
                  this: t('field', { howMany: 1 })
                })
              )
              removeDialog({ id: 'loader' })
              history.replace(routes.fieldList.path, { editFields })
            }))

          }
        },
        textWarning: t('are you sure you want to delete the {this}?', {
          howMany: 1,
          gender: 'male',
          this: t('field', { howMany: 1 })
        })
      }
    })
  }, [createDialog, dispatch, editFields, growingSeasons, history, location, removeDialog, routes, snackbar, t])

  useEffect(() => {
    if(findLastIndex(polygonCoordinates) !== selectedPoint) {
      const start = slice(polygonCoordinates, selectedPoint + 1)
      const end = slice(polygonCoordinates, 0, selectedPoint + 1)

      setPolygonCoordinates([...start, ...end])
      setSelectedPoint(findLastIndex([...start, ...end]))
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[selectedPoint])

  return (
    <SplitedScreenLayout
      title={ { name: t('field', { howMany: 2 }) } }
      divRightStyle={ { padding: 0 } }
      leftChildren={ (
        <Container>
          <Header>
            <IconContainer>
              <Icon icon={ field } size={ 18 } />
            </IconContainer>

            <Title style={ { fontSize: 18, fontWeight: 600 } }>
              <I18n>{isEditing ? 'field edit' : 'field registration'}</I18n>
            </Title>

          </Header>
          <FieldForm
            ref={ activityFormRef }
            onSuccess={ onSuccess }
            onSubmit={ () => setLoading(true) }
            onError={ () => setLoading(false) }
            polygonCoordinates={ polygonCoordinates }
            splitedPolygons={ splitedPolygons }
            oldPolygon={ oldPolygon }
            withoutSubmitButton
            childrenPolygons={ childrenPolygons }
            splitCoordinates={ splitCoordinates }
            area={ toString(round(location.state?.field?.area,2)) }
          />

          <ButtonsContainer style={ { paddingTop: 30 } }>
            <Button
              id="cancel-field-form"
              onClick={ handleClose }
              style={ { flex: 1 } }
              variant="outlined"
              disabled={ loading }
            >
              <I18n>cancel</I18n>
            </Button>

            <div style={ { width: '10%' } } />

            <Button
              id="submit-field-form"
              onClick={ submitForms }
              style={ { flex: 1 } }
              disabled={ loading || !userWrite }
            >
              <I18n>save</I18n>
            </Button>
          </ButtonsContainer>
        </Container>
      ) }
      rightChildren={ (
        <Maps
          region={ mapCenter }
          zoom={ mapZoom }
          onClick={ noAction ? () => {} : handleAddPolygonPoint }
          style={ { minHeight: 500 } }
          cursor="crosshair"
          layer="google"
        >
          <Control position="topcenter">
            <div
              style={ {
                display: 'flex',
                justifyContent: 'flex-end',
                width: '100%',
                padding: '0 10px',
                paddingRight: '40px'
              } }
            >
              <InputFile
                idInput="contained-button-file-1"
                // idInput={`contained-button-file-${location.id}`}
                onChange={ handleAdd }
                inputProps={ {
                  accept: '.kml'
                } }
                buttonProps={ {
                  id: 'add-docs-1',
                  // id: `add-docs-${location.id}`,
                  variant: 'outlined',
                  style: {
                    backgroundColor: colors.white
                  }
                } }
              >
                <>
                  <I18n>import shape</I18n>
                  <Icon icon={ field } size={ 16 } style={ { marginLeft: 5 } } />
                </>
              </InputFile>
            </div>
          </Control>
          <Control position="bottomright">
            {!isEmpty(splitCoordinates) && (
              <Button
                id="reverse-split"
                onClick={ onReverseSplit }
                variant="outlined"
                style={ { backgroundColor: colors.white, marginRight: 10 } }
              >
                <I18n>reverse split</I18n>
              </Button>
            )}
            { !isSplit && (
              <Button
                id="set-split"
                onClick={ () => {
                  setIsSplit(true)
                  setIsNewSplit(true)
                } }
                variant="outlined"
                style={ { backgroundColor: colors.white, marginRight: isSplit ? 10 : 0 } }
                disabled={ isEmpty(polygonCoordinates) && isEmpty(oldPolygon) }
              >
                <I18n>split</I18n>
              </Button>
            )}

            {isSplit && (
              <Button
                id="confirm-split"
                onClick={ onConfirmSplit }
                variant="outlined"
                style={ { backgroundColor: colors.white } }
              >
                <I18n>confirm split</I18n>
              </Button>
            )}
          </Control>
          <Control position="topright">
            {(userWrite && editFields && isEmpty(growingSeasons)) && (
              <Button
                id="delete-field"
                style={ { padding: '9px 0' } }
                color={ colors.white }
                onClick={ deleteField }
              >
                <Icon size={ 16 } icon={ trash } color={ colors.red } />
              </Button>
            )}
            {(userWrite && editFields && !isEmpty(growingSeasons)) && (
              <Tooltip title={ t(
                'it is not possible to exclude fields with registered cultivation'
              ) }
              >
                <Button
                  id="delete-field"
                  style={ { padding: '9px 0' } }
                  color={ colors.white }
                  disabled
                >
                  <Icon size={ 16 } icon={ trash } color={ colors.red } />
                </Button>
              </Tooltip>
            )}
          </Control>
          {map(fields, ({ polygonCoordinates, id }) => (
            <Polygon
              key={ id }
              points={ polygonCoordinates }
              color={ colors.white }
            />
          ))}

          {map(splitedPolygons, (item, index) => isSplit ? (
            <Polygon
              key={ `polygon-${ index }` }
              points={ item }
              onClick={ noAction ? () => {
                setSelected(index)
              } : () => {} }
              color={ colors.secondary }
              strokeWidth={ item.id === selected ? 7 : 2 }
            />
          ) : (
            <Polygon
              key={ `polygon-${ index }-with-popup` }
              points={ item }
              onClick={ noAction ? () => {
                setSelected(index)
              } : () => {} }
              color={ colors.secondary }
              strokeWidth={ item.id === selected ? 7 : 2 }
            >
              <Popup>
                <InputText
                  minWidth={ 150 }
                  name="polygonName"
                  detached
                  label={ t('polygon name') }
                  value={ polygonNames[index] }
                  onChange={ ({ target: { value } }) => {
                    const newNames = [...polygonNames]
                    newNames[index] = value
                    setPolygonNames(newNames)
                  } }
                />
              </Popup>
            </Polygon>
          ))}
          {isSplit && map(splitCoordinates, (line, index) => (
            <Polyline key={ `line-${ index }` } points={ line } />
          )
          )}
          { (isSplit && !isEmpty(firstSplitMarkerPoint)) && (
            <MarkersList
              markers={ [{
                key: 'first-marker',
                point: firstSplitMarkerPoint,
                icon: firstSplitMarker
              }] }
            />
          )}
          <Polygon
            ref={ polygonRef }
            onChange={ isSplit ? null : setPolygonCoordinates }
            onChangeArea={ !isEmpty(polygonCoordinates) ? setFormArea : () => {} }
            color={ colors.secondary }
            points={ polygonCoordinates }
            setSelectedPoint={ setSelectedPoint }
            selectedPoint={ selectedPoint }
          />
        </Maps>
      ) }
    />
  )}

export default FieldRegisterScreen
