import React from 'react'

import moment from 'moment'
import PropTypes from 'prop-types'

import map from 'lodash/map'

import { useT } from '@smartcoop/i18n'
import { icon26 }  from '@smartcoop/icons/weatherIcons'
import { momentFriendlyDateFormat } from '@smartcoop/utils/dates'
import Icon from '@smartcoop/web-components/Icon'
import Tooltip from '@smartcoop/web-components/Tooltip'

import useStyles, {
  TabsGroup,
  TabItem,
  TabContainer,
  CloudPercentContainer,
  DateText,
  WeatherPercentText
} from './styles'

const DateTabs = ({
  onChange,
  value,
  indicators,
  maxCloudsPercent
}) => {
  const t = useT()
  const classes = useStyles()

  return (
    <TabsGroup
      value={ value }
      onChange={ onChange }
      classes={ classes }
      textColor="inherit"
      variant="scrollable"
      scrollButtons="auto"
      aria-label="dates by field indexes"
    >
      { map(indicators, item => {
        const { isImageTooCloudy, date } = item

        const label = isImageTooCloudy ? (
          <Tooltip title={ t('too cloudy day') }>
            <TabContainer style={ { opacity: 0.5 } }>
              <CloudPercentContainer>
                <WeatherPercentText>{ maxCloudsPercent }%+ &nbsp;</WeatherPercentText>
                <Icon size={ 16 } icon={ icon26 } />
              </CloudPercentContainer>
              <DateText>{moment(date).format(momentFriendlyDateFormat)}</DateText>
            </TabContainer>
          </Tooltip>
        ) : (
          <>
            {item.cloudPercent > 0.1 ? (
              <TabContainer>
                <CloudPercentContainer>
                  <WeatherPercentText>{item.cloudPercent.toFixed(1)}% &nbsp;</WeatherPercentText>
                  <Icon size={ 16 } icon={ icon26 } />
                </CloudPercentContainer>
                <DateText>{moment(date).format(momentFriendlyDateFormat)}</DateText>
              </TabContainer>
            ) : (
              moment(date).format(momentFriendlyDateFormat)
            )}
          </>
        )

        return <TabItem disabled={ isImageTooCloudy } label={ label } key={ date } />
      })}
    </TabsGroup>
  )
}

DateTabs.propTypes = {
  value: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
  indicators: PropTypes.array.isRequired,
  maxCloudsPercent: PropTypes.number.isRequired
}

export default DateTabs
