import * as Yup from 'yup'

import flow from 'lodash/fp/flow'

import monthYear from '@smartcoop/forms/validators/monthYear.validator'
import required from '@smartcoop/forms/validators/required.validator'

const registerMonthlyDataHistory = ({ t }) => Yup.object().shape({
  date: flow(
    monthYear({ t }),
    required({ t })
  )(Yup.string()),

  rationData: Yup.object().shape({
    lactationRation: Yup.object().shape({
      monthlyRation: flow(
      )(Yup.string().nullable()),
      totalCost: flow(
      )(Yup.string().nullable())
    }),
    prepartumRation: Yup.object().shape({
      monthlyRation: flow(
      )(Yup.string().nullable()),
      totalCost: flow(
      )(Yup.string().nullable())
    }),
    calfRation: Yup.object().shape({
      monthlyRation: flow(
      )(Yup.string().nullable()),
      totalCost: flow(
      )(Yup.string().nullable())
    })
  }),
  monthlyMilkForCalves: flow(
  )(Yup.string()),

  monthlyDiscardedMilk: flow(
  )(Yup.string())
})

export default registerMonthlyDataHistory
