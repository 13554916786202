import React, {
  useCallback,
  forwardRef,
  useMemo,
  useState,
  useEffect
} from 'react'
import { useDispatch, useSelector } from 'react-redux'

import moment from 'moment/moment'
import PropTypes from 'prop-types'

import debounce from 'lodash/debounce'
import filter from 'lodash/filter'
import find from 'lodash/find'
import flatMap from 'lodash/flatMap'
import findFP from 'lodash/fp/find'
import flow from 'lodash/fp/flow'
import mapFP from 'lodash/fp/map'
import isEmpty from 'lodash/isEmpty'
import map from 'lodash/map'
import reduce from 'lodash/reduce'
import sumBy from 'lodash/sumBy'
import toLower from 'lodash/toLower'
import uniq from 'lodash/uniq'

import { useDialog } from '@smartcoop/dialog'
import { reloadSchema } from '@smartcoop/forms'
import registerOrderSchema from '@smartcoop/forms/schemas/shoppingPlatform/order/registerOrder.schema'
import I18n, { useT } from '@smartcoop/i18n'
import { shoppingCart } from '@smartcoop/icons'
import {
  getProducts as getProductsService,
  getProduct
} from '@smartcoop/services/apis/smartcoopApi/resources/product'
import { getProductGroups as getProductGroupsService } from '@smartcoop/services/apis/smartcoopApi/resources/productGroup'
import useSmartcoopApi from '@smartcoop/services/hooks/useSmartcoopApi'
import { useSnackbar } from '@smartcoop/snackbar'
import { OrderActions } from '@smartcoop/stores/order'
import { OrganizationActions } from '@smartcoop/stores/organization'
import {
  selectCurrentOrganization
} from '@smartcoop/stores/organization/selectorOrganization'
import colors from '@smartcoop/styles/colors'
import { hourOptions } from '@smartcoop/utils/constants'
import { momentBackDateTimeFormat, momentBackDateFormat, momentFriendlyTimeFormat, momentBackDateTimeZoneFormat } from '@smartcoop/utils/dates'
import Button from '@smartcoop/web-components/Button'
import CheckboxTreeView from '@smartcoop/web-components/CheckboxTreeView'
import FilteredCheckboxGroup from '@smartcoop/web-components/FilteredCheckboxGroup'
import Form from '@smartcoop/web-components/Form'
import Icon from '@smartcoop/web-components/Icon'
import InputDate from '@smartcoop/web-components/InputDate'
import InputSelect from '@smartcoop/web-components/InputSelect'
import Loader from '@smartcoop/web-components/Loader'
import DemandStatusFragment from '@smartcoop/web-containers/fragments/shoppingPlatform/DemandStatusFragment/DemandStatusFragment'
import {
  Item,
  Title,
  ButtonsContainer
} from '@smartcoop/web-containers/layouts/AuthenticatedLayout/theme'
import SplitedScreenLayout from '@smartcoop/web-containers/layouts/SplitedScreenLayout'
import RegistrationAmountPaidModal from '@smartcoop/web-containers/modals/AmountPaid/RegistrationAmountPaidModal'
import ViewListAmountPaidModal from '@smartcoop/web-containers/modals/AmountPaid/ViewListAmountPaidModal'
import ViewListSuppliersByProducts from '@smartcoop/web-containers/modals/ViewListSuppliersByProducts'

import AddressWithQuantity from './AddressWithQuantity'
import {
  IconContainer,
  Row,
  TotalQuantityRow,
  TotalQuantity,
  Header,
  Container,
  HourWrapper,
  DateWrapper
} from './styles'

const RegisterOrderForm = forwardRef((props, formRef) => {
  const { onSuccess, onCancel, defaultValues } = props

  const t = useT()
  const dispatch = useCallback(useDispatch(), [])
  const snackbar = useSnackbar()
  const { createDialog } = useDialog()

  const { data: dateParams, isValidating } = useSmartcoopApi('/v1/purchase-demands/params')

  const currentOrganization = useSelector(selectCurrentOrganization)

  const [filterText, setFilterText] = useState('')
  const [loading, setLoading] = useState(false)
  const [sent, setSent] = useState(false)
  const [addressesOptions, setAddressesOptions] = useState([])
  const [productGroup, setProductGroup] = useState(defaultValues?.productId ? { id: defaultValues?.product?.productGroupId  } : null)
  const [unit, setUnit] = useState(defaultValues?.product?.unitOfMeasures || 'kg')
  const [productId, setProductId] = useState(defaultValues?.productId || null)
  const [products, setProducts] = useState([])
  const [suppliersProduct, setSuppliersProduct] = useState(null)
  const [minReceiptDate, setMinReceiptDate] = useState(defaultValues?.product?.minReceiptDate || moment().format(momentBackDateFormat))
  const [participationDeadline, setParticipationDeadline] = useState('')
  const [participationDeadlineHour, setParticipationDeadlineHour] = useState(defaultValues?.participationDeadline ? moment(defaultValues?.participationDeadline, momentBackDateTimeFormat).format(momentFriendlyTimeFormat) : '23:00')
  const [proposalDeadline, setProposalDeadline] = useState('')
  const [proposalDeadlineHour, setProposalDeadlineHour] = useState(defaultValues?.proposalDeadline ? moment(defaultValues?.proposalDeadline, momentBackDateTimeFormat).format(momentFriendlyTimeFormat) :'23:00')
  const [decisionDeadline, setDecisionDeadline] = useState('')
  const [decisionDeadlineHour, setDecisionDeadlineHour] = useState(defaultValues?.decisionDeadline ? moment(defaultValues?.decisionDeadline, momentBackDateTimeFormat).format(momentFriendlyTimeFormat) :'23:00')
  const [receiptDeadline, setReceiptDeadline] = useState('')
  const [receiptDeadlineHour, setReceiptDeadlineHour] = useState(defaultValues?.receiptDeadline ? moment(defaultValues?.receiptDeadline, momentBackDateTimeFormat).format(momentFriendlyTimeFormat) :'23:00')
  const [paymentOptions, setPaymentOptions] = useState([
    {
      id: 'vista',
      checked: true,
      disabled: true,
      label: 'À Vista'
    },
    {
      id: 'prazo',
      label: 'À Prazo',
      creatableInput: {
        type: 'inputText',
        label: t('quantity of days'),
        name: 'creatableValue'
      },
      options: [
        {
          id: '30',
          checked: !isEmpty(defaultValues) ? !!find(defaultValues?.paymentForms, item => item?.daysAmount === '30')?.deferredPayment : true,
          label: `30 ${ t('day', { howMany: 30 }) }`
        },
        {
          id: '45',
          checked: !isEmpty(defaultValues) ? !!find(defaultValues?.paymentForms, item => item?.daysAmount === '45')?.deferredPayment : false,
          label: `45 ${ t('day', { howMany: 45 }) }`
        },
        {
          id: '90',
          checked: !isEmpty(defaultValues) ? !!find(defaultValues?.paymentForms, item => item?.daysAmount === '90')?.deferredPayment : false,
          label: `90 ${ t('day', { howMany: 90 }) }`
        }
      ]
    }
  ])

  const freightTypeOptions = [
    {
      label: 'CIF',
      value: 'cif'
    },
    {
      label: 'FOB',
      value: 'fob'
    },
    {
      label: 'CFA',
      value: 'cfa'
    }
  ]

  const totalQuantity = useMemo(
    () => sumBy(addressesOptions, (item) => Number(item.quantity) || 0),
    [addressesOptions]
  )

  const defaultDateValues = useMemo(
    () => (
      !isEmpty(dateParams) || !isEmpty(defaultValues)
        ? {
          participationDeadline: defaultValues?.participationDeadline ? moment().add(moment(moment(defaultValues?.participationDeadline, momentBackDateTimeFormat)).diff(moment(defaultValues?.createdAt, momentBackDateTimeZoneFormat), 'days'), 'days').format(momentBackDateFormat) : moment().add(dateParams?.participationTimebox, 'days').format(momentBackDateFormat),
          proposalDeadline: defaultValues?.proposalDeadline ? moment().add(moment(moment(defaultValues?.proposalDeadline, momentBackDateTimeFormat)).diff(moment(defaultValues?.participationDeadline, momentBackDateTimeFormat), 'days'), 'days').format(momentBackDateFormat) : moment().add(dateParams?.participationTimebox + dateParams?.proposalTimebox, 'days').format(momentBackDateFormat),
          decisionDeadline: defaultValues?.decisionDeadline ? moment().add(moment(moment(defaultValues?.decisionDeadline, momentBackDateTimeFormat)).diff(moment(defaultValues?.proposalDeadline, momentBackDateTimeFormat), 'days'), 'days').format(momentBackDateFormat) : moment().add(dateParams?.participationTimebox + dateParams?.proposalTimebox + dateParams?.decisionTimebox, 'days').format(momentBackDateFormat),
          receiptDeadline: defaultValues?.receiptDeadline ? moment().add(moment(moment(defaultValues?.receiptDeadline, momentBackDateTimeFormat)).diff(moment(defaultValues?.decisionDeadline, momentBackDateTimeFormat), 'days'), 'days').format(momentBackDateFormat) : ''
        }
        : {}
    ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [defaultValues, dateParams, isValidating]
  )

  useEffect(() => {
    if(!isEmpty(defaultDateValues) && formRef.current.getFieldRef('participationDeadline')) {
      setParticipationDeadline(defaultDateValues.participationDeadline)
      formRef.current.getFieldRef('participationDeadline').setValue(defaultDateValues.participationDeadline)

      setProposalDeadline(defaultDateValues.proposalDeadline)
      formRef.current.getFieldRef('proposalDeadline').setValue(defaultDateValues.proposalDeadline)

      setDecisionDeadline(defaultDateValues.decisionDeadline)
      formRef.current.getFieldRef('decisionDeadline').setValue(defaultDateValues.decisionDeadline)

      setReceiptDeadline(defaultDateValues.receiptDeadline)
      formRef.current.getFieldRef('receiptDeadline').setValue(defaultDateValues.receiptDeadline)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultDateValues])

  const schemaProps = useMemo(
    () => ({
      ...dateParams,
      participationDeadline,
      proposalDeadline,
      decisionDeadline,
      receiptDeadline,
      participationDeadlineHour,
      proposalDeadlineHour,
      decisionDeadlineHour,
      receiptDeadlineHour
    }),
    [dateParams, decisionDeadline, decisionDeadlineHour, participationDeadline, participationDeadlineHour, proposalDeadline, proposalDeadlineHour, receiptDeadline, receiptDeadlineHour]
  )

  const handleSubmit = useCallback(
    ({ deliveryLocations, ...submittedData }) => {
      setSent(true)
      setLoading(true)

      const addressWithQuantityZero = flow(
        mapFP((opt) => find(addressesOptions, { value: opt })),
        findFP((opt) => !!opt.error)
      )(deliveryLocations)


      if (!addressWithQuantityZero) {
        const paymentForms = reduce(
          submittedData.paymentForms,
          (acc, val, valName) => {
            if (valName === 'prazo') {
              return [
                ...acc,
                ...map(val, (_, days) => ({
                  deferredPayment: true,
                  daysAmount: days
                }))
              ]
            }
            return [
              ...acc,
              {
                deferredPayment: false,
                daysAmount: 0
              }
            ]
          },
          []
        )

        const data = {
          ...submittedData,
          organizationId: currentOrganization.id,
          paymentForms,
          participationDeadline: moment(`${ participationDeadline } ${ participationDeadlineHour }`, 'YYYY-MM-DD HH:mm').format(momentBackDateTimeFormat),
          proposalDeadline: moment(`${ proposalDeadline } ${ proposalDeadlineHour }`, 'YYYY-MM-DD HH:mm').format(momentBackDateTimeFormat),
          decisionDeadline: moment(`${ decisionDeadline } ${ decisionDeadlineHour }`, 'YYYY-MM-DD HH:mm').format(momentBackDateTimeFormat),
          receiptDeadline: moment(`${ receiptDeadline } ${ receiptDeadlineHour }`, 'YYYY-MM-DD HH:mm').format(momentBackDateTimeFormat),
          organizationPurchases: [
            {
              totalAmount: Number(totalQuantity),
              organizationId: currentOrganization.id,
              deliveryLocations: map(deliveryLocations, (address) => ({
                organizationId: address,
                amount: Number(
                  find(addressesOptions, { value: address })?.quantity
                ),
                billingOrganizationId: find(addressesOptions, { value: address })?.billingOrganizationId,
                note: find(addressesOptions, { value: address })?.note
              }))
            }
          ]
        }

        dispatch(
          OrderActions.saveOrder(
            data,
            () => {
              snackbar.success(
                t('your {this} was registered', {
                  howMany: 1,
                  this: t('order', { howMany: 1 }),
                  gender: 'female'
                })
              )
              setSent(false)
              setLoading(false)
              onSuccess()
            },
            () => {
              setSent(false)
              setLoading(false)
            }
          )
        )
      }
    },
    [addressesOptions, currentOrganization.id, decisionDeadline, decisionDeadlineHour, dispatch, onSuccess, participationDeadline, participationDeadlineHour, proposalDeadline, proposalDeadlineHour, receiptDeadline, receiptDeadlineHour, snackbar, t, totalQuantity]
  )

  const handleProductChanges = useCallback(
    async (event) => {
      if(event.target.value) {
        const selectedProduct = (
          await getProduct({ productId: event.target.value, params: {} })
        ).data
        setUnit(selectedProduct?.unitOfMeasures)
        setProductId(selectedProduct?.id)
        // cliente comentou que talvez seja necessario remover
        setMinReceiptDate(selectedProduct?.minReceiptDate || minReceiptDate)
      }
    },
    [minReceiptDate]
  )

  const changedProductGroup = useCallback(({ target: { value } }) => {
    setProducts([])
    formRef.current.getFieldRef('productId').setValue(null)
    setProductGroup({ id: value })
  }, [formRef])

  const defaultPaymentExpanded = useMemo(() => ['prazo'], [])

  const productGroupId = useMemo(() => productGroup?.id, [productGroup])

  const getProductList = useCallback(async () => {
    const { data: { data } } = await getProductsService({ productGroupId, purchasing: true, limit: 99999 })
    setProducts(map(data, item => ({
      label: `${ item?.productCode } - ${ item?.description }`,
      value: item?.id
    })))
  }, [productGroupId])

  useEffect(() => {
    if(productGroupId) {
      getProductList()
    }
  },[getProductList, productGroupId])

  const ListSuppliersByProducts = useCallback(() => {
    createDialog({
      id: 'list-suppliers-by-products',
      Component: ViewListSuppliersByProducts,
      props: {
        data: suppliersProduct
      }
    })
  }, [createDialog, suppliersProduct])

  const handleAddressQuantityChange = useCallback(
    debounce((id, newOpt) => {
      setAddressesOptions((old) =>
        old.map((opt) => {
          if (opt.value === id) {
            return { ...opt, ...newOpt }
          }
          return opt
        })
      )
    }, 300),
    []
  )

  useEffect(() => {
    if(productId) {
      dispatch(OrderActions.loadSuppliersByProduct(
        productId,
        (data) => {
          if(isEmpty(data)) {
            snackbar.warning('Não existe fornecedor cadastrado para este produto.')
          } else {
            setSuppliersProduct(data)
          }
        },
        () => {
          snackbar.warning('Não existe fornecedor cadastrado para este produto.')
        }
      ))
    }
  },[dispatch, productId, snackbar])

  useEffect(() => {
    setLoading(true)
    dispatch(
      OrganizationActions.loadCurrentOrganizationAddresses(
        { q: filterText },
        (data) => {
          setAddressesOptions((old) =>
            map(data, (address) => {
              const defaultCurrentAddress = find(flatMap(defaultValues?.organizationPurchases, item => flatMap(item?.deliveryLocations, subItem => subItem)), item => item?.organizationId === address.id)

              return ({
                value: address.id,
                quantity: find(old, { value: address.id })?.quantity || defaultCurrentAddress?.amount || '',
                billingOrganizationId: find(old, { value: address.id })?.billingOrganizationId || address.id,
                note: find(old, { value: address.id })?.note || '',
                // eslint-disable-next-line react/prop-types
                render: ({ option }) => (
                  <AddressWithQuantity
                    // eslint-disable-next-line react/prop-types
                    id={ option.value }
                    // eslint-disable-next-line react/prop-types
                    checked={ option.checked || !!defaultCurrentAddress }
                    address={ address }
                    onChange={ handleAddressQuantityChange }
                    // eslint-disable-next-line react/prop-types
                    quantity={ option.quantity }
                    // eslint-disable-next-line react/prop-types
                    billingOrganizationId={ option.billingOrganizationId }
                    // eslint-disable-next-line react/prop-types
                    note={ option.note || defaultCurrentAddress?.note }
                    // eslint-disable-next-line react/prop-types
                    error={ option.error }
                    unit={ unit }
                    allAdresses={ data }
                  />
                )
              })
            })
          )
          setLoading(false)
        },
        () => setLoading(false)
      )
    )

  },[dispatch, filterText, handleAddressQuantityChange, unit, productId, defaultValues])

  const RegisterAmountPaid = useCallback(() => {
    createDialog({
      id: 'register-history-price',
      Component: RegistrationAmountPaidModal,
      props: {
        productId: formRef.current?.getFieldValue('productId'),
        productUnit: unit
      }
    })
  }, [createDialog, formRef, unit])

  const ListAmountPaid = useCallback(() => {
    createDialog({
      id: 'complete-crop-management',
      Component: ViewListAmountPaidModal,
      props: {
        productId: formRef.current?.getFieldValue('productId'),
        organizationId: currentOrganization.id
      }
    })
  }, [createDialog, currentOrganization.id, formRef])

  useEffect(() => {
    if (!isEmpty(defaultDateValues)) {
      reloadSchema(formRef, true)
    }
  }, [defaultDateValues, formRef])

  useEffect(() => {
    if(!isEmpty(defaultValues) && formRef.current.getFieldRef('deliveryLocations')) {
      formRef.current.getFieldRef('deliveryLocations').setValue(uniq(filter(map(flatMap(defaultValues?.organizationPurchases, item => flatMap(item?.deliveryLocations, subItem => subItem)), item => item?.organizationId), item => find(addressesOptions, address => address.value === item))))
    }
  },[addressesOptions, defaultValues, formRef])

  return isEmpty(defaultDateValues)
    ? (<Loader/>)
    : (
      <Form
        ref={ formRef }
        schemaConstructor={ registerOrderSchema }
        schemaProps={ schemaProps }
        onSubmit={ handleSubmit }
        onError={ () => setSent(false) }
        style={ { flex: 1 } }
        loading={ loading }
      >
        <SplitedScreenLayout
          title={ { name: t('order', { howMany: 2 }) } }
          divRightStyle={ { paddingTop: 67 } }
          leftChildren={
            <Container>
              <Header style={ { paddingLeft: 35 } }>
                <IconContainer>
                  <Icon icon={ shoppingCart } size={ 18 } />
                </IconContainer>

                <Title style={ { fontSize: 16, fontWeight: 600 } }>
                  <I18n>new purchase order</I18n>
                </Title>
              </Header>

              <Item style={ { padding: '0 35px 0 35px' } }>
                <InputSelect
                  label={ t('product group') }
                  name="productGroupId"
                  options={ getProductGroupsService }
                  queryParams={ { purchasing: true } }
                  onChange={ changedProductGroup }
                  defaultValue={ defaultValues?.product?.productGroupId }
                />

                <InputSelect
                  label={ t('product') }
                  name="productId"
                  options={ products }
                  onChange={ handleProductChanges }
                  defaultValue={ defaultValues?.productId }
                />

                <InputSelect
                  label={ t('freight type') }
                  name="freightType"
                  options={ freightTypeOptions }
                  defaultValue={ defaultValues?.freightType ? toLower(defaultValues?.freightType) : '' }
                />

                <CheckboxTreeView
                  id="checkbox-tree-view"
                  name="paymentForms"
                  label={ t('payment methods') }
                  options={ paymentOptions }
                  onChangeOptions={ setPaymentOptions }
                  defaultExpanded={ defaultPaymentExpanded }
                />

                <Title style={ { fontSize: 16, fontWeight: 600 } }>
                  <I18n>demand parameterization dates</I18n>:
                </Title>

                <Row>
                  <DateWrapper>
                    <InputDate
                      pickerProps={ {
                        minDate: moment().format(),
                        disabledDates: [(date) => (date.getDay() === 0 || date.getDay() === 6)]
                      } }
                      onChange={ setParticipationDeadline }
                      label={ t('demand closing') }
                      name="participationDeadline"
                      defaultValue={ defaultDateValues?.participationDeadline }
                      style={ { display: 'flex', flex: 1, width: '100%' } }
                      onlyWeekDays
                    />
                  </DateWrapper>
                  <HourWrapper>
                    <InputSelect
                      label={ t('hour') }
                      name="participationDeadlineHour"
                      options={ hourOptions }
                      defaultValue={ defaultValues?.participationDeadline ? moment(defaultValues?.participationDeadline, momentBackDateTimeFormat).format(momentFriendlyTimeFormat) : '23:00' }
                      onChange={ (e) => {
                        setParticipationDeadlineHour(e.target.value)
                      } }
                    />
                  </HourWrapper>
                </Row>
                <Row>
                  <DateWrapper>
                    <InputDate
                      label={ t('proposal limit') }
                      name="proposalDeadline"
                      defaultValue={ defaultDateValues?.proposalDeadline }
                      pickerProps={ {
                        minDate: moment().format(),
                        disabledDates: [(date) => (date.getDay() === 0 || date.getDay() === 6)]
                      } }
                      onChange={ setProposalDeadline }
                      style={ { display: 'flex', flex: 1, width: '100%' } }
                    />
                  </DateWrapper>
                  <HourWrapper>
                    <InputSelect
                      label={ t('hour') }
                      name="proposalDeadlineHour"
                      options={ hourOptions }
                      defaultValue={ defaultValues?.proposalDeadline ? moment(defaultValues?.proposalDeadline, momentBackDateTimeFormat).format(momentFriendlyTimeFormat) : '23:00' }
                      onChange={ (e) => {
                        setProposalDeadlineHour(e.target.value)
                      } }
                    />
                  </HourWrapper>
                </Row>
                <Row>
                  <DateWrapper>
                    <InputDate
                      label={ t('organization decision limit') }
                      name="decisionDeadline"
                      pickerProps={ {
                        minDate: moment().format(),
                        disabledDates: [(date) => (date.getDay() === 0 || date.getDay() === 6)]
                      } }
                      onChange={ setDecisionDeadline }
                      defaultValue={ defaultDateValues?.decisionDeadline }
                      style={ { display: 'flex', flex: 1, width: '100%' } }
                    />
                  </DateWrapper>
                  <HourWrapper>
                    <InputSelect
                      label={ t('hour') }
                      name="decisionDeadlineHour"
                      options={ hourOptions }
                      defaultValue={ defaultValues?.decisionDeadline ? moment(defaultValues?.decisionDeadline, momentBackDateTimeFormat).format(momentFriendlyTimeFormat) : '23:00' }
                      onChange={ (e) => {
                        setDecisionDeadlineHour(e.target.value)
                      } }
                    />
                  </HourWrapper>
                </Row>

                <Row>
                  <DateWrapper>
                    <InputDate
                      label={ t('receipt limit') }
                      name="receiptDeadline"
                      pickerProps={ {
                        minDate: moment().format(),
                        disabledDates: [(date) => (date.getDay() === 0 || date.getDay() === 6)]
                      } }
                      onChange={ setReceiptDeadline }
                      style={ { display: 'flex', flex: 1, width: '100%' } }
                      defaultValue={ defaultDateValues?.receiptDeadline }
                    />
                  </DateWrapper>
                  <HourWrapper>
                    <InputSelect
                      label={ t('hour') }
                      name="receiptDeadlineHour"
                      options={ hourOptions }
                      defaultValue={ defaultValues?.receiptDeadline ? moment(defaultValues?.receiptDeadline, momentBackDateTimeFormat).format(momentFriendlyTimeFormat) : '23:00' }
                      onChange={ (e) => {
                        setReceiptDeadlineHour(e.target.value)
                      } }
                    />
                  </HourWrapper>
                </Row>

              </Item>
            </Container>
          }
          rightChildren={
            <Container style={ { flex: 1 } }>
              <div style={ { marginBottom: 15 } }>
                <DemandStatusFragment />
              </div>
              <div style={ { alignSelf: 'flex-end' } }>
                {productId && (
                  <Button
                    id="open-suppliers-by-products"
                    onClick={ ListSuppliersByProducts }
                    disabled={ isEmpty(suppliersProduct) }
                    style={ { flex: 'none', whiteSpace: 'nowrap', marginRight: 20 } }
                    color={ colors.white }
                  >
                    <I18n>open suppliers by products</I18n>
                  </Button>
                )}
                <Button
                  id="see-paid-price-history"
                  onClick={ ListAmountPaid }
                  style={ { width: 'auto', fontWeight: 600, marginRight: 10 } }
                  color={ colors.white }
                  disabled={ !formRef.current?.getFieldValue('productId') }
                >
                  <I18n>see paid price history</I18n>
                </Button>
                <Button
                  id="inform-price-paid"
                  onClick={ RegisterAmountPaid }
                  style={ { width: 'auto', fontWeight: 600 } }
                  color={ colors.white }
                  disabled={ !formRef.current?.getFieldValue('productId') }
                >
                  <I18n>inform price paid</I18n>
                </Button>
              </div>
              <FilteredCheckboxGroup
                name="deliveryLocations"
                label={ t('select delivery locations') }
                options={ addressesOptions }
                inputSearchStyle={ { backgroundColor: colors.white } }
                inputLabel={ t('search delivery location by cnpj') }
                disabled={ loading  || sent }
                setFilterText={ setFilterText }
                filterText={ filterText }
              />

              <TotalQuantityRow>
                <Row style={ { flex: 2 } }>
                  <TotalQuantity style={ { marginRight: 8 } }>
                    <I18n>total quantity</I18n>:
                  </TotalQuantity>
                  <TotalQuantity style={ { fontWeight: 'normal' } }>
                    {totalQuantity} {unit}
                  </TotalQuantity>
                </Row>

                <ButtonsContainer style={ { flex: 1 } }>
                  <Button
                    id="cancel-order-register-form"
                    onClick={ onCancel }
                    style={ { flex: 1 } }
                    variant="outlined"
                  >
                    <I18n>cancel</I18n>
                  </Button>

                  <div style={ { width: '10%' } } />

                  <Button
                    id="submit-order-register-form"
                    onClick={ () => {
                      formRef.current.submit()
                    } }
                    disabled={ loading || sent }
                    style={ { flex: 1 } }
                  >
                    <I18n>save</I18n>
                  </Button>
                </ButtonsContainer>
              </TotalQuantityRow>
            </Container>
          }
        />
      </Form>
    )
})

RegisterOrderForm.propTypes = {
  onSuccess: PropTypes.func,
  defaultValues: PropTypes.object,
  onCancel: PropTypes.func
}

RegisterOrderForm.defaultProps = {
  onSuccess: () => {},
  defaultValues: {},
  onCancel: () => {}
}

export default RegisterOrderForm
