import api from '../api'

export const getAnimalPevList = (params, { animalId }) =>
  api.get(`/v1/herds-management/animal/${ animalId }/pev`, { params })

export const createAnimalPev = (params, { propertyId, animalId }) =>
  api.post(`/v1/herds-management/property/${ propertyId }/pev/${ animalId }`, params)

export const getMilkControl = (params, { propertyId }) =>
  api.get(`/v1/herds-management/dairy-control/property/${ propertyId }`, { params })

export const getAllMilkControl = (params) =>
  api.get('/v1/herds-management/dairy-control/all', { params })

export const editMilkControl = (params) =>
  api.post('/v1/herds-management/dairy-control', params)

export const editAnimalPev = (params, { propertyId, pevId }) =>
  api.patch(`/v1/herds-management/property/${ propertyId }/pev/${ pevId }`, params)

export const deleteAnimalPev = ({ pevId }) =>
  api.delete(`/v1/herds-management/pev/${ pevId }`)

export const getPropertyAnimalsNextActions = ({ propertyId }) =>
  api.get(`/v1/herds-management/property/${ propertyId }/animals/next-action`)

export const getAnimalsNextActionsTypes = (params, { propertyId }) =>
  api.get(`/v1/herds-management/property/${ propertyId }/animals/next-action/types`, { params })

export const getAnimalsDiseases = (params) =>
  api.get('/v1/herds-management/medication-control/animal-diseases', { params })

export const createMedicationControl = (params) =>
  api.post('/v1/herds-management/medication-control', params)

export const getBulls = (params) =>
  api.get('/v1/herds-management/bulls', { params })

export const editBull = (params, { bullId }) =>
  api.patch(`/v1/herds-management/bulls/${ bullId }`, params)

export const deleteBull = (params, { bullId }) =>
  api.delete(`/v1/herds-management/bulls/${ bullId }`)

export const createBull = (params) =>
  api.post('/v1/herds-management/bulls', params)

export const editMedicationControl = (params, { registerCode }) =>
  api.patch(`/v1/herds-management/medication-control/register-code/${ registerCode }`, params)

export const deleteMedicationControl = (params, { registerCode }) =>
  api.delete(`/v1/herds-management/medication-control/${ registerCode }`, { data: params })

export const getMedicationControls = (params, { propertyId }) =>
  api.get(`/v1/herds-management/medication-control/property/${ propertyId }`, { params })
