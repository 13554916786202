import React, { useEffect, useCallback, forwardRef, useState, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import trimMask from '@meta-awesome/functions/src/trimMask'
import PropTypes from 'prop-types'


import filter from 'lodash/filter'
import find from 'lodash/find'
import findIndex from 'lodash/findIndex'
import findLastIndex from 'lodash/findLastIndex'
import flatMap from 'lodash/flatMap'
import groupBy from 'lodash/groupBy'
import includes from 'lodash/includes'
import isEmpty from 'lodash/isEmpty'
import isNumber from 'lodash/isNumber'
import map from 'lodash/map'
import orderBy from 'lodash/orderBy'
import replace from 'lodash/replace'
import toNumber from 'lodash/toNumber'
import toString from 'lodash/toString'
import uniqBy from 'lodash/uniqBy'

import Autorenew from '@material-ui/icons/Autorenew'
import Done from '@material-ui/icons/Done'


import { useDialog } from '@smartcoop/dialog'
import nfeIntegratorSchema from '@smartcoop/forms/schemas/profile/nfeIntegrator.schema'
import I18n, { useT } from '@smartcoop/i18n'
import { arrowLeftSimple, arrowRightSimple, emptyFilter, nfeLogo, alert as iconAlert } from '@smartcoop/icons'
import { searchCep } from '@smartcoop/services/apis/brasilApi'
import { searchCityById } from '@smartcoop/services/apis/ibgeApi'
import { getFiscalFigure, getNfeOrganizations } from '@smartcoop/services/apis/smartcoopApi/resources/nfe'
import { getUserStateRegistrations as getUserStateRegistrationsService } from '@smartcoop/services/apis/smartcoopApi/resources/stateRegistration'
import { NfeActions } from '@smartcoop/stores/nfe'
import { selectProducer } from '@smartcoop/stores/nfe/selectorNfe'
import { selectUser } from '@smartcoop/stores/user/selectorUser'
import { colors } from '@smartcoop/styles'
import { formatNCM, formatCep, formatCpfCnpj, formatKebabCase } from '@smartcoop/utils/formatters'
import Button from '@smartcoop/web-components/Button'
import CardAccordion from '@smartcoop/web-components/CardAccordion'
import EmptyState from '@smartcoop/web-components/EmptyState'
import Form from '@smartcoop/web-components/Form'
import Icon from '@smartcoop/web-components/Icon'
import InputCep from '@smartcoop/web-components/InputCep'
import InputCpfCnpj from '@smartcoop/web-components/InputCpfCnpj'
import InputPhone from '@smartcoop/web-components/InputPhone'
import InputSelect from '@smartcoop/web-components/InputSelect'
import InputText from '@smartcoop/web-components/InputText'
import ConfirmModal from '@smartcoop/web-components/Modal/ConfirmModal'
import Tooltip from '@smartcoop/web-components/Tooltip'
import { ButtonsContainer } from '@smartcoop/web-containers/layouts/AuthenticatedLayout/theme'
import LoadingModal from '@smartcoop/web-containers/modals/LoadingModal'


import { Container, TabsGroup, TabItem, FieldInfoText, ButtonIntegrate, TextBold, TextInfo, Table } from './styles'

const NfeIntegratorForm = forwardRef((props, formRef) => {
  const { style, onSuccess, handleClose, setStep, step, inputSearch, setInputSearch } = props
  const dispatch = useCallback(useDispatch(), [])

  const [stateRegistrations, setStateRegistrations] = useState([])
  const [selectedStateRegistrations, setSelectedStateRegistrations] = useState([])
  const [formData, setFormData] = useState(null)
  const [Inscricao, setInscricao] = useState([])
  const [fiscalFigures, setFiscalFigures] = useState([])
  const [organizations, setOrganizations] = useState([])
  const [tab, setTab] = useState(null)

  const { createDialog , removeDialog } = useDialog()
  const t = useT()
  const user = useSelector(selectUser)

  const defaultValues = useSelector(selectProducer)


  const loadStateRegistrations = useCallback(
    async () => {
      const { data: { data } } = await getUserStateRegistrationsService()

      setStateRegistrations(data)
    },
    []
  )

  const loadOrganizations = useCallback(
    async () => {
      const { data: { data } } = await getNfeOrganizations({ nfe: true })
      setOrganizations(map(data, item => item?.nfe ? ({ ...item, subsidiaries: [item, ...item?.subsidiaries] }) : item ))
    },
    []
  )

  const loadFiscalFigures = useCallback(
    async () => {
      const { data } = await getFiscalFigure()
      setFiscalFigures(groupBy(data, 'organizationId'))
    },
    []
  )

  useEffect(() => {
    dispatch(NfeActions.loadProducer())
    loadStateRegistrations()
    loadFiscalFigures()
    loadOrganizations()
  }, [dispatch, loadFiscalFigures, loadOrganizations, loadStateRegistrations])

  useEffect(() => {
    if(!isEmpty(defaultValues)) {
      setSelectedStateRegistrations(map(defaultValues?.Inscricao, item => item?.InscricaoEstadual))

      const loadAddressData = async () => {
        const currentCity = await searchCityById(defaultValues?.Cidade)
        formRef.current.getFieldRef('CEP').setValue(toString(defaultValues?.CEP))
        formRef.current.getFieldRef('Cidade').setValue(currentCity?.nome)
        formRef.current.getFieldRef('Uf').setValue(currentCity?.microrregiao?.mesorregiao?.UF?.sigla)
      }
      loadAddressData()

      const func = map(defaultValues?.Inscricao, async (item) => {
        const currentCity = await searchCityById(item?.Cidade)
        return {
          ...item,
          Cidade: currentCity?.nome,
          Uf: currentCity?.microrregiao?.mesorregiao?.UF?.sigla
        }
      })

      Promise.all(func).then((data) => {
        const ordered = map(map(defaultValues?.Inscricao, item => item?.InscricaoEstadual), item => {
          const found = find(data, ie => ie?.InscricaoEstadual === item)
          return { ...found, Cep: toString(found?.Cep) }
        })
        setInscricao(ordered)
        setTab(ordered[0]?.InscricaoEstadual)
      })

    }
  }, [defaultValues, formRef])

  useEffect(() => () => {
    dispatch(NfeActions.loadProducerSuccess({}))
  },[dispatch])

  const isUserIntegrationStep = useMemo(() => step === 3, [step])

  const stateRegistrationOptions = useMemo(() => {
    if(!isEmpty(defaultValues)) {
      const defaultRegistrations = map(defaultValues?.Inscricao, item => item?.InscricaoEstadual)
      const registrations = map(stateRegistrations, item => item?.stateRegistration)
      const notFound = filter(defaultRegistrations, item => !includes(registrations, item))

      const merged = map([...registrations, ...defaultRegistrations], item => ({ label: item, value: item, notFound: includes(notFound, item), data: find(stateRegistrations, registration => registration?.stateRegistration === item) }))
      return uniqBy(merged, 'value')
    }
    return map(stateRegistrations, item => ({ label: item?.stateRegistration, value: item?.stateRegistration, data: item }))
  }, [defaultValues, stateRegistrations])

  const onStateRegistrationChange = useCallback(async (value, registration) => {
    setSelectedStateRegistrations(value)
    const result = map(value, (item, index) => {
      const foundSavedRegistration = find(defaultValues?.Inscricao, ie => ie.InscricaoEstadual === item)

      const currentData = { ...Inscricao[index] }
      return {
        ...currentData,
        Nome: Inscricao?.[index]?.Nome || foundSavedRegistration?.Nome,
        Cep: Inscricao?.[index]?.Cep ||  foundSavedRegistration?.Cep ? toString(foundSavedRegistration?.Cep) : registration?.data?.postalCode,
        Cidade: Inscricao?.[index]?.Cidade || registration?.data?.city,
        Uf: Inscricao?.[index]?.Uf || registration?.data?.state,
        Logradouro: Inscricao?.[index]?.Logradouro || foundSavedRegistration?.Logradouro || registration?.data?.street,
        Bairro: Inscricao?.[index]?.Bairro || foundSavedRegistration?.Bairro || registration?.data?.district,
        NroEnd: Inscricao?.[index]?.NroEnd || foundSavedRegistration?.NroEnd || registration?.data?.number,
        InscricaoEstadual: item
      }
    })

    const updatedResult = [...result]

    await Promise.all(map(result, async (item, index) => {
      if(item?.Cep) {
        const address = await searchCep(item?.Cep)
        updatedResult[index] = {
          ...updatedResult[index],
          Cep: trimMask(address?.cep),
          Cidade: address?.city,
          Uf: address?.state
        }
      }
    }))
    setInscricao(updatedResult)
    setTab(value[findLastIndex(value)])
  }, [Inscricao, defaultValues.Inscricao])



  const handleUpdateFormData = useCallback((data) => {
    setFormData(data)
    setStep(step + 1)
  },[setStep, step])

  const handleSubmitNfeIntegrator = useCallback(
    async (data) => {
      const currentCity = await searchCep(replace(data?.CEP, '-', ''))

      let updatedStateRegistrationData = []

      const func = map([...Inscricao], async item => {
        const currentStateRegistrationCity = await searchCep(replace(item?.Cep, '-', ''))

        return {
          ...item,
          Cidade: currentStateRegistrationCity?.ibge,
          Cep: toNumber(replace(item?.Cep, '-', ''))
        }
      })

      await Promise.all(func).then((result) => {
        updatedStateRegistrationData = result
      })

      const updatedData = {
        ...data,
        Cidade: currentCity?.ibge,
        CEP: toNumber(replace(data?.CEP, '-', '')),
        Inscricao: updatedStateRegistrationData
      }

      createDialog({
        id: 'confirm-submit-nfe-integrator-form',
        Component: ConfirmModal,
        props: {
          onConfirm: () => {
            createDialog({
              id: 'loading',
              Component: LoadingModal
            })
            dispatch(NfeActions.integrateNfe(
              updatedData,
              () => {
                onSuccess()
                removeDialog({ id: 'loading' })
              },
              () => {
                removeDialog({ id: 'loading' })
              }
            ))
          },
          textWarning: t('are you sure you want to register this integration?')
        }
      })
    },
    [Inscricao, createDialog, dispatch, onSuccess, removeDialog, t]
  )

  const receiveAddress = useCallback(
    (address, inscricao = false) => {
      if(isNumber(inscricao)) {
        const currentInscricao = [...Inscricao]

        currentInscricao[inscricao] = {
          ...currentInscricao[inscricao],
          Cep: trimMask(address?.cep),
          Cidade: address?.city,
          Uf: address?.state
        }

        setInscricao(currentInscricao)
      } else {
        formRef.current.setData({
          Cidade: address?.city,
          Cep: trimMask(address?.cep),
          Uf: address?.state
        })
      }
    },
    [Inscricao, formRef]
  )

  const blockSave = useMemo(() => find(Inscricao, item =>
    isEmpty(item?.Nome)
      || isEmpty(item?.Cep)
      || isEmpty(item?.Cidade)
      || isEmpty(item?.Uf)
      || isEmpty(item?.Bairro)
      || isEmpty(item?.Logradouro)
      || isEmpty(item?.NroEnd)
  ), [Inscricao])

  const renderList = useMemo(() => {
    const currentIndex = findIndex(selectedStateRegistrations, item => item === tab)

    return tab && (
      <div>
        <InputText
          detached
          label={ t('state registration') }
          fullWidth
          value={ tab }
        />
        <InputText
          detached
          required
          label={ t('property', { howMany: 1 }) }
          fullWidth
          onChange={ ({ target: { value } }) => {
            const currentData = [...Inscricao]
            currentData[currentIndex] = {
              ...currentData[currentIndex],
              Nome: value
            }
            setInscricao(currentData)
          } }
          value={ Inscricao[currentIndex]?.Nome }
        />
        <InputCep
          detached
          required
          label={ t('cep') }
          fullWidth
          onAddressChange={ (address) => receiveAddress(address, currentIndex) }
          onChange={ ({ target: { value } }) => {
            const currentData = [...Inscricao]
            currentData[currentIndex] = {
              ...currentData[currentIndex],
              Cep: value
            }
            setInscricao(currentData)
          } }
          value={ Inscricao[currentIndex]?.Cep }
        />
        <InputText
          detached
          label={ t('city', { howMany: 1 }) }
          fullWidth
          disabled
          value={ Inscricao[currentIndex]?.Cidade }
        />
        <InputText
          detached
          label={ t('state', { howMany: 1 }) }
          fullWidth
          disabled
          value={ Inscricao[currentIndex]?.Uf }
        />
        <InputText
          detached
          required
          label={ t('neighborhood') }
          fullWidth
          onChange={ ({ target: { value } }) => {
            const currentData = [...Inscricao]
            currentData[currentIndex] = {
              ...currentData[currentIndex],
              Bairro: value
            }
            setInscricao(currentData)
          } }
          value={ Inscricao[currentIndex]?.Bairro }
        />
        <InputText
          detached
          required
          label={ t('street') }
          fullWidth
          onChange={ ({ target: { value } }) => {
            const currentData = [...Inscricao]
            currentData[currentIndex] = {
              ...currentData[currentIndex],
              Logradouro: value
            }
            setInscricao(currentData)
          } }
          value={ Inscricao[currentIndex]?.Logradouro }
        />
        <InputText
          detached
          required
          label={ t('number') }
          fullWidth
          onChange={ ({ target: { value } }) => {
            const currentData = [...Inscricao]
            currentData[currentIndex] = {
              ...currentData[currentIndex],
              NroEnd: value
            }
            setInscricao(currentData)
          } }
          value={ Inscricao[currentIndex]?.NroEnd }
        />
        <InputText
          detached
          label={ t('complement') }
          fullWidth
          onChange={ ({ target: { value } }) => {
            const currentData = [...Inscricao]
            currentData[currentIndex] = {
              ...currentData[currentIndex],
              Complemento: value
            }
            setInscricao(currentData)
          } }
          value={ Inscricao[currentIndex]?.Complemento }
        />
      </div>
    )
  },[Inscricao, receiveAddress, selectedStateRegistrations, t, tab])

  const integrateProduct = useCallback(async (data) => {
    createDialog({
      id: 'confirm-submit-nfe-integration-control',
      Component: ConfirmModal,
      props: {
        onConfirm: () => {
          createDialog({
            id: 'loading',
            Component: LoadingModal
          })
          dispatch(NfeActions.integrationControl(
            data,
            () => {
              onSuccess(false)
              loadFiscalFigures()
            },
            () => {
              removeDialog({ id: 'loading' })
            }
          ))
        },
        textWarning: t('are you sure you want to register this integration?')
      }
    })
  }, [createDialog, dispatch, loadFiscalFigures, onSuccess, removeDialog, t])

  const integrateClientes = useCallback(async (data) => {
    createDialog({
      id: 'confirm-submit-nfe-integration-clientes',
      Component: ConfirmModal,
      props: {
        onConfirm: () => {
          createDialog({
            id: 'loading',
            Component: LoadingModal
          })
          dispatch(NfeActions.integrationControl(
            data,
            () => {
              onSuccess(false)
              loadFiscalFigures()
              loadOrganizations()
            },
            () => {
              removeDialog({ id: 'loading' })
            }
          ))
        },
        textWarning: t('are you sure you want to register this integration?')
      }
    })
  }, [createDialog, dispatch, loadFiscalFigures, loadOrganizations, onSuccess, removeDialog, t])

  return (
    <Container>
      <Form
        style={ { ...style } }
        ref={ formRef }
        schemaConstructor={ nfeIntegratorSchema }
        onSubmit={ handleUpdateFormData }
      >
        <div style={ { display: step === 1 ? 'flex' : 'none', flexDirection: 'column' } }>
          <Icon icon={ nfeLogo } size="20vh" />
          <p>O SMARTCOOP NF-e é um aplicativo para gerenciamento e emissão de documentos fiscais destinados a produtores rurais. </p>
          <p>Para a emissão dos documentos, você deverá dispor de um Certificado Digital SafeID e-CPF A3 ou e-CNPJ A3, que pode ser adquirido junto a sua cooperativa.</p>
          <p>Para habilitar o uso do aplicativo, é necessária a confirmação de seus dados cadastrais.</p>
        </div>
        <div style={ { display: step === 2 ? 'flex' : 'none', flexDirection: 'column', alignItems: 'center' } }>
          <InputCpfCnpj
            name="CpfCnpj"
            label="CPF/CNPJ"
            defaultValue={ defaultValues?.CpfCnpj || user?.document }
            fullWidth
          />
          <InputText
            name="Nome"
            label={ t('name') }
            fullWidth
            defaultValue={ defaultValues?.Nome || user?.name }
          />
          <InputText
            name="Fantasia"
            label={ t('trade name') }
            fullWidth
            defaultValue={ defaultValues?.Fantasia || user?.chatNickname }
          />
          <InputCep
            name="CEP"
            label={ t('cep') }
            fullWidth
            onAddressChange={ receiveAddress }
            defaultValue={ defaultValues?.CEP ? toString(defaultValues?.CEP) : user?.postalCode }
          />
          <InputText
            name="Uf"
            label={ t('state', { howMany: 1 }) }
            fullWidth
            defaultValue={ defaultValues?.Uf || user?.state }
            disabled
          />
          <InputText
            name="Cidade"
            label={ t('city', { howMany: 1 }) }
            fullWidth
            defaultValue={ defaultValues?.Cidade || user?.city }
            disabled
          />
          <InputText
            name="Bairro"
            label={ t('neighborhood') }
            fullWidth
            defaultValue={ defaultValues?.Bairro || user?.district }
          />
          <InputText
            name="Logradouro"
            label={ t('street') }
            fullWidth
            defaultValue={ defaultValues?.Logradouro || user?.street }
          />
          <InputText
            name="NroEnd"
            label={ t('number') }
            fullWidth
            defaultValue={ defaultValues?.NroEnd || user?.number }
          />
          <InputText
            name="Complemento"
            label={ t('complement') }
            fullWidth
            defaultValue={ defaultValues?.Complemento || user?.name }
          />
          <InputText
            name="Email"
            label={ t('email') }
            fullWidth
            defaultValue={ defaultValues?.Email || user?.email }
          />
          <InputPhone
            name="Fone"
            label={ t('phone') }
            defaultValue={ defaultValues?.Fone || user.cellPhone }
            style={ { width: '100%' } }
          />
        </div>
        <div style={ { display: step === 3 ? 'flex' : 'none', flexDirection: 'column', alignItems: 'center' } }>
          <InputSelect
            detached
            label={ t('state registration') }
            options={ stateRegistrationOptions }
            onChange={ onStateRegistrationChange }
            value={ selectedStateRegistrations }
            multiple
            required
          />

          {!isEmpty(selectedStateRegistrations) && (
            <TabsGroup
              value={ tab }
              onChange={ (_, newTab) => {
                setTab(newTab)
              } }
            >
              {map(selectedStateRegistrations, option => {
                const notFound = find(stateRegistrationOptions, item => item?.value === option && item?.notFound)

                return (
                  <TabItem
                    notFound={ notFound }
                    value={ option }
                    key={ option }
                    label={ <Tooltip style={ { display: 'flex', flexDirection: 'row', alignItems: 'center' } } title={ notFound ? t('ie not found') : option }>
                      {option}{ notFound ? <Icon size={ 16 } icon={ iconAlert } style={ { backgroundColor: colors.secondary, color: '#fff', height: 16, borderRadius: 16/2, marginLeft: 5 } } /> : '' }
                    </Tooltip>
                    }
                  />

                )
              })}
            </TabsGroup>
          )}
          { renderList }
          {(blockSave || isEmpty(Inscricao)) && (
            <FieldInfoText>{t('required fields not informed')}</FieldInfoText>
          )}
        </div>
        <div style={ { display: step === 4 ? 'flex' : 'none', flexDirection: 'column', alignItems: 'center', marginBottom: 20 } }>
          {map(orderBy(fiscalFigures, 'organization.tradeName'), (fiscalFigure, key) => {
            const fiscalProducts = flatMap(fiscalFigure, figure => map(figure.products, product => ({ ...product, figureId: figure?.id, cfopId: figure?.cfop?.id, figureCode: figure?.code, cfop: figure?.cfop?.code, cfopDescription: figure?.description })))
            const fiscalProductsList = !inputSearch ? fiscalProducts : filter(fiscalProducts, item => includes(formatKebabCase(item?.description), formatKebabCase(inputSearch)) || includes(formatKebabCase(item?.cfop), formatKebabCase(inputSearch)) || includes(formatKebabCase(item?.ncm), formatKebabCase(inputSearch)))
            return isEmpty(fiscalProductsList) ? (
              <CardAccordion defaultOpened headerLeft={ fiscalFigure[0]?.organization?.tradeName } key={ key } title=" ">
                <EmptyState text={ t('no product configuration suggested by the organization') } icon={ emptyFilter } />
              </CardAccordion>
            ) : (
              <CardAccordion defaultOpened headerLeft={ fiscalFigure[0]?.organization?.tradeName } key={ key } title=" ">
                <Table>
                  {map(orderBy(fiscalProductsList, 'description'), fiscalProduct => (
                    <tr>
                      <td style={ { width: '30%' } }>
                        { formatNCM(fiscalProduct?.ncm) } - { fiscalProduct?.description }
                      </td>
                      <td style={ { width: '30%' } }>
                        <span>CFOP </span>{ fiscalProduct?.cfop }
                      </td>
                      <td style={ { width: '30%' } }>
                        { fiscalProduct?.figureCode } - { fiscalProduct?.cfopDescription }
                      </td>
                      <td style={ { width: '10%', paddingLeft: 10, paddingRight: 10 } }>
                        {fiscalProduct?.isIntegrated ? (
                          <ButtonIntegrate isIntegrated>
                            <Done size={ 30 } style={ { color: 'rgb(40,159,48)', marginRight: 5 } } />
                            <TextBold style={ { color: 'rgb(40,159,48)' } }>{t('integrated')}</TextBold>
                          </ButtonIntegrate>
                        ) : (
                          <ButtonIntegrate
                            onClick={
                              () => integrateProduct(
                                {
                                  cfopId: fiscalProduct?.cfopId,
                                  productId: fiscalProduct?.id,
                                  nfeFiscalFigureId: fiscalProduct?.figureId
                                }
                              )
                            }
                          >
                            <Autorenew size={ 30 } color={ colors.grey } style={ { marginRight: 5 } } />
                            <TextBold>{t('integrate')}</TextBold>
                          </ButtonIntegrate>

                        )}
                      </td>
                    </tr>
                  ))}
                </Table>

              </CardAccordion>
            )
          })}
        </div>
        <div style={ { display: step === 5 ? 'flex' : 'none', flexDirection: 'column', alignItems: 'center', marginBottom: 20 } }>
          {map(orderBy(organizations, 'tradeName'), (grouper) => {
            const subsidiariesList = !inputSearch ? grouper?.subsidiaries : filter(grouper?.subsidiaries, item => includes(formatKebabCase(item?.companyDocument), formatKebabCase(inputSearch)) || includes(formatKebabCase(item?.tradeName), formatKebabCase(inputSearch)) || includes(formatKebabCase(item?.city), formatKebabCase(inputSearch)))
            return isEmpty(subsidiariesList) ? (
              <CardAccordion defaultOpened headerLeft={ grouper?.tradeName } key={ grouper?.id } title=" ">
                <EmptyState text={ t('no subsidiary configuration suggested by the organization') } icon={ emptyFilter } />
              </CardAccordion>
            ) :  (
              <CardAccordion defaultOpened headerLeft={ grouper?.tradeName } key={ `main-${ grouper?.id }` } title=" ">
                <Table>
                  {map(orderBy(subsidiariesList, 'tradeName'), organization => (
                    <tr key={ organization?.id }>
                      <td style={ { width: '60%', paddingLeft: 50 } }>
                        <div style={ { fontWeight: 'bold' } }>
                            CNPJ { formatCpfCnpj(organization?.companyDocument) } - {organization?.tradeName}
                        </div>
                        <TextInfo>
                          {organization?.street}
                        </TextInfo>
                        <TextInfo>
                          {organization?.district}
                        </TextInfo>
                      </td>
                      <td style={ { width: '30%' } }>
                        <TextInfo>
                          <span>CEP </span>{ formatCep(organization?.postalCode) }
                        </TextInfo>
                        <TextInfo>
                          {organization?.city} - {organization?.state}
                        </TextInfo>
                      </td>
                      <td style={ { width: '10%', paddingLeft: 10, paddingRight: 10 } }>
                        {organization?.isIntegrated ? (
                          <ButtonIntegrate isIntegrated>
                            <Done size={ 30 } style={ { color: 'rgb(40,159,48)', marginRight: 5 } } />
                            <TextBold style={ { color: 'rgb(40,159,48)' } }>{t('integrated')}</TextBold>
                          </ButtonIntegrate>
                        ) : (
                          <ButtonIntegrate
                            onClick={
                              () => {
                                integrateClientes({ organizationId: organization?.id })
                              }
                            }
                          >
                            <Autorenew size={ 30 } style={ { marginRight: 5  } } />
                            <TextBold>{t('integrate')}</TextBold>
                          </ButtonIntegrate>

                        )}
                      </td>
                    </tr>
                  ))}
                </Table>
              </CardAccordion>
            )
          } )}
        </div>
        {step >= 4 && (
          <ButtonsContainer>
            <Button
              id="close-nfe-integrator"
              onClick={ () => {
                setInputSearch(null)
                setStep(step - 1)
              } }
              style={ { flex: 1, marginRight: 10 } }
              variant="outlined"
              disabled={ step === 4 }
            >
              <Icon icon={ arrowLeftSimple } size={ 14 } style={ { marginRight: 25 } }/>
              <I18n>products</I18n>
            </Button>
            <Button
              id="close-nfe-integrator"
              onClick={ () => {
                setInputSearch(null)
                setStep(step + 1)
              } }
              style={ { flex: 1, marginLeft: 10 } }
              variant="outlined"
              disabled={ step === 5 }
            >
              <I18n>customers</I18n>
              <Icon icon={ arrowRightSimple } size={ 14 } style={ { marginLeft: 25 } }/>
            </Button>
          </ButtonsContainer>
        )}
        {step < 4 && (
          <ButtonsContainer>
            <Button
              id="cancel-nfe-integrator"
              onClick={ step === 1 ? handleClose : () => setStep(step - 1) }
              style={ { flex: 1, marginRight: 10 } }
              variant="outlined"
            >
              <I18n>{step === 1 ? 'cancel' : 'go back'}</I18n>
            </Button>
            <Button
              id="submit-nfe-integrator"
              // eslint-disable-next-line no-nested-ternary
              onClick={ step === 2 ? () => formRef.current.submit() : (isUserIntegrationStep ? () => handleSubmitNfeIntegrator(formData) : () => setStep(step + 1))  }
              style={ { flex: 1 } }
              disabled={ isUserIntegrationStep && (blockSave || isEmpty(Inscricao)) }
            >
              <I18n>{isUserIntegrationStep ? 'confirm' : 'next'}</I18n>
            </Button>
          </ButtonsContainer>
        )}
      </Form>
    </Container>
  )
})

NfeIntegratorForm.propTypes = {
  style: PropTypes.object,
  step: PropTypes.number,
  handleClose: PropTypes.func,
  setInputSearch: PropTypes.func,
  inputSearch: PropTypes.string,
  setStep: PropTypes.func,
  onSuccess: PropTypes.func
}

NfeIntegratorForm.defaultProps = {
  style: {},
  step: 1,
  inputSearch: null,
  setInputSearch: () => {},
  handleClose: () => {},
  setStep: () => {},
  onSuccess: () => {}
}

export default NfeIntegratorForm
