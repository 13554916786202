import { REACT_APP_FAKE_PAGINATION_SIZE } from 'react-native-dotenv'

import { call, put, takeLatest, select } from 'redux-saga/effects'

import { find, isEmpty } from 'lodash'
import map from 'lodash/map'
import size from 'lodash/size'

import { getActivities } from '@smartcoop/services/apis/smartcoopApi/resources/activity'
import { getProductivityChallengeByProperty } from '@smartcoop/services/apis/smartcoopApi/resources/challenges'
import { createCostsManagementItem, deleteCostsManagementItem, editCostsManagementItem, getCostsManagementGroups } from '@smartcoop/services/apis/smartcoopApi/resources/costsManagement'
import { getPropertyAnimalsNextActions } from '@smartcoop/services/apis/smartcoopApi/resources/herdsManagement'
import {
  postPropertiesAssets,
  deletePropertiesAssets,
  putPropertiesAssets,
  postPropertyAssetsMovementsService,
  postPropertyAssetsListService
} from '@smartcoop/services/apis/smartcoopApi/resources/propertiesAssets'
import {
  getProperty,
  getProperties,
  createProperty,
  editProperty,
  deleteProperty as doDeleteProperty,
  getPendingManagements,
  loadPropertyParameters,
  patchPropertyParameters
} from '@smartcoop/services/apis/smartcoopApi/resources/property'
import { DairyFarmActions } from '@smartcoop/stores/dairyFarm/duckDairyFarm'
import { FieldActions } from '@smartcoop/stores/field'
import { ModuleActions } from '@smartcoop/stores/module/duckModule'
import { selectModuleIsTechnical } from '@smartcoop/stores/module/selectorModule'
import {
  selectCurrentProperty,
  selectOfflineProperty,
  selectFamilyGroupAccess
} from '@smartcoop/stores/property/selectorProperty'
import { TechnicalActions, TechnicalTypes } from '@smartcoop/stores/technical'
import { selectCurrentOwner, selectCurrentOwnerId } from '@smartcoop/stores/technical/selectorTechnical'

import { AuthenticationActions } from '../authentication/duckAuthentication'
import { selectAuthenticated } from '../authentication/selectorAuthentication'
import { PropertyActions, PropertyTypes } from './duckProperty'

function* loadProperties({ onSuccess = () => {}, onError = () => {} }) {
  try {
    const currentOwner = yield select(selectCurrentOwner)
    const moduleIsTechnical = yield select(selectModuleIsTechnical)

    if (moduleIsTechnical) {
      yield put(TechnicalActions.loadPropertiesByOwner(currentOwner, onSuccess, onError))
    } else {
      const { data } = yield call(getProperties, {
        limit:
          process.env.REACT_APP_FAKE_PAGINATION_SIZE ||
          REACT_APP_FAKE_PAGINATION_SIZE
      })

      if (size(data?.properties) === 1 && size(data?.groupFamilyProperties ?? []) === 0) {
        yield put(PropertyActions.saveCurrentProperty(data.properties[0]))
      }

      yield put(PropertyActions.loadPropertiesFamilyGroupSuccess(data?.groupFamilyProperties ?? []))

      const currentProperty = yield select(selectCurrentProperty)
      const familyGroupAccess = yield select(selectFamilyGroupAccess)

      if (
        familyGroupAccess &&
        !isEmpty(data?.groupFamilyProperties) &&
        !isEmpty(currentProperty) &&
        !find(data?.groupFamilyProperties, property => property?.id === currentProperty?.id) &&
        !find(data?.properties, property => property?.id === currentProperty?.id)
      ) {
        yield put(PropertyActions.resetCurrentProperty())
        yield put(ModuleActions.exitCurrentModule())
      } else if (
        familyGroupAccess &&
        isEmpty(data?.groupFamilyProperties)
      ) {
        yield put(PropertyActions.propertyError('Acesso do grupo familiar revogado. Contate o proprietário.'))
        yield put(AuthenticationActions.logout())
      }

      yield put(PropertyActions.loadPropertiesSuccess(data?.properties ?? [], onSuccess))
    }
  } catch (err) {
    const error = err.message
    yield put(PropertyActions.propertyError(error))
    yield call(onError, error)
  }
}

function* loadPropertiesSuccess({ onSuccess }) {
  yield call(onSuccess)
}

function* saveCurrentProperty() {
  yield put(DairyFarmActions.resetDairyFarmMonth())
  yield put(FieldActions.resetFields())
}

function* saveOfflineProperty() {
  try {
    const { data, activitiesDetails } = yield select(selectOfflineProperty)

    const ownerId = yield select(selectCurrentOwnerId)

    const activities = map(activitiesDetails, (details, id) => ({
      id,
      details
    }))
    const body = { ...data, activities, ownerId }

    yield put(PropertyActions.saveProperty(body))

    if (!data.id) {
      yield put(PropertyActions.resetOfflineProperty())
    }
  } catch (err) {
    const error = err.message
    yield put(PropertyActions.propertyError(error))
  }
}

function* saveProperty({ property }) {
  try {
    const { data } = yield call(
      property?.id ? editProperty : createProperty,
      property,
      { propertyId: property?.id }
    )
    yield put(PropertyActions.saveCurrentProperty(data))

    yield put(PropertyActions.loadProperties())
  } catch (err) {
    const error = err.message
    yield put(PropertyActions.propertyError(error))
  }
}

function* saveRubric({ params, onSuccess = () => {}, onError = () => {} }) {
  try {
    yield call(
      params?.id ? editCostsManagementItem : createCostsManagementItem,
      params,
      { rubricId: params?.id }
    )

    yield call(onSuccess)
  } catch (err) {
    const error = err.message
    yield call(onError, error)
    yield put(PropertyActions.propertyError(error))
  }
}

function* deleteRubric({ rubricId, onSuccess = () => {}, onError = () => {} }) {
  try {
    yield call(
      deleteCostsManagementItem,
      {},
      { rubricId }
    )

    yield call(onSuccess)
  } catch (err) {
    const error = err.message
    yield call(onError, error)
    yield put(PropertyActions.propertyError(error))
  }
}

function* loadActivities() {
  try {
    const { data } = yield call(getActivities, {
      limit:
        process.env.REACT_APP_FAKE_PAGINATION_SIZE ||
        REACT_APP_FAKE_PAGINATION_SIZE
    })
    yield put(PropertyActions.loadActivitiesSuccess(data))
  } catch (err) {
    const error = err.message
    yield put(PropertyActions.propertyError(error))
  }
}

function* loadCurrentProperty() {
  const currentProperty = yield select(selectCurrentProperty)

  try {
    const { data } = yield call(getProperty, {}, { propertyId: currentProperty?.id })
    yield put(PropertyActions.saveCurrentProperty(data))
  } catch (err) {
    const error = err.message
    yield put(PropertyActions.propertyError(error))
  }
}

function* loadPropertyChallenges({ params, onSuccess = () => {}, onError = () => {} }) {
  try {
    const currentProperty = yield select(selectCurrentProperty)

    const { data } = yield call(getProductivityChallengeByProperty, params, { propertyId: currentProperty?.id })
    yield put(PropertyActions.loadPropertyChallengesSuccess(data))
    yield call(onSuccess)
  } catch (err) {
    const error = err.message
    yield put(PropertyActions.propertyError(error))
    yield call(onError, err)
  }
}

function* loadPropertyPendingManagements({
  propertyId,
  onSuccess = () => {},
  onError = () => {}
}) {
  try {
    const data = yield call(getPendingManagements, { propertyId })
    const authenticated = yield select(selectAuthenticated)
    if (authenticated) {
      yield put(PropertyActions.loadPropertyPendingManagementsSuccess(data))
      yield call(onSuccess)
    }
  } catch (err) {
    const error = err.message
    yield put(PropertyActions.propertyError(error))
    yield call(onError, err)
  }
}

function* loadPropertyDairyFarmNextActions({
  propertyId,
  onSuccess = () => {},
  onError = () => {}
}) {
  try {
    const { data: { data } } = yield call(getPropertyAnimalsNextActions, { propertyId })
    const authenticated = yield select(selectAuthenticated)
    if (authenticated) {
      yield put(PropertyActions.loadPropertyDairyFarmNextActionsSuccess(data))
      yield call(onSuccess)
    }
  } catch (err) {
    const error = err.message
    yield put(PropertyActions.propertyError(error))
    yield call(onError, err)
  }
}

function* deleteProperty({ propertyId }) {
  try {
    yield call(doDeleteProperty, { propertyId })

    yield put(PropertyActions.onDeletePropertySuccess(propertyId))
    yield put(PropertyActions.loadProperties())
  } catch (err) {
    const error = err.message
    yield put(PropertyActions.propertyError(error))
  }
}

function* patchProperty({ propertyId, propertyData }) {
  try {
    yield call(editProperty, propertyData, { propertyId })

    yield put(PropertyActions.onEditPropertySuccess(propertyId))
  } catch (err) {
    const error = err.message
    yield put(PropertyActions.propertyError(error))
  }
}

function* resetTechnicalCurrentOwner() {
  yield put(DairyFarmActions.resetDairyFarmMonth())
  yield put(PropertyActions.resetCurrentProperty())
}

function* loadParameters({ onSuccess = () => {}, onError = () => {} }) {
  try {
    const currentProperty = yield select(selectCurrentProperty)

    const { data } = yield call(loadPropertyParameters,
      {},
      { propertyId: currentProperty?.id }
    )

    yield put(PropertyActions.updatePropertyParametersSuccess(data))

    yield call(onSuccess, data)
  } catch (err) {
    const error = err.message
    yield put(PropertyActions.propertyError(error))
    yield call(onError, error)
  }
}

function* loadRubricGroups({ onSuccess = () => {}, onError = () => {} }) {
  try {

    const { data } = yield call(getCostsManagementGroups)

    yield put(PropertyActions.loadRubricGroupsSuccess(data))

    yield call(onSuccess, data)
  } catch (err) {
    const error = err.message
    yield put(PropertyActions.propertyError(error))
    yield call(onError, error)
  }
}

function* updatePropertyParameters({ parameters, onSuccess = () => {}, onError = () => {} }) {
  try {
    const { data } = yield call(
      patchPropertyParameters,
      parameters,
      { parametersId: parameters.id }
    )

    yield put(PropertyActions.updatePropertyParametersSuccess(data))
    yield call(onSuccess)
  } catch (err) {
    const error = err.message
    yield put(PropertyActions.propertyError(error))
    yield call(onError, error)
  }
}

function* savePropertiesAssets({ propertiesAssets, onSuccess = () => {}, onError = () => {} }) {
  try {
    if (propertiesAssets.id) {
      yield call(putPropertiesAssets, propertiesAssets)
    } else {
      yield call(postPropertiesAssets, propertiesAssets)
    }

    yield call(onSuccess)
  } catch (err) {
    const error = err.message
    yield put(PropertyActions.propertyError(error))
    yield call(onError, error)
  }
}

function* deletePropertyAsset({ propertyAssetsId, onSuccess = () => {} }) {
  try {
    yield call(deletePropertiesAssets, propertyAssetsId)

    yield call(onSuccess)
  } catch (err) {
    const error = err.message
    yield put(PropertyActions.propertyError(error))
  }
}

function* postPropertyAssetsMovements({ dto, onSuccess = () => {}, onError = () => {} }) {
  try {
    yield call(postPropertyAssetsMovementsService, dto)

    yield call(onSuccess)
  } catch (err) {
    const error = err.message
    yield put(PropertyActions.propertyError(error))
    yield call(onError, error)
  }
}

function* postPropertyAssetsList({ dto, onSuccess = () => {}, onError = () => {} }) {
  try {
    yield call(postPropertyAssetsListService, dto)

    yield call(onSuccess)
  } catch (err) {
    const error = err.message
    yield put(PropertyActions.propertyError(error))
    yield call(onError, error)
  }
}

export default [
  takeLatest(PropertyTypes.LOAD_PROPERTIES, loadProperties),
  takeLatest(PropertyTypes.LOAD_PROPERTIES_SUCCESS, loadPropertiesSuccess),
  takeLatest(PropertyTypes.LOAD_PROPERTY_CHALLENGES, loadPropertyChallenges),
  takeLatest(PropertyTypes.SAVE_OFFLINE_PROPERTY, saveOfflineProperty),
  takeLatest(PropertyTypes.SAVE_PROPERTY, saveProperty),
  takeLatest(PropertyTypes.SAVE_CURRENT_PROPERTY, saveCurrentProperty),
  takeLatest(PropertyTypes.SAVE_RUBRIC, saveRubric),
  takeLatest(PropertyTypes.DELETE_RUBRIC, deleteRubric),
  takeLatest(PropertyTypes.LOAD_ACTIVITIES, loadActivities),
  takeLatest(
    PropertyTypes.LOAD_PROPERTY_PENDING_MANAGEMENTS,
    loadPropertyPendingManagements
  ),
  takeLatest(
    PropertyTypes.LOAD_PROPERTY_DAIRY_FARM_NEXT_ACTIONS,
    loadPropertyDairyFarmNextActions
  ),
  takeLatest(PropertyTypes.DELETE_PROPERTY, deleteProperty),
  takeLatest(PropertyTypes.EDIT_PROPERTY, patchProperty),
  takeLatest(
    TechnicalTypes.RESET_TECHNICAL_CURRENT_OWNER,
    resetTechnicalCurrentOwner
  ),
  takeLatest(PropertyTypes.LOAD_CURRENT_PROPERTY, loadCurrentProperty),
  takeLatest(PropertyTypes.LOAD_PARAMETERS, loadParameters),
  takeLatest(PropertyTypes.LOAD_RUBRIC_GROUPS, loadRubricGroups),
  takeLatest(PropertyTypes.UPDATE_PROPERTY_PARAMETERS, updatePropertyParameters),
  takeLatest(PropertyTypes.SAVE_PROPERTIES_ASSETS, savePropertiesAssets),
  takeLatest(PropertyTypes.DELETE_PROPERTY_ASSET, deletePropertyAsset),
  takeLatest(PropertyTypes.POST_PROPERTY_ASSETS_MOVEMENTS, postPropertyAssetsMovements),
  takeLatest(PropertyTypes.POST_PROPERTY_ASSETS_LIST, postPropertyAssetsList)
]
