/* eslint-disable no-param-reassign */
import { Database, Q } from '@nozbe/watermelondb'
import moment from 'moment'

import { profilePictureService } from './profilePictureService'

const CONTACT_FIELDS = [
  'contactId',
  'contactType',
  'profiles',
  'chatNickname',
  'photo',
  'publicKey',
  'company',
  'default',
  'activeUser'
]

export function contactService(database: Database) {
  async function createOrUpdate(contacts = []) {

    await database.write(async () => {
      const promises = contacts.map(contact => async () => {
        const register = await database.get('contact')
          .query(Q.where('contactId', contact.contactId))
          .fetch()

        if (register[0]) {
          await register[0].update(item => {
            CONTACT_FIELDS.forEach(field => { item[field] = contact[field] })
            item.createdAt = Number(moment(contact.createdAt).format('X'))

            if (contact.contactType !== 'added') {
              item.userCode = contact.userCode
            }
          })
          return
        }

        await database.get('contact').create(item => {
          CONTACT_FIELDS.forEach(field => { item[field] = contact[field] })
          item.userCode = contact.userCode
          item.createdAt = Number(moment(contact.createdAt).format('X'))
        })
      })

      await Promise.all(promises.map(fn => fn()))
    })

    if (contacts) {
      // Run in background
      const users = contacts.map(item => ({ userId: item?.contactId, photo: item?.photo }))
      await profilePictureService(database).createOrUpdate(users)
    }
  }

  async function deleteContactByCode(userCode) {
    database.write(async () => {
      const registers = await database.get('contact')
        .query(Q.where('userCode', userCode))
        .fetch()

      if (registers[0]) {
        await registers[0].markAsDeleted()
      }

    })
  }

  async function findByContactId(contactId) {
    return database
      .get('contact')
      .query(
        Q.where('contactId', contactId)
      )
      .fetch()
  }

  async function updateCodeByContactId(contactId, userCode) {
    await database.write(async () => {
      const registers = await database.get('contact')
        .query(
          Q.where('contactId', contactId)
        )
        .fetch()

      if (registers[0]) {
        await registers[0].update(item => {
          item.invalidCode = false
          item.userCode = userCode
        })
      }
    })
  }

  function observeContacts(contactId) {
    const contact = database.collections.get('contact')

    if (contactId) {
      return contact
        .query(
          Q.where('contactId', Q.notEq(contactId))
        )
        .observe()
    }

    return contact.query(
      Q.where('activeUser', Q.eq(true))
    ).observe()
  }

  function observeContactsByFilter(searchParams = '') {
    const contact = database.collections.get('contact')

    return contact.query(
      Q.where('chatNickname', Q.like(`%${ Q.sanitizeLikeString(searchParams) }%`)),
      Q.where('activeUser', Q.eq(true))
    ).observe()
  }

  return {
    createOrUpdate,
    findByContactId,
    observeContacts,
    deleteContactByCode,
    updateCodeByContactId,
    observeContactsByFilter
  }
}
