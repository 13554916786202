import styled from 'styled-components'

import Tab from '@material-ui/core/Tab'
import Tabs from '@material-ui/core/Tabs'

import colors from '@smartcoop/styles/colors'

export const Container = styled.div`
  justify-content: center;
  align-items: center;
  flex: 1 1 auto;
  flex-direction: column;
  width: 100%;
`
export const FieldInfoText = styled.p`
  color: #721c24;
  background-color: #f8d7da;
  border: 1px solid #f5c6cb;
  padding: 10px;
  border-radius: 10px;
  font-size: 10;
  width: 100%;
`

export const ButtonContainer = styled.div`
  flex: 1 1 auto;
  justify-content: center;
  align-items: flex-end;
  flex-direction: column;
  width: 100%;
`

export const CheckboxContainer = styled.div`
  flex: 1 1 auto;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  width: 100%;
`

export const TextDate = styled.div`
  font-weight: 600;
  font-size: 14px;
  padding-bottom: 15px;
`

export const ContentRadioGroup = styled.div`
  margin-top: 15px;
`

export const Gallery = styled.div`
  display: flex;
  justify-content: ${ ({ isCenter }) => isCenter ? 'center' : 'flex-start' };
  align-items: center;
  flex-wrap: wrap;
  margin-top: ${ ({ isCenter }) => isCenter ? '0' : '20px' };
`

export const TabsGroup = styled(Tabs).attrs({
  variant: 'fullWidth',
  indicatorColor: 'primary'
})`
  color: ${ colors.black };
`

export const TabItem = styled(Tab)`
  min-width: 0;
  min-height: 0;
  pointer-events: inherit !important;
  height: 100%;
`

export const ButtonIntegrate = styled.div`
  margin-left: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 4px;
  padding-left: 10px;
  padding-right: 10px;
  border-radius: 8px;
  cursor: pointer;
  background-color: ${ props => props?.isIntegrated ? 'rgba(40,159,48, 0.1)' : 'lightgray' };

  :hover {
    background-color: ${ props => props?.isIntegrated ? 'rgba(40,159,48, 0.1)' : 'grey' };
    z-index: 1000;
  }
`
export const TextBold = styled.span`
  margin: 0;
  font-weight: 600;
`

export const TextInfo = styled.div`
  font-size: 12px;
`

export const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
  td {
    padding: 5px;
  }
  tr:nth-child(odd) {
    background-color: rgba(0, 0, 0, .04);
  }
`
