import moment from 'moment/moment'

import filter from 'lodash/filter'
import get from 'lodash/get'
import isArray from 'lodash/isArray'
import map from 'lodash/map'
import size from 'lodash/size'

import { selectModuleIsTechnical } from '@smartcoop/stores/module/selectorModule'
import { selectPropertiesByOwner } from '@smartcoop/stores/technical/selectorTechnical'
import { momentBackDateTimeFormat } from '@smartcoop/utils/dates'

export const selectProperties = state => {
  const moduleIsTechnical = selectModuleIsTechnical(state)

  if (moduleIsTechnical) {
    return selectPropertiesByOwner(state)
  }

  return state.property?.properties ?? []
}

export const selectFamilyGroupProperties = state => state.property?.familyGroupProperties ?? []

export const selectCurrentProperty = state => state.property?.currentProperty

export const selectCurrentPropertyOwnerId = state => state.property?.currentProperty?.userProperty?.userId

export const selectFamilyGroupAccess = state => size(state.property?.currentProperty?.userFamilyGroupProperty) > 0

export const selectFamilyGroupAccessCanWrite = state => state.property?.currentProperty?.userFamilyGroupProperty?.[0]?.write

export const selectFamilyGroupFinancialDataAccess = state => state.property?.currentProperty?.userFamilyGroupProperty?.[0]?.financialDataAccess

export const selectCurrentPropertyId = state => state.property?.currentProperty?.id

export const selectPropertyError = state => state.property?.error

export const selectCurrentPropertyDairyFarmIEs = state => {
  if(isArray(state.property?.currentProperty?.dairyFarm)) {
    return size(state.property?.currentProperty?.dairyFarm) > 0
      ? map(state.property?.currentProperty?.dairyFarm[0]?.dairyFarmStateRegistration, ({ userStateRegistration }) => userStateRegistration)
      : []
  }
  return map(state.property?.currentProperty?.dairyFarm?.dairyFarmStateRegistration, ({ userStateRegistration }) => userStateRegistration)
}


export const selectCurrentPropertyDairyFarm = state => isArray(state.property?.currentProperty?.dairyFarm) ? get(state.property?.currentProperty, 'dairyFarm[0]') : state.property?.currentProperty?.dairyFarm

export const selectOfflineProperty = state => state.property?.offlineProperty

export const selectReloadData = state => state.property?.reloadData

export const selectActivities = state => state.property?.activities

export const selectSuggestPropertyRegister = state => {
  const moduleIsTechnical = selectModuleIsTechnical(state)

  const hasFamilyGroupProperties = size(selectFamilyGroupProperties(state)) > 0

  return !moduleIsTechnical && !hasFamilyGroupProperties && state.property?.suggestPropertyRegister
}

export const selectPropertyPendingManagements = state => state.property?.propertyPendingManagements
export const selectPropertyDairyFarmNextActions = state => state.property?.propertyDairyFarmNextActions

export const selectPropertyParameters = state => state.property?.propertyParameters

export const selectRubricGroups = state => state.property?.rubricGroups

export const selectPropertyChallenges = state => filter(state.property?.propertyChallenges, item => moment(item.accreditationDate, momentBackDateTimeFormat).add(1, 'year').isAfter(moment()))

export const selectPropertyAcceptedTermChallenges = state => {
  const challenges = selectPropertyChallenges(state)

  return filter(challenges, item => item?.acceptedTermDate && !item.fieldId)
}

export const selectPropertyNotAcceptedTermChallenges = state => {
  const challenges = selectPropertyChallenges(state)

  return filter(challenges, item => !item?.acceptedTermDate && !item.fieldId)
}
