import { REACT_APP_FAKE_PAGINATION_SIZE } from 'react-native-dotenv'

import { select, call, put, takeLatest } from 'redux-saga/effects'

import { v4 as uuidv4 } from 'uuid'

import filter from 'lodash/filter'
import find from 'lodash/find'
import replace from 'lodash/replace'
import toString from 'lodash/toString'

import {
  getAnimals as getAnimalsService,
  getAnimal as getAnimalService,
  createAnimal as createAnimalService,
  importAnimals as importAnimalsService,
  editAnimal as editAnimalService,
  deleteAnimal as deleteAnimalService,
  getUnavailableEarrings,
  createAnimalImage
} from '@smartcoop/services/apis/smartcoopApi/resources/animal'
import { getAnimalBreeds as getAnimalBreedsService } from '@smartcoop/services/apis/smartcoopApi/resources/animalBreed'
import {
  createAnimalPev as createAnimalPevService,
  editAnimalPev as editAnimalPevService,
  deleteAnimalPev as deleteAnimalPevService,
  getAnimalPevList,
  editMilkControl,
  getMilkControl,
  deleteBull as deleteBullService,
  createBull as createBullService,
  editBull as editBullService
} from '@smartcoop/services/apis/smartcoopApi/resources/herdsManagement'
import { getAllInseminationTypes, getBulls } from '@smartcoop/services/apis/smartcoopApi/resources/insemination'
import { getLots as getLotsService } from '@smartcoop/services/apis/smartcoopApi/resources/lot'
import { createAnimalObservations, deleteAnimalObservations, getAnimalObservations, updateAnimalObservations } from '@smartcoop/services/apis/smartcoopApi/resources/observation'
import { OfflineAnimalUserDataActions } from '@smartcoop/stores/offlineData/userData/offlineAnimalUserData/duckOfflineAnimalUserData'
import { selectOfflineAnimalStatus, selectOfflineLots, selectOfflineMilkControls } from '@smartcoop/stores/offlineData/userData/offlineAnimalUserData/selectorOfflineAnimalUserData'
import { OfflineTechnicalUserDataActions } from '@smartcoop/stores/offlineData/userData/offlineTechnicalUserData/duckOfflineTechnicalUserData'
import { selectTechnicalPropertyLots } from '@smartcoop/stores/offlineData/userData/offlineTechnicalUserData/selectorOfflineTechnicalUserData'
import { selectCurrentProperty, selectCurrentPropertyId } from '@smartcoop/stores/property/selectorProperty'

import { getAnimalStatus } from '../../services/apis/smartcoopApi/resources/animalStatus'
import { selectModuleIsTechnical } from '../module/selectorModule'
import { selectIsConnected } from '../network/selectorNetwork'
import { AnimalActions, AnimalTypes } from './duckAnimal'
import { selectCurrentAnimal } from './selectorAnimal'

function* loadAnimals({ params = {}, onSuccess = () => {}, onError = () => {} }) {
  try {
    const currentProperty = yield select(selectCurrentProperty)
    const propertyId = currentProperty?.id || params?.propertyId

    if(propertyId){
      const { data: { data, ...pagination } } = yield call(
        getAnimalsService,
        {
          limit: process.env.REACT_APP_FAKE_PAGINATION_SIZE || REACT_APP_FAKE_PAGINATION_SIZE,
          ...params
        },
        { propertyId }
      )
      yield put(AnimalActions.loadAnimalsSuccess(
        data,
        pagination.page
      ))
      yield call(onSuccess, data)
    }
  } catch (err) {
    const error = err.message
    yield put(AnimalActions.animalError(error))
    yield call(onError, error)
  }
}

function* loadPropertyBulls() {
  try {
    const currentProperty = yield select(selectCurrentProperty)
    const propertyId = currentProperty?.id

    const { data: { data } } = yield call(
      getAnimalsService,
      {
        limit: process.env.REACT_APP_FAKE_PAGINATION_SIZE || REACT_APP_FAKE_PAGINATION_SIZE
      },
      { propertyId }
    )

    yield put(AnimalActions.loadPropertyBullsSuccess(
      filter(data, item => item.category === 'touro')
    ))
  } catch (err) {
    const error = err.message
    yield put(AnimalActions.animalError(error))
  }
}

function* saveAnimal({ params, onSuccess = () => {}, onError = () => {}, isEdit = false }) {
  try {
    const { data } = yield call(
      !isEdit ? createAnimalService : editAnimalService,
      params,
      {
        animalId: isEdit ? params?.id : null,
        propertyId: params?.propertyId
      }
    )
    if (params?.animalImage) {
      yield call(createAnimalImage, params?.animalImage, { animalId: data.id })
    }
    yield call(onSuccess, data)
  } catch (err) {
    const error = err.message
    yield put(AnimalActions.animalError(error))
    yield call(onError, error)
  }
}

function* deleteBull({ bullId, onSuccess = () => {}, onError = () => {} }) {
  try {
    yield call(deleteBullService, { bullId })

    yield call(onSuccess)
  } catch (err) {
    const error = err.message
    yield put(AnimalActions.animalError(error))
    yield call(onError, error)
  }
}

function* saveMilkControl({ params, onSuccess = () => {}, onError = () => {} }) {
  try {
    const { data } = yield call(
      editMilkControl,
      params
    )
    yield call(onSuccess, data)
  } catch (err) {
    const error = err.message
    yield put(AnimalActions.animalError(error))
    yield call(onError, error)
  }
}

function* saveOfflineMilkControl({ params, onSuccess = () => {}, onError = () => {} }) {
  try {
    const isConnected = yield select(selectIsConnected)
    const currentProperty = yield select(selectCurrentProperty)
    const isTechnical = yield select(selectModuleIsTechnical)
    const milkControlList = yield select(selectOfflineMilkControls)

    if(params?.data?.[0]?.deleted) {
      if(isTechnical) {
        yield put(OfflineTechnicalUserDataActions.deleteOfflineTechnicalMilkControl(params?.data?.[0]?.id, currentProperty?.id))
      } else {
        yield put(OfflineAnimalUserDataActions.removeOfflineMilkControl(params?.data?.[0]?.id))
      }
    } else if(isTechnical) {
      yield put(OfflineTechnicalUserDataActions.updateOfflineTechnicalMilkControl(params?.data?.[0], currentProperty?.id))
    } else {
      const listWithoutCurrent = filter([...milkControlList], item => item.id !== params?.data?.[0]?.id)
      yield put(OfflineAnimalUserDataActions.loadAllMilkControlSuccess([params?.data?.[0], ...listWithoutCurrent]))
    }

    if(!isConnected) {
      yield call(onSuccess, params?.data?.[0])
    }

    yield put(AnimalActions.saveMilkControl(params, isConnected ? onSuccess : () => {}, onError))


  } catch (err) {
    const error = err.message
    yield put(AnimalActions.animalError(error))
    yield call(onError, error)
  }
}

function* importAnimals({ params, onSuccess = () => {}, onError = () => {}, t }) {
  try {
    const currentProperty = yield select(selectCurrentProperty)

    yield call(
      importAnimalsService,
      params,
      {
        propertyId: currentProperty?.id
      }
    )
    yield call(onSuccess)
  } catch (err) {
    let error = err.response.data.message
    error = replace(error, 'message: Insemination is not allowed for this category of animals.', t('message: Insemination is not allowed for this category of animals.'))
    error = replace(error, 'row:', t('row:'))
    yield put(AnimalActions.animalError(error))
    yield call(onError, error)
  }
}

function* loadUnavailableEarrings({ params }) {
  try {
    const { data: { data } } = yield call(getUnavailableEarrings,  params)

    yield put(AnimalActions.loadUnavailableEarringsSuccess(data))

  } catch (err) {
    const error = err.message
    yield put(AnimalActions.animalError(error))
  }
}

function* saveOfflineAnimal({ params, onSuccess = () => {}, onError = () => {}, web = false }) {
  try {
    const status = web ? null : yield select(selectOfflineAnimalStatus)
    const lots = web ? null :  yield select(selectOfflineLots)
    const isTechnical = yield select(selectModuleIsTechnical)
    const currentProperty = yield select(selectCurrentProperty)
    const offlineTechnicalLots = web ? null : yield select(selectTechnicalPropertyLots)

    if(web) {
      yield put(AnimalActions.saveAnimal(params, onSuccess, onError, !!params?.id))
    } else {
      const newAnimal = {
        ...params,
        earring: {
          ...params?.earring,
          earringCode: params.earringCode
        },
        animalStatus: find(status, item => toString(item.id) === toString(params?.statusId)),
        lot: find(isTechnical ? offlineTechnicalLots : lots, item => item.id === params?.lotId),
        id: params?.id || uuidv4(),
        propertyId: currentProperty?.id
      }

      if (isTechnical) {
        yield put(OfflineTechnicalUserDataActions.updateOfflineAnimal(newAnimal))
      } else {
        yield put(OfflineAnimalUserDataActions.updateOfflineAnimal(newAnimal))
      }

      yield put(AnimalActions.saveAnimal(newAnimal, onSuccess, onError, !!params?.id))
    }
  } catch (err) {
    const error = err.message
    yield put(AnimalActions.animalError(error))
    yield call(onError, error)
  }
}

function* deleteAnimal({ animalId, propertyId, onSuccess = () => {}, onError = () => {} }) {
  try {
    yield call(deleteAnimalService, { propertyId, animalId })
    yield call(onSuccess)
  } catch (err) {
    const error = err.message
    yield put(AnimalActions.animalError(error))
    yield call(onError, error)
  }
}

function* deleteOfflineAnimal({ animalId, onSuccess = () => {}, onError = () => {} }) {
  try {
    const currentProperty = yield select(selectCurrentProperty)

    yield put(OfflineAnimalUserDataActions.removeOfflineAnimal(animalId))

    yield put(AnimalActions.deleteAnimal(animalId, currentProperty?.id, onSuccess, onError))
  } catch (err) {
    const error = err.message
    yield put(AnimalActions.animalError(error))
    yield call(onError, error)
  }
}
function* loadAnimalStatus({ onSuccess = () => {} }) {
  try {
    const { data: { data } } = yield call(getAnimalStatus)
    yield call(onSuccess, data)
  } catch (err) {
    const error = err.message
    yield put(AnimalActions.animalError(error))
  }
}
function* loadAnimalLots({ onSuccess = () => {} }) {
  try {
    const currentProperty = yield select(selectCurrentProperty)
    const propertyId = currentProperty?.id

    const { data: { data } } = yield call(getLotsService, {}, { propertyId })
    yield call(onSuccess, data)
  } catch (err) {
    const error = err.message
    yield put(AnimalActions.animalError(error))
  }
}
function* loadAnimalBreeds({ onSuccess = () => {} }) {
  try {
    const { data: { data } } = yield call(getAnimalBreedsService)
    yield call(onSuccess, data)
  } catch (err) {
    const error = err.message
    yield put(AnimalActions.animalError(error))
  }
}

function* loadAnimalBulls({ onSuccess = () => {} }) {
  try {
    const { data: { data } } = yield call(getBulls, { limit: 99999 })
    yield call(onSuccess, data)
  } catch (err) {
    const error = err.message
    yield put(AnimalActions.animalError(error))
  }
}

function* loadAnimalInseminationTypes({ onSuccess = () => {} }) {
  try {
    const { data: { data } } = yield call(getAllInseminationTypes)
    yield call(onSuccess, data)
  } catch (err) {
    const error = err.message
    yield put(AnimalActions.animalError(error))
  }
}

function* loadMilkControlList({ params = {}, onSuccess= () => {}, onError = () => {} }) {
  try {
    const propertyId = yield select(selectCurrentPropertyId)
    const { data: { data } } = yield call(getMilkControl, params, { propertyId: params?.propertyId || propertyId })
    yield put(AnimalActions.loadMilkControlListSuccess(data))
    yield call(onSuccess, data)
  } catch (err) {
    const error = err.message
    yield put(AnimalActions.animalError(error))
    yield call(onError, error)
  }
}

function* loadAnimalPevList({ params = {}, onSuccess= () => {}, onError = () => {} }) {
  try {
    const currentAnimal = yield select(selectCurrentAnimal)

    const { data: { data, ...pagination } } = yield call(
      getAnimalPevList,
      {
        limit: process.env.REACT_APP_FAKE_PAGINATION_SIZE || REACT_APP_FAKE_PAGINATION_SIZE,
        ...params
      },
      { animalId: currentAnimal.id }
    )

    yield put(AnimalActions.loadAnimalPevListSuccess(
      data,
      pagination.page,
      () => onSuccess(data)
    ))
  } catch (err) {
    const error = err.message
    yield put(AnimalActions.animalError(error))
    yield call(onError, error)
  }
}


function* loadAnimalPevListSuccess({ onSuccess = () => {} }) {
  yield call(onSuccess)
}

function* loadAnimalObservation({ params = {}, onSuccess= () => {}, onError = () => {} }) {
  try {
    const currentAnimal = yield select(selectCurrentAnimal)

    const { data: { data, ...pagination } } = yield call(
      getAnimalObservations,
      {
        limit: process.env.REACT_APP_FAKE_PAGINATION_SIZE || REACT_APP_FAKE_PAGINATION_SIZE,
        ...params
      },
      { animalId: currentAnimal.id }
    )

    yield put(AnimalActions.loadAnimalObservationSuccess(
      data,
      pagination.page
    ))

    yield call(onSuccess)
  } catch (err) {
    const error = err.message
    yield put(AnimalActions.animalError(error))
    yield call(onError, error)
  }
}

function* loadCurrentAnimal({ onSuccess = () => {}, onError = () => {} }) {
  try {
    const { id: propertyId } = yield select(selectCurrentProperty)
    const { id: animalId } = yield select(selectCurrentAnimal)

    const { data } = yield call(getAnimalService, {}, { propertyId, animalId })
    yield put(AnimalActions.loadCurrentAnimalSuccess(data))
    yield call(onSuccess, data)
  } catch (err) {
    const error = err.message
    yield put(AnimalActions.animalError(error))
    yield call(onError, error)
  }
}


function* saveAnimalPev({ pev, onSuccess = () => {}, onError = () => {}, isEdit = false }) {
  try {
    const { data } = yield call(
      !isEdit ? createAnimalPevService : editAnimalPevService,
      pev,
      {
        propertyId: pev?.propertyId,
        animalId: pev?.animalId ?? null,
        pevId: pev?.id ?? null
      }
    )
    yield call(onSuccess, data)
  } catch (err) {
    const error = err.message
    yield put(AnimalActions.animalError(error))
    yield call(onError, error)
  }
}

function* saveOfflineAnimalPev({ pev, onSuccess = () => {}, onError = () => {}, web = false }) {
  try {
    const currentAnimal = yield select(selectCurrentAnimal)
    const currentProperty = yield select(selectCurrentProperty)
    const isTechnical = yield select(selectModuleIsTechnical)
    const status = web ? null : yield select(selectOfflineAnimalStatus)
    const isConnected = yield select(selectIsConnected)

    const newPev = {
      ...pev,
      id: pev?.id || uuidv4(),
      animalId: currentAnimal?.id,
      propertyId: currentProperty?.id
    }

    if(!web) {
      const animal = {
        ...currentAnimal,
        voluntaryWaitingPeriod: [
          ...filter(currentAnimal?.voluntaryWaitingPeriod, item => item.id !== pev.id),
          newPev
        ],
        animalStatus: pev?.able ? find(status, item => item.id === 7) : find(status, item => item.id === 5),
        statusId: pev?.able ? find(status, item => item.id === 7).id : find(status, item => item.id === 5).id,
        alreadyPev: !isConnected ? true : null
      }

      if (isTechnical) {
        yield put(OfflineTechnicalUserDataActions.updateOfflineAnimal(animal))
      } else {
        yield put(OfflineAnimalUserDataActions.updateOfflineAnimal(animal))
      }
    }

    yield put(AnimalActions.saveAnimalPev(newPev, onSuccess, onError, pev?.id))
  } catch (err) {
    const error = err.message
    yield put(AnimalActions.animalError(error))
    yield call(onError, error)
  }
}

function* deleteAnimalPev({ pevId, onSuccess = () => {}, onError = () => {} }) {
  try {
    yield call(deleteAnimalPevService, { pevId })
    yield call(onSuccess)
  } catch (err) {
    const error = err.message
    yield put(AnimalActions.animalError(error))
    yield call(onError, error)
  }
}

function* deleteOfflineAnimalPev({ pevId, onSuccess = () => {}, onError = () => {} }) {
  try {
    yield put(AnimalActions.deleteAnimalPev(pevId, onSuccess, onError))
  } catch (err) {
    const error = err.message
    yield put(AnimalActions.animalError(error))
    yield call(onError, error)
  }
}

function* saveAnimalObservation({ params, onSuccess = () => {}, onError = () => {}, isEdit = false }) {
  try {
    const { data } = yield call(
      !isEdit ? createAnimalObservations : updateAnimalObservations,
      params,
      {
        observationId: params?.id ?? null
      }
    )
    yield call(onSuccess, data)
  } catch (err) {
    const error = err.message
    yield put(AnimalActions.animalError(error))
    yield call(onError, error)
  }
}

function* saveBull({ params, onSuccess = () => {}, onError = () => {} }) {
  try {
    yield call(
      params?.id ? editBullService : createBullService,
      params,
      { bullId: params?.id }
    )
    yield call(onSuccess)
  } catch (err) {
    const error = err.message
    yield put(AnimalActions.animalError(error))
    yield call(onError, error)
  }
}

function* saveOfflineAnimalObservation({ params, onSuccess = () => {}, onError = () => {}, web = false }) {
  try {
    const currentAnimal = yield select(selectCurrentAnimal)
    const currentProperty = yield select(selectCurrentProperty)
    const isTechnical = yield select(selectModuleIsTechnical)

    const newObservation = {
      ...params,
      id: params?.id || uuidv4(),
      animalId: currentAnimal?.id,
      propertyId: currentProperty?.id
    }

    if(!web) {
      const animal = {
        ...currentAnimal,
        observations: [
          ...filter(currentAnimal?.observations, item => item.id !== params.id),
          newObservation
        ]
      }

      if (isTechnical) {
        yield put(OfflineTechnicalUserDataActions.updateOfflineAnimal(animal))
      } else {
        yield put(OfflineAnimalUserDataActions.updateOfflineAnimal(animal))
      }
    }

    yield put(AnimalActions.saveAnimalObservation(newObservation, onSuccess, onError, params?.id))
  } catch (err) {
    const error = err.message
    yield put(AnimalActions.animalError(error))
    yield call(onError, error)
  }
}

function* deleteAnimalObservation({ observationId, onSuccess = () => {}, onError = () => {} }) {
  try {
    yield call(deleteAnimalObservations, { observationId })
    yield call(onSuccess)
  } catch (err) {
    const error = err.message
    yield put(AnimalActions.animalError(error))
    yield call(onError, error)
  }
}

function* deleteOfflineAnimalObservation({ observationId, onSuccess = () => {}, onError = () => {} }) {
  try {
    yield put(AnimalActions.deleteAnimalObservation(observationId, onSuccess, onError))
  } catch (err) {
    const error = err.message
    yield put(AnimalActions.animalError(error))
    yield call(onError, error)
  }
}

function* resetCurrentAnimal() {
  yield put(AnimalActions.resetAnimalPevList())
  yield put(AnimalActions.resetAnimalObservation())
}

export default [
  takeLatest(AnimalTypes.LOAD_ANIMALS, loadAnimals),

  takeLatest(AnimalTypes.LOAD_CURRENT_ANIMAL, loadCurrentAnimal),

  takeLatest(AnimalTypes.SAVE_ANIMAL, saveAnimal),
  takeLatest(AnimalTypes.SAVE_OFFLINE_ANIMAL, saveOfflineAnimal),
  takeLatest(AnimalTypes.SAVE_MILK_CONTROL, saveMilkControl),
  takeLatest(AnimalTypes.SAVE_OFFLINE_MILK_CONTROL, saveOfflineMilkControl),
  takeLatest(AnimalTypes.IMPORT_ANIMALS, importAnimals),
  takeLatest(AnimalTypes.DELETE_ANIMAL, deleteAnimal),
  takeLatest(AnimalTypes.DELETE_OFFLINE_ANIMAL, deleteOfflineAnimal),

  takeLatest(AnimalTypes.LOAD_MILK_CONTROL_LIST, loadMilkControlList),
  takeLatest(AnimalTypes.LOAD_ANIMAL_STATUS, loadAnimalStatus),
  takeLatest(AnimalTypes.LOAD_ANIMAL_LOTS, loadAnimalLots),
  takeLatest(AnimalTypes.LOAD_ANIMAL_BREEDS, loadAnimalBreeds),
  takeLatest(AnimalTypes.LOAD_ANIMAL_BULLS, loadAnimalBulls),
  takeLatest(AnimalTypes.LOAD_ANIMAL_INSEMINATION_TYPES, loadAnimalInseminationTypes),
  takeLatest(AnimalTypes.LOAD_ANIMAL_PEV_LIST, loadAnimalPevList),
  takeLatest(AnimalTypes.LOAD_ANIMAL_PEV_LIST_SUCCESS, loadAnimalPevListSuccess),
  takeLatest(AnimalTypes.LOAD_UNAVAILABLE_EARRINGS, loadUnavailableEarrings),
  takeLatest(AnimalTypes.LOAD_PROPERTY_BULLS, loadPropertyBulls),
  takeLatest(AnimalTypes.SAVE_ANIMAL_PEV, saveAnimalPev),
  takeLatest(AnimalTypes.SAVE_OFFLINE_ANIMAL_PEV, saveOfflineAnimalPev),
  takeLatest(AnimalTypes.DELETE_ANIMAL_PEV, deleteAnimalPev),
  takeLatest(AnimalTypes.DELETE_OFFLINE_ANIMAL_PEV, deleteOfflineAnimalPev),
  takeLatest(AnimalTypes.LOAD_ANIMAL_OBSERVATION, loadAnimalObservation),

  takeLatest(AnimalTypes.SAVE_ANIMAL_OBSERVATION, saveAnimalObservation),
  takeLatest(AnimalTypes.SAVE_OFFLINE_ANIMAL_OBSERVATION, saveOfflineAnimalObservation),
  takeLatest(AnimalTypes.DELETE_OFFLINE_ANIMAL_OBSERVATION, deleteOfflineAnimalObservation),
  takeLatest(AnimalTypes.DELETE_ANIMAL_OBSERVATION, deleteAnimalObservation),
  takeLatest(AnimalTypes.DELETE_BULL, deleteBull),
  takeLatest(AnimalTypes.SAVE_BULL, saveBull),

  takeLatest(AnimalTypes.RESET_CURRENT_ANIMAL, resetCurrentAnimal)
]
