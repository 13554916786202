import React, {
  useState,
  useEffect,
  useCallback,
  forwardRef
} from 'react'
import { useSelector, useDispatch } from 'react-redux'

import moment from 'moment'
import PropTypes from 'prop-types'

import filter from 'lodash/filter'
import map from 'lodash/map'

import Grid from '@material-ui/core/Grid'

import registerProtocolSchema from '@smartcoop/forms/schemas/dairyFarm/registerProtocol.schema'
import I18n, { useT } from '@smartcoop/i18n'
import { getAnimals as getAnimalsService } from '@smartcoop/services/apis/smartcoopApi/resources/animal'
import { selectCurrentProperty } from '@smartcoop/stores/property/selectorProperty'
import { colors } from '@smartcoop/styles'
import { AnimalStatusCode, PROTOCOL_TYPES } from '@smartcoop/utils/constants'
import Button from '@smartcoop/web-components/Button'
import Form from '@smartcoop/web-components/Form'
import InputDate from '@smartcoop/web-components/InputDate'
import InputNumber from '@smartcoop/web-components/InputNumber'
import InputSelect from '@smartcoop/web-components/InputSelect'

import {
  Container,
  ButtonsContainer,
  FormContainer,
  Item
} from './styles'

const RegisterProtocolForm = forwardRef((props, formRef) => {
  const { defaultValues, onSubmit, onCancel, loading } = props

  const dispatch = useDispatch()
  const [animals, setAnimals] = useState([])
  const currentProperty = useSelector(selectCurrentProperty)

  useEffect(() => {
    const loadAnimals = async() => {
      const { data: { data: response } } = await getAnimalsService({ limit: 99999 }, { propertyId: currentProperty?.id })
      const filteredAnimals = filter(response, item => (item.category === 'vaca' ||
      item.category === 'novilha') &&
      (item.animalStatus.id === AnimalStatusCode.VAZIA ||
        item.animalStatus.id === AnimalStatusCode.APTAS ||
        item.animalStatus.id === AnimalStatusCode.PEV ||
        item.animalStatus.id === AnimalStatusCode.NENHUM))
      setAnimals(map(filteredAnimals, item => ({ label: item?.earring?.earringCode, value: item.id })))
    }

    loadAnimals()
  },[currentProperty.id, dispatch])


  const t = useT()

  const handleSubmit = useCallback(
    (data) => {
      onSubmit({
        ...data
      })
    },
    [onSubmit]
  )

  return (
    <Container>
      <Form
        style={ { display: 'flex', flexDirection: 'column', width: '100%' } }
        ref={ formRef }
        schemaConstructor={ registerProtocolSchema }
        onSubmit={ handleSubmit }
      >
        <FormContainer>
          <Grid container style={ { justifyContent: 'space-between' } }>
            <Item>
              <InputSelect
                label={ t('earrings') }
                name="animalsIds"
                options={ animals }
                multiple
                defaultValue={ [defaultValues?.animal?.id] }
                disabled={ !!defaultValues?.id }
              />
            </Item>
            <Item>
              <InputSelect
                label={ t('type') }
                name="type"
                fullWidth
                options={ PROTOCOL_TYPES }
                defaultValue={ defaultValues?.type }
              />
            </Item>
            <Item>
              <InputDate
                label={ t('protocol date') }
                name="protocolDate"
                fullWidth
                defaultValue={ defaultValues.protocolDate }
                pickerProps={ {
                  maxDate: moment().format()
                } }
              />
            </Item>
            <Item>
              <InputNumber
                name="numberOfDays"
                label={ t('Days') }
                maxLength={ 2 }
                fullWidth
                defaultValue={ defaultValues?.numberOfDays }
              />
            </Item>
          </Grid>
        </FormContainer>
        <ButtonsContainer>
          <Button
            id="web-cancel-form-button"
            onClick={ onCancel }
            color={ colors.white }
            disabled={ loading }
            fullWidth
            style={ { marginLeft: 10, marginRight: 10 } }
          >
            <I18n>cancel</I18n>
          </Button>
          <Button
            id="web-save-form-button"
            onClick={ () => formRef.current.submit() }
            disabled={ loading }
            fullWidth
            style={ { marginLeft: 10, marginRight: 10 } }
          >
            <I18n>save</I18n>
          </Button>
        </ButtonsContainer>
      </Form>
    </Container>
  )
})

RegisterProtocolForm.propTypes = {
  loading: PropTypes.bool,
  onSubmit: PropTypes.func,
  onCancel: PropTypes.func,
  defaultValues: PropTypes.object.isRequired
}

RegisterProtocolForm.defaultProps = {
  loading: false,
  onSubmit: () => {},
  onCancel: () => {}
}

export default RegisterProtocolForm
