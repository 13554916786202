import React, { useMemo, useCallback, useRef, useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'

import moment from 'moment'
import PropTypes from 'prop-types'

import filter from 'lodash/filter'
import find from 'lodash/find'
import findIndex from 'lodash/findIndex'
import includes from 'lodash/includes'
import isEmpty from 'lodash/isEmpty'
import last from 'lodash/last'
import map from 'lodash/map'
import reverse from 'lodash/reverse'
import size from 'lodash/size'
import sortBy from 'lodash/sortBy'
import split from 'lodash/split'
import uniqBy from 'lodash/uniqBy'

import { ThemeProvider } from '@material-ui/core/styles'

import I18n, { useT } from '@smartcoop/i18n'
import { getBucketFile } from '@smartcoop/services/apis/smartcoopApi/resources/bucket'
import { useSnackbar } from '@smartcoop/snackbar'
import { SateliteSvcActions } from '@smartcoop/stores/sateliteSvc'
import { selectIndicators, selectIndicatorsLoaded } from '@smartcoop/stores/sateliteSvc/selectorSateliteSvc'
import { colors } from '@smartcoop/styles'
import * as colorPalette from '@smartcoop/utils/colorPalette'
import { momentBackDateFormat } from '@smartcoop/utils/dates'
import { downloadFromBase64 } from '@smartcoop/utils/files'
import { formatNumber } from '@smartcoop/utils/formatters'
import CalendarPicker from '@smartcoop/web-components/CalendarPicker'
import ColorPalette from '@smartcoop/web-components/ColorPalette'
import Form from '@smartcoop/web-components/Form'
import CalendarButton from '@smartcoop/web-components/IconButton/CalendarButton'
import DownloadIconButton from '@smartcoop/web-components/IconButton/DownloadIconButton'
import InputSelect from '@smartcoop/web-components/InputSelect'
import Loader from '@smartcoop/web-components/Loader'
import Popover from '@smartcoop/web-components/Popover'

import DateTabs from './DateTabs'
import {
  Container,
  FormContainer,
  FormItem,
  InputGroup,
  DownloadIconContainer,
  LoaderContainer
} from './styles'
import { handleTiffDownload } from './utils'

const FieldIndexesNavigator = ({
  field,
  onChangeImage,
  fieldHistory,
  isFromHistory
}) => {
  const t = useT()
  const formRef = useRef()
  const dispatch = useCallback(useDispatch(), [])
  const snackbar = useSnackbar()

  const [activeTab, setActiveTab] = useState()
  const [activeDate, setActiveDate] = useState()
  const [gettingImage, setGettingImage] = useState(false)
  const [indicatorImage, setIndicatorImage] = useState(false)
  const [period, setPeriod] = useState(
    isFromHistory
      ? {
        from: moment(fieldHistory.startDate),
        to: moment(fieldHistory.endDate)
      }
      : {
        from: moment().subtract(1, 'months').format(momentBackDateFormat),
        to: moment().format(momentBackDateFormat)
      })
  const [indicator, setIndicator] = useState()
  const [timeCounter, setTimeCounter] = useState(39)
  const [intervalsControl, setIntervalsControl] = useState([])

  const indicators = useSelector(selectIndicators)
  const indicatorsLoaded = useSelector(selectIndicatorsLoaded)
  const { detached } = useState(true)

  const maxCloudsPercent = useMemo(() => 50, [])

  const indicatorOptions = [
    { label: t('base map'), value: 'trueColor' },
    { label: t('monitoring'), value: 'STDEV' },
    { label: 'NDRE', value: 'NDRE' },
    { label: 'NDVI', value: 'NDVI' }
  ]

  const [calendarValue, setCalendarValue] = useState(period)

  const inputRef = useRef()

  const dates = useMemo(
    () => filter(detached ? period : calendarValue, (date) => !isEmpty(date)),
    [calendarValue, detached, period]
  )

  const renderFile = useCallback(async (file) => {
    const fileUrl = split(file, '/')
    const { data: { file: data } } = await getBucketFile({ bucketId: process.env.REACT_APP_BUCKET_SATELITE_IMAGES_ID, fileKey: last(fileUrl) })

    return downloadFromBase64(data)
  }, [])

  const indicatorsDates = useMemo(
    () => {
      if (isEmpty(indicators)) return []

      let formattedIndicators = indicators
        .filter(item => isFromHistory ? item.fieldId === fieldHistory.fieldId : item.fieldId === field.id)
        .map(item => ({
          ...item,
          date: item.date,
          isImageTooCloudy: !item.url || item.cloudPercent > maxCloudsPercent,
          cloudPercent: item.cloudPercent
        }))

      formattedIndicators = sortBy(formattedIndicators, item => new Date(item.date))


      return uniqBy(formattedIndicators, item => item.date)
    }, [indicators, isFromHistory, fieldHistory.fieldId, field.id, maxCloudsPercent]
  )

  const indicatorData = useMemo(
    () => {
      if (
        activeDate
        && indicator
        && !isEmpty(indicators)
      ) {
        return find(indicators, { date: activeDate, indicator }) || {}
      }
      return {}
    },
    [activeDate, indicator, indicators]
  )

  useEffect(() => {
    const getFromBucket = async() => {
      const image = await renderFile(indicatorData?.url)
      setIndicatorImage(image)
    }

    getFromBucket()
  },[indicatorData, renderFile])

  const handleChangeActiveDate = useCallback(
    (_, newTab) => {
      setActiveDate(indicatorsDates[newTab]?.date)
      setActiveTab(newTab)
    },
    [indicatorsDates]
  )

  useEffect(() => {
    if (period.to) {
      dispatch(SateliteSvcActions.searchIndicatorsByDate({
        period,
        fieldId: isFromHistory ? fieldHistory.fieldId : field.id
      }))
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, field, period.to, fieldHistory])

  useEffect(() => {
    if (!isEmpty(indicatorsDates)) {
      const index = (size(indicatorsDates)-1) - findIndex(reverse([...indicatorsDates]), item => !item.isImageTooCloudy)

      handleChangeActiveDate(undefined, index)
    }
  }, [handleChangeActiveDate, indicatorsDates])

  useEffect(() => {
    onChangeImage(indicatorImage)
  }, [indicatorImage, onChangeImage])

  useEffect(() => () => {
    dispatch(SateliteSvcActions.resetSateliteSvc())
  }, [dispatch])

  const colorsPalette = useMemo(
    () => colorPalette[indicator] || [],
    [indicator]
  )

  const labels = useMemo(
    () => {
      switch(indicator) {
        case 'NDVI':
          return { startLabel: `0,3 ${ t('low') }`, endLabel: `0,9 ${ t('high') }` }
        case 'NDRE':
          return { startLabel: `0,1 ${ t('low') }`, endLabel: `0,9 ${ t('high') }` }
        case 'STDEV':
          return { startLabel: `${ formatNumber((indicatorData?.minStDev || 0).toFixed(2)) } ${ t('low') }`, endLabel: `${ formatNumber((indicatorData?.maxStDev || 0).toFixed(2)) } ${ t('high') }` }
        default:
          return {}
      }
    },
    [indicator, indicatorData, t]
  )

  const downloadTiff = useCallback(() => {
    const payload = {
      indicator,
      date: activeDate,
      fieldId: field.id
    }

    setGettingImage(true)

    const onSuccess = (url) => {
      handleTiffDownload(url)
      setGettingImage(false)
    }

    const onError = () => setGettingImage(false)

    dispatch(SateliteSvcActions.getGeotiffImage(payload, onSuccess, onError))
  }, [indicator, activeDate, field.id, dispatch])

  const handleChangeDates = useCallback(
    (newDates) => {
      if (!detached && inputRef.current) {
        inputRef.current.setValue(newDates)
      }
      const disabledDates = map([1,2,3,4,5], each => moment(newDates?.from).add(each, 'days').format('YYYY-MM-DD'))

      if(includes(disabledDates, newDates?.to)) {
        snackbar.error(t('select a date with a minimum range of 6'))
      } else {
        setPeriod(newDates)
        setCalendarValue(newDates)
      }
    },
    [detached, snackbar, t]
  )
  const handleCalendarChange = useCallback(
    (newDates) => {
      const [from = '', to = ''] = newDates || []

      handleChangeDates({ from, to })

      if (size(newDates) === 2) {
        // eslint-disable-next-line no-unused-expressions
        () => {}
      }
    },
    [handleChangeDates]
  )
  const onCloseCalendar = useCallback(
    (values) => {
      if (size(values) < 2) {
        handleChangeDates({ from: '', to: '' })
      }
    },
    [handleChangeDates]
  )

  const calendarOptions = useMemo(
    () => ({
      mode: 'range',
      onClose: onCloseCalendar
    }),
    [onCloseCalendar]
  )

  useEffect(() => {
    if (indicatorsLoaded && isEmpty(indicatorsDates)) {
      map(intervalsControl, item => clearInterval(item))

      setTimeCounter(39)
      const interval = setInterval(() => {
        if (period.to) {
          dispatch(SateliteSvcActions.searchIndicatorsByDate({
            period,
            fieldId: isFromHistory ? fieldHistory.fieldId : field.id
          }))
        }
        clearInterval(interval)
      }, 40000)
      setIntervalsControl((old) => [...old, interval])

    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[indicatorsDates, indicatorsLoaded])

  useEffect(
    () => {
      const timer = timeCounter > 0 && setInterval(() => setTimeCounter(timeCounter - 1), 1000)
      return () => clearInterval(timer)
    },
    [timeCounter]
  )

  return (
    <>
      <ColorPalette
        colors={ colorsPalette }
        { ...labels }
        style={ {
          padding: 12,
          marginBottom: 2,
          width: 300,
          backgroundColor: colors.shadow,
          borderRadius: 4
        } }
      />

      <Container>
        <div>
          <Form ref={ formRef }>
            <FormContainer>
              <FormItem>
                <InputGroup>
                  <Popover
                    popoverStyle={ { zIndex: 1200 } }
                    anchorOrigin={ {
                      vertical: 'top',
                      horizontal: 'right'
                    } }
                    transformOrigin={ {
                      vertical: 330,
                      horizontal: 'left'
                    } }
                    popoverId="calendar"
                    Component={ CalendarButton }
                    ComponentProps={ {
                      edge: 'end'
                    } }
                  >
                    <div style={ { padding: '5px 5px 10px' } }>
                      <CalendarPicker
                        withoutInput
                        value={ dates }
                        onChange={ handleCalendarChange }
                        options={ calendarOptions }
                        maxDate={ isFromHistory ? moment(fieldHistory.endDate).format(momentBackDateFormat) : moment().format(momentBackDateFormat) }
                        minDate={ isFromHistory ? moment(fieldHistory.startDate).format(momentBackDateFormat) : null }
                      />
                    </div>
                  </Popover>
                </InputGroup>
              </FormItem>
              <FormItem style={ { width: 160, color: colors.white } }>
                <ThemeProvider
                  theme={
                    (theme) => ({
                      ...theme,
                      overrides: {
                        ...theme.overrides,
                        MuiOutlinedInput: {
                          root: {
                            '& *': {
                              color: `${ colors.white } !important`,
                              fill: `${ colors.white } !important`
                            }
                          }
                        },
                        MuiInputLabel: {
                          outlined: {
                            color: `${ colors.white } !important`,
                            fill: `${ colors.white } !important`
                          }
                        }
                      }
                    })
                  }
                >
                  <InputSelect
                    detached
                    label={ t('index', { howMany: 1 }) }
                    name="indicador"
                    options={ indicatorOptions }
                    onChange={ setIndicator }
                    value={ indicator }
                    fullWidth
                  />
                </ThemeProvider>
              </FormItem>

            </FormContainer>
          </Form>
        </div>
        {indicatorsLoaded ? (
          <>
            {(!isEmpty(indicatorsDates) && activeTab !== undefined && activeTab !== -1)
              ? (
                <>
                  <DateTabs
                    onChange={ handleChangeActiveDate }
                    value={ activeTab }
                    indicators={ indicatorsDates }
                    maxCloudsPercent={ maxCloudsPercent }
                  />
                  <DownloadIconContainer>
                    <DownloadIconButton
                      loading={ gettingImage }
                      onClick={ downloadTiff }
                      size={ 22 }
                      disabled={ !indicator }
                      tooltip={ t('download geotiff') }
                      color={ colors.white }
                    />
                  </DownloadIconContainer>
                </>
              ) : (
                <div
                  style={ {
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    justifyContent: 'center',
                    paddingLeft: 10,
                    color: colors.white
                  } }
                >
                  <Loader width={ 45 } style={ { padding: 0 } } />
                  <I18n style={ { padding: '0px 5px' } }>we are looking for your images</I18n>
                  <span>{timeCounter}s</span>
                </div>
              )}
          </>
        ) : (
          <LoaderContainer>
            <Loader width={ 45 } style={ { padding: 0 } } />
          </LoaderContainer>
        )}
      </Container>
    </>
  )
}

FieldIndexesNavigator.propTypes = {
  field: PropTypes.object,
  onChangeImage: PropTypes.func,
  fieldHistory: PropTypes.object,
  isFromHistory: PropTypes.bool
}

FieldIndexesNavigator.defaultProps = {
  onChangeImage: () => {},
  field: {},
  fieldHistory: {},
  isFromHistory: false
}

export default FieldIndexesNavigator
