import React, { useCallback, forwardRef, useMemo, useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import moment from 'moment'
import PropTypes from 'prop-types'

import filter from 'lodash/filter'
import isEmpty from 'lodash/isEmpty'
import map from 'lodash/map'

import { useDialog } from '@smartcoop/dialog'
import technicalVisitScheduleSchema from '@smartcoop/forms/schemas/property/technicalVisitSchedule.schema'
import I18n, { useT } from '@smartcoop/i18n'
import { getPropertiesByOwner } from '@smartcoop/services/apis/smartcoopApi/resources/property'
import { getTechnicalGoals } from '@smartcoop/services/apis/smartcoopApi/resources/technical'
import { selectCurrentPropertyId } from '@smartcoop/stores/property/selectorProperty'
import { TechnicalActions } from '@smartcoop/stores/technical'
import { selectTechnicalOwners } from '@smartcoop/stores/technical/selectorTechnical'
import Button from '@smartcoop/web-components/Button'
import CheckboxButton from '@smartcoop/web-components/CheckboxGroup/CheckboxButton'
import Form from '@smartcoop/web-components/Form'
import InputDate from '@smartcoop/web-components/InputDate'
import InputHour from '@smartcoop/web-components/InputHour'
import InputSelect from '@smartcoop/web-components/InputSelect'
import InputText from '@smartcoop/web-components/InputText'
import ConfirmModal from '@smartcoop/web-components/Modal/ConfirmModal'
import { ButtonsContainer } from '@smartcoop/web-containers/layouts/AuthenticatedLayout/theme'

import { Container, CheckboxContainer } from './styles'

const PropertyTechnicalVisitForm = forwardRef((props, formRef) => {
  const { style, technicalVisit, disabled, onSuccess, handleClose, readOnly, onVisit, fromTechnicalArea } = props
  const dispatch = useCallback(useDispatch(), [])
  const [ isEditing, setIsEditing] = useState(disabled)
  const [ notify, setNotify] = useState(technicalVisit[0]?.notify || false)
  const [ goalsOptions, setGoalsOptions] = useState([])
  const [propertyId, setPropertyId] = useState(technicalVisit[0]?.propertyId || useSelector(selectCurrentPropertyId) || null)
  const [producer, setProducer] = useState(technicalVisit[0]?.users[0]?.id || null)
  const [properties, setProperties] = useState([])
  const technicalOwners = useSelector(selectTechnicalOwners)


  const availableProducers = useMemo(() => filter(technicalOwners, item => item?.proprietaryTechnician?.read || item?.proprietaryTechnician?.assistance),[technicalOwners])

  const { createDialog } = useDialog()
  const t = useT()

  const loadTechnicalGoals = useCallback(
    async () => {
      const { data: { data } } = await getTechnicalGoals()
      setGoalsOptions(map(data, item => ({ value: item?.id, label: item?.description })))
    },
    []
  )

  useEffect(() => {
    loadTechnicalGoals()
  },[loadTechnicalGoals])

  const handleSubmitRegisterTechnicalVisit = useCallback(
    (data) => {
      createDialog({
        id: 'confirm-register-property-technical-visit',
        Component: ConfirmModal,
        props: {
          onConfirm: () => {
            dispatch(TechnicalActions.saveOfflineTechnicalVisit(
              {
                ...data,
                visitId: !isEmpty(technicalVisit) ? technicalVisit[0]?.visitId : null,
                predictedDate: `${ data?.predictedDate } ${ data?.hour }`,
                status: 'agendada',
                notify,
                propertyId
              },
              null,
              () => {
                onSuccess()
                handleClose()
              },
              handleClose,
              !isEmpty(technicalVisit) && technicalVisit[0]?.visitId
            ))
          },
          textWarning: !isEmpty(technicalVisit) && technicalVisit[0].id ? t('are you sure you want to edit this schedulement') : t('are you sure you want to register this schedulement')
        }
      })
    },
    [createDialog, dispatch, handleClose, notify, onSuccess, propertyId, t, technicalVisit]
  )

  const dateAndTime = useMemo(
    () => !isEmpty(technicalVisit) && ({
      'hour': moment(`${ technicalVisit[0].predictedDate }`, 'YYYY-MM-DD HH:mm').format('HH:mm'),
      'date': moment(`${ technicalVisit[0].predictedDate }`, 'YYYY-MM-DD HH:mm').format('YYYY-MM-DD')
    }),
    [technicalVisit]
  )

  const visitModeOptions = useMemo(
    () => (
      [
        {
          label: 'Presencial',
          value: 'presential'
        },
        {
          label: 'Virtual',
          value: 'virtual'
        }
      ]
    ),
    []
  )

  useEffect(() => {
    const getProperties = async () => {
      const { data: { data } } = await getPropertiesByOwner({}, { ownerId: producer })
      setProperties(data)
    }
    if(!isEmpty(producer)) {
      getProperties()
    }
  },[producer])


  const producersOptions = useMemo(
    () => map(availableProducers, (item) => ({ ...item, value: item.id, label: item.name })) || [],
    [availableProducers]
  )

  const propertiesOptions = useMemo(
    () => !producer
      ? []
      : map(
        properties,
        (item) => ({
          ...item,
          value: item.id,
          label: item?.name
        })) || [],
    [producer, properties]
  )

  return (
    <Container>
      <Form
        style={ { ...style } }
        ref={ formRef }
        schemaConstructor={ technicalVisitScheduleSchema }
        onSubmit={ handleSubmitRegisterTechnicalVisit }
      >
        <div style={ { display: 'flex', flexDirection: 'column', alignItems: 'center', flex: 1, marginBottom: 20 } }>
          {fromTechnicalArea ? (
            <div style={ { display: 'flex', alignSelf: 'flex-start', flex: 1, flexDirection: 'row', width: '100%' } }>
              <InputSelect
                detached
                label={ t('producer', { howMany: 1, gender: 'male' }) }
                options={ producersOptions }
                value={ producer }
                onChange={ value => {
                  setPropertyId(null)
                  setProducer(value)
                } }
                disabled={ isEditing }
                style={ { marginRight: 5 } }
                fullWidth
              />
              <InputSelect
                detached
                label={ t('property', { howMany: 1 }) }
                options={ propertiesOptions }
                onChange={ value => setPropertyId(value) }
                value={ propertyId }
                disabled={ isEditing }
                fullWidth
              />
            </div>
          ) : null }
          <div style={ { display: 'flex', alignSelf: 'flex-start', flex: 1, flexDirection: 'row', width: '100%' } }>
            <InputDate
              name="predictedDate"
              label="Data"
              defaultValue={ dateAndTime ? dateAndTime?.date : '' }
              style={ { minWidth: 160 } }
              disabled={ isEditing }
              pickerProps={ { minDate: moment().format() } }
            />
            <InputHour
              name="hour"
              label={ t('hour') }
              style={ { maxWidth: 160, marginLeft: 20 } }
              defaultValue={ dateAndTime ? dateAndTime?.hour : '' }
              disabled={ isEditing }
            />
            <InputSelect
              fullWidth
              label={ t('visit mode') }
              name="visitMode"
              options={ visitModeOptions }
              defaultValue={ technicalVisit[0]?.visitMode }
              disabled={ isEditing || readOnly }
              style={ { marginLeft: 20, maxWidth: 200 } }
            />
            <InputSelect
              fullWidth
              label={ t('goal') }
              name="visitGoalId"
              options={ goalsOptions }
              defaultValue={ technicalVisit[0]?.visitGoalId }
              disabled={ isEditing || readOnly }
              style={ { marginLeft: 20, minWidth: 200 } }
            />
          </div>

          <InputText
            name="scheduleNote"
            label={ t('observation', { howMany: 2 }) }
            fullWidth
            multiline
            defaultValue={ technicalVisit[0]?.scheduleNote }
            disabled={ isEditing }
            rows={ 4  }
          />

          <CheckboxContainer>
            <CheckboxButton
              value={ notify }
              checked={ notify }
              style={ { width: '100%', marginBottom: '20px' } }
              onChange={ () => setNotify(!notify) }
              label={ t('notify the producer about the schedulement') }
              disabled={ isEditing }
              hideColor
            />
          </CheckboxContainer>
        </div>

        { !readOnly && (
          <ButtonsContainer>
            <Button
              id="cancel-technical-visit-form"
              onClick={ handleClose }
              style={ { flex: 1, marginRight: 10 } }
              variant="outlined"
            >
              <I18n>cancel</I18n>
            </Button>

            {!isEmpty(technicalVisit) && isEditing && (
              <Button
                id="edit-technical-visit-form"
                onClick={ () => setIsEditing(!isEditing) }
                style={ { flex: 1, marginRight: 10 } }
                color="secondary"
              >
                <I18n>edit</I18n>
              </Button>
            )}

            {!isEditing && (
              <Button
                id="submit-technical-visit-form"
                onClick={ () => formRef.current.submit() }
                style={ { flex: 1 } }
              >
                <I18n>save</I18n>
              </Button>
            )}

            {!isEmpty(technicalVisit) && isEditing && (
              <Button
                id="realize-technical-visit"
                onClick={ () => {
                  onVisit()
                  handleClose()
                } }
              >
                <I18n>realize visit</I18n>
              </Button>
            )}
          </ButtonsContainer>
        )}
      </Form>
    </Container>
  )
})

PropertyTechnicalVisitForm.propTypes = {
  style: PropTypes.object,
  handleClose: PropTypes.func,
  technicalVisit: PropTypes.object,
  disabled: PropTypes.bool,
  fromTechnicalArea: PropTypes.bool,
  readOnly: PropTypes.bool,
  onSuccess: PropTypes.func,
  onVisit: PropTypes.func
}

PropertyTechnicalVisitForm.defaultProps = {
  style: {},
  handleClose: () => {},
  onVisit: () => {},
  technicalVisit: [],
  disabled: false,
  fromTechnicalArea: false,
  readOnly: false,
  onSuccess: () => {}
}

export default PropertyTechnicalVisitForm
