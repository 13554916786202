import map from 'lodash/map'
import orderBy from 'lodash/orderBy'
import reduce from 'lodash/reduce'

import api from '../api'

export const getProperties = async (params) => {
  const { data } = await api.get('/v2/properties', { params })
  return data
}

export const getPropertiesByOwner = async (params, { ownerId }) =>
  api.get(`/v1/properties/all-properties/${ ownerId }`, { params })

export const getPropertiesWithData = async (params) =>
  api.get('/v1/properties', { params })

export const selectGetProperties = async (params) =>
  api.get('/v1/properties', { params })

export const createProperty = (params) => api.post('/v1/properties', params)

export const getProperty = (params, { propertyId }) =>
  api.get(`/v1/properties/${ propertyId }`, { params })

export const editProperty = (params, { propertyId }) =>
  api.patch(`/v1/properties/${ propertyId }`, params)

export const deleteProperty = ({ propertyId }) =>
  api.delete(`/v1/properties/${ propertyId }`)

export const getPropertyActivitiesConsolidate = ({ propertyId }) =>
  api.get(`/v1/property/${ propertyId }/activities/consolidate`)

export const getPropertyGrowingSeasonsConsolidate = ({ propertyId }) =>
  api.get(`/v1/growing-seasons/consolidate/${ propertyId }`)

export const getPropertyGrowingSeasonsHistory = (params, { propertyId }) =>
  api.get(`/v1/growing-seasons/property/${ propertyId }/history`, { params })

export const getPendingManagements = async ({ propertyId }) => {
  const {
    data: { data }
  } = await api.get(`/v1/properties/${ propertyId }/crop-management`)

  const managements = reduce(
    data,
    (acc, { cropManagement, ...growingSeason }) => [
      ...acc,
      ...map(cropManagement, (crop) => ({
        ...crop,
        growingSeason
      }))
    ],
    []
  )

  return orderBy(managements, 'predictedDate', 'asc')
}

export const loadPropertyParameters = async (params, { propertyId }) =>
  api.get(`/v1/properties/${ propertyId }/parameters`, { params })

export const loadLotsByUser = async (params) =>
  api.get('/v1/property/lots-by-user', { params })

export const patchPropertyParameters = async (params, { parametersId }) =>
  api.patch(`/v1/properties/parameters/${ parametersId }`, params)

export const getLotsByUser = async (params) =>
  api.get('/v1/property/lots-by-user', { params })

export const createReport = (params) => api.post('/v1/reports', params)

export const getReport = async ({ reportId }) =>
  api.get(`/v1/reports/${ reportId }`)
