import React, { forwardRef, useMemo, useState, useEffect, useCallback } from 'react'
import { useSelector } from 'react-redux'

import PropTypes from 'prop-types'
import { v4 as uuidv4 } from 'uuid'

import debounce from 'lodash/debounce'
import find from 'lodash/find'
import first from 'lodash/first'
import isEmpty from 'lodash/isEmpty'
import isNil from 'lodash/isNil'
import map from 'lodash/map'
import replace from 'lodash/replace'
import round from 'lodash/round'
import split from 'lodash/split'
import times from 'lodash/times'
import toNumber from 'lodash/toNumber'
import toString from 'lodash/toString'

import phytosanitaryCropManagementSchema from '@smartcoop/forms/schemas/management/phytosanitaryCropManagement.schema'
import { useT } from '@smartcoop/i18n'
import { getProducts as getProductsService } from '@smartcoop/services/apis/smartcoopApi/resources/product'
import { getProductGroups as getProductGroupsService } from '@smartcoop/services/apis/smartcoopApi/resources/productGroup'
import { selectModuleIsTechnical } from '@smartcoop/stores/module/selectorModule'
import { selectFamilyGroupAccess, selectFamilyGroupFinancialDataAccess } from '@smartcoop/stores/property/selectorProperty'
import { selectCurrentOwnerExtraAttributes, selectTechnicalFinancialDataAccess } from '@smartcoop/stores/technical/selectorTechnical'
import { selectUserExtraAttributes } from '@smartcoop/stores/user/selectorUser'
import { PHYTOSANITARY_APPLICATION_PRODUCT_GROUPS } from '@smartcoop/utils/constants'
import { convertValues, getBaseVolume, getExtenseBaseVolume } from '@smartcoop/utils/functions'
import Button from '@smartcoop/web-components/Button'
import Form from '@smartcoop/web-components/Form'
import InputFloat from '@smartcoop/web-components/InputFloat'
import InputHour from '@smartcoop/web-components/InputHour'
import InputSelect from '@smartcoop/web-components/InputSelect'
import InputUnit from '@smartcoop/web-components/InputUnit'

import { Divider } from './styles'

const PhytosanitaryApplicationForm = forwardRef(({ defaultValues: { hour, formFields }, handleSubmit, readOnly, area }, formRef) => {
  const t = useT()
  const [items, setItems] = useState(!isEmpty(formFields) ? formFields.length: 1)
  const [currentProductsGroups, setCurrentProductsGroups] = useState([])
  const [productsOptions, setProductsOptions] = useState([])
  const [loading, setLoading] = useState(false)
  const [dose, setDose] = useState({})

  const userExtraAttributes = useSelector(selectUserExtraAttributes)
  const currentOwnerExtraAttributes = useSelector(selectCurrentOwnerExtraAttributes)

  const technicalFinancialDataAccess = useSelector(selectTechnicalFinancialDataAccess)
  const familyGroupFinancialDataAccess = useSelector(selectFamilyGroupFinancialDataAccess)

  const familyGroupAccess = useSelector(selectFamilyGroupAccess)
  const isTechnical = useSelector(selectModuleIsTechnical)

  const extraAttributes = useMemo(() => userExtraAttributes || {}, [userExtraAttributes])

  // eslint-disable-next-line no-nested-ternary
  const shouldDisplayCosts = useMemo(() => familyGroupAccess ? familyGroupFinancialDataAccess : isTechnical ? technicalFinancialDataAccess && currentOwnerExtraAttributes?.enabledCosts : extraAttributes?.enabledCosts,[currentOwnerExtraAttributes, extraAttributes, familyGroupAccess, familyGroupFinancialDataAccess, isTechnical, technicalFinancialDataAccess])

  const unitsOptions = useMemo(
    () => (
      [
        {
          label: 'kg/ha',
          value: 'kg/ha'
        },
        {
          label: 'mg/ha',
          value: 'mg/ha'
        },
        {
          label: 'g/ha',
          value: 'g/ha'
        },
        {
          label: 'l/ha',
          value: 'l/ha'
        },
        {
          label: 'ml/ha',
          value: 'ml/ha'
        },
        {
          label: 'ton/ha',
          value: 'ton/ha'
        }
      ]
    ),
    []
  )

  useEffect(() => {
    if(!isEmpty(formFields)) {
      const defaultDose = {}
      map(formFields, (item, index) => {
        defaultDose[index] = {
          value: toNumber(replace(item?.dosePhyntosanitary, ',', '.')) || 0,
          unit: item?.unit
        }
      })
      setDose(defaultDose)
    }
  },[formFields])

  useEffect(() => {
    const loadProductGroup = async () => {
      if(!isEmpty(formFields)) {
        setLoading(true)
        const func = map(formFields, async (item) => {
          const { data: {
            data
          } } = await getProductGroupsService({ orderBy: 'id', slug: item?.productGroup })

          return { ...data[0] }
        })

        Promise.all(func).then((data) => {
          setCurrentProductsGroups(data)
          setLoading(false)
        })

        setLoading(false)
      }
    }

    loadProductGroup()
  }, [formFields])

  useEffect(() => {
    const func = map(currentProductsGroups, async (item) => {
      const { data: {
        data
      } } = await getProductsService({
        productGroupId: item?.id,
        limit: process.env.HUGE_REACT_APP_FAKE_PAGINATION_SIZE || 10000,
        digitalization: true
      })
      return map(data, productItem => ({
        label: productItem.shortDescription,
        value: productItem.slug,
        id: productItem.id
      }))
    })

    if(!isEmpty(currentProductsGroups)){
      Promise.all(func).then((products) => {
        setProductsOptions(products)
        setLoading(false)
      })
    }
  }, [currentProductsGroups])

  const onSubmit = useCallback((data) => {
    const updatedData = {
      ...data,
      formFields: map(data.formFields, (item, key) => ({
        ...item,
        value: item?.value || null,
        valueHA: !isEmpty(item?.valueHA) ? toNumber(item?.valueHA) : null,
        productId: find(productsOptions[key], product => product?.value === item.products).id,
        dosePhyntosanitary: !isEmpty(item?.dosePhyntosanitary) ? toString(toNumber(item?.dosePhyntosanitary)) : item?.dosePhyntosanitary,
        referenceId: formFields?.[key]?.referenceId || uuidv4()
      }))
    }

    handleSubmit(updatedData)
  }, [formFields, handleSubmit, productsOptions])

  const updateCostValues = useCallback(debounce((value, item, from) => {
    const formUnit = formRef.current.getFieldValue(`formFields[${ item }].unit`)
    const dosePhyntosanitary = formRef.current.getFieldValue(`formFields[${ item }].dosePhyntosanitary`)

    if(isEmpty(formUnit) || isEmpty(dosePhyntosanitary)) {
      return
    }

    const unit = first(split(formUnit, '/'))
    const convertedCost = convertValues(dosePhyntosanitary, unit, getBaseVolume(unit))

    switch(from) {
      case 'ha':
        formRef.current.getFieldRef(`formFields[${ item }].valueDose`).setValue(round(value/convertedCost, 2))
        formRef.current.getFieldRef(`formFields[${ item }].value`).setValue(round(value*area, 4))
        break
      case 'dose':
        formRef.current.getFieldRef(`formFields[${ item }].valueHA`).setValue(round(value*convertedCost, 2))
        formRef.current.getFieldRef(`formFields[${ item }].value`).setValue(round((value*convertedCost )*area, 4))
        break
      case 'unit':
      case 'formDose':
        // eslint-disable-next-line no-case-declarations
        const doseValue = formRef.current.getFieldValue(`formFields[${ item }].valueDose`)
        formRef.current.getFieldRef(`formFields[${ item }].valueHA`).setValue(round(doseValue*convertedCost, 2))
        break
      default:
        break
    }
  }, 500), [area, formRef])

  return (
    <>
      <Form
        ref={ formRef }
        schemaConstructor={ phytosanitaryCropManagementSchema }
        containerStyle={ { height: 'auto' } }
        onSubmit={ onSubmit }
      >
        { times(items, (item) => (
          <div key={ item }>
            {item === 0 ? (
              <InputHour
                name="hour"
                label={ t('hour') }
                fullWidth
                defaultValue={ hour }
                disabled={ readOnly }
              />
            ) : (
              <Divider />
            )}

            <InputSelect
              label={ t('product group') }
              name={ `formFields[${ item }].productGroup` }
              options={ PHYTOSANITARY_APPLICATION_PRODUCT_GROUPS }
              onChange={ async (value) => {
                setLoading(true)
                const updatedItems = [...currentProductsGroups]
                const { data: {
                  data
                } } = await getProductGroupsService({ orderBy: 'id', slug: value.target.value })
                // eslint-disable-next-line prefer-destructuring
                updatedItems[item] = data[0]
                setCurrentProductsGroups(updatedItems)
              } }
              defaultValue={ !isNil(formFields) ? formFields[item]?.productGroup : '' }
              disabled={ readOnly }
            />
            <InputSelect
              label={ t('products') }
              name={ `formFields[${ item }].products` }
              options={ productsOptions[item] }
              defaultValue={ !isNil(formFields) ? formFields[item]?.products : '' }
              disabled={ loading || readOnly }
            />

            <div style={ { display: 'flex', flex: 1 } }>
              <InputFloat
                name={ `formFields[${ item }].dosePhyntosanitary` }
                label={ t('dosePhyntosanitary') }
                maxLength={ 8 }
                defaultValue={ !isNil(formFields) ? toNumber(replace(formFields[item]?.dosePhyntosanitary, ',', '.')) || 0 : 0 }
                fullWidth
                style={ { marginRight: '5px' } }
                disabled={ readOnly }
                onChange={ ({ target: { value } }) => setDose(old => {
                  const currentDose = { ...old }
                  currentDose[item] = old?.[item] || {}
                  currentDose[item].value = value
                  updateCostValues(null, item, 'formDose')
                  return currentDose
                }) }
              />
              <InputSelect
                label={ t('unit') }
                name={ `formFields[${ item }].unit` }
                options={ unitsOptions }
                defaultValue={ !isNil(formFields) ? formFields[item]?.unit : '' }
                fullWidth
                style={ { marginLeft: '5px' } }
                disabled={ readOnly }
                onChange={ ({ target: { value } }) => setDose(old => {
                  const currentDose = { ...old }
                  currentDose[item].unit = value
                  updateCostValues(null, item, 'unit')
                  return currentDose
                }) }
              />
            </div>
            {shouldDisplayCosts ? (
              <div style={ { display: 'flex', flexDirection: 'column' } }>
                <div style={ { display: 'flex', flexDirection: 'row' } }>
                  <InputUnit
                    name={ `formFields[${ item }].valueDose` }
                    label={ `${ t('cost by') } ${ getExtenseBaseVolume(dose?.[item]?.unit || formFields?.[item]?.unit) || 'dose' }` }
                    unit={ `R$/${ getExtenseBaseVolume(dose?.[item]?.unit || formFields?.[item]?.unit) || 'dose' }` }
                    type="float"
                    fullWidth
                    onChange={ ({ target: { value } }) => updateCostValues(value, item, 'dose') }
                    disabled={ !dose?.[item]?.unit || !dose?.[item]?.value }
                    defaultValue={ !isNil(formFields) && formFields[item]?.valueDose }
                    style={ { marginRight: 10 } }
                  />
                  <InputUnit
                    name={ `formFields[${ item }].valueHA` }
                    label={ t('cost by ha') }
                    unit="R$/ha"
                    type="float"
                    fullWidth
                    onChange={ ({ target: { value } }) => updateCostValues(value, item, 'ha') }
                    disabled={ !dose?.[item]?.unit || !dose?.[item]?.value }
                    defaultValue={ !isNil(formFields) && round(formFields[item]?.value/area, 2) }
                  />
                </div>

                <InputUnit
                  name={ `formFields[${ item }].value` }
                  disabled
                  label={ t('total cost') }
                  unit="R$"
                  type="float"
                  fullWidth
                  defaultValue={ !isNil(formFields) && formFields[item]?.value }
                />
              </div>
            ) : null }
          </div>
        ))}
      </Form>
      <div style={ { display: 'flex', justifyContent: 'space-between', marginBottom: '10px' } }>
        <Button
          id="button-remove-item"
          onClick={ () => setItems(items - 1) }
          variant="outlined"
          disabled={ items === 1 || readOnly }
          style={ { marginRight: '60px' } }
        >
        -
        </Button>

        <Button
          id="button-add-item"
          onClick={ () => setItems(items + 1) }
          style={ { marginLeft: '60px' } }
          disabled={ readOnly }
        >
        +
        </Button>
      </div>
    </>
  )
})

PhytosanitaryApplicationForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  area: PropTypes.number.isRequired,
  readOnly: PropTypes.bool,
  defaultValues: PropTypes.object
}

PhytosanitaryApplicationForm.defaultProps = {
  readOnly: false,
  defaultValues: {}
}

export default PhytosanitaryApplicationForm
