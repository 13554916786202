import React, { useCallback, forwardRef, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'

import moment from 'moment/moment'
import PropTypes from 'prop-types'

import filter from 'lodash/filter'
import map from 'lodash/map'

import Grid from '@material-ui/core/Grid'

import registerDiagnosisActionsSchema from '@smartcoop/forms/schemas/dairyFarm/registerDiagnosisActions.schema'
import I18n, { useT } from '@smartcoop/i18n'
import { getAnimals as getAnimalsService } from '@smartcoop/services/apis/smartcoopApi/resources/animal'
import { getAnimalPregnancyDiagnosticsTypes } from '@smartcoop/services/apis/smartcoopApi/resources/animalPregnancyDiagnostics'
import { selectCurrentAnimal } from '@smartcoop/stores/animal/selectorAnimal'
import { selectCurrentInsemination } from '@smartcoop/stores/insemination/selectorInsemination'
import { selectCurrentProperty } from '@smartcoop/stores/property/selectorProperty'
import { colors } from '@smartcoop/styles'
import { AnimalStatusCode } from '@smartcoop/utils/constants'
import Button from '@smartcoop/web-components/Button'
import Form from '@smartcoop/web-components/Form'
import InputDate from '@smartcoop/web-components/InputDate'
import InputSelect from '@smartcoop/web-components/InputSelect'

import {
  Container,
  FormContainer,
  ButtonsContainer,
  Item
} from './styles'

const RegisterDiagnosisActionsForm = forwardRef((props, formRef) => {
  const {
    withoutSubmitButton,
    defaultValues,
    loading,
    onSubmit,
    onCancel
  } = props

  const t = useT()
  const currentInsemination = useSelector(selectCurrentInsemination)

  const currentAnimal = useSelector(selectCurrentAnimal)
  const currentProperty = useSelector(selectCurrentProperty)

  const [animals, setAnimals] = useState([])

  const handleSubmit = useCallback(
    (data) => {
      onSubmit({ ...data })
    },
    [onSubmit]
  )

  useEffect(() => {
    const loadAnimals = async() => {
      const { data: { data: response } } = await getAnimalsService({ limit: 99999 }, { propertyId: currentProperty?.id })
      const filteredAnimals = filter(response, item => (item?.category === 'vaca' ||
      item?.category === 'novilha') &&
      (item.animalStatus.id === AnimalStatusCode.INSEMINADA ||
        item.animalStatus.id === AnimalStatusCode.INSEMINADA_A_CONFIRMAR ||
        item.animalStatus.id === AnimalStatusCode.PRENHA))
      setAnimals(map(filteredAnimals, item => ({ label: item?.earring?.earringCode, value: item.id })))
    }

    loadAnimals()
  },[currentProperty.id])

  return (
    <Container>
      <Form
        style={ { display: 'flex', flexDirection: 'column', width: '100%' } }
        ref={ formRef }
        schemaConstructor={ registerDiagnosisActionsSchema }
        onSubmit={ handleSubmit }
        schemaProps={ { isEditing: !!defaultValues?.result } }
      >
        <FormContainer>
          <Grid container style={ { justifyContent: 'space-between' } }>
            <InputSelect
              label={ t('earrings') }
              name="animalsId"
              options={ animals }
              multiple
              defaultValue={ [defaultValues?.animalId] }
              disabled={ !!defaultValues?.id }
            />
            <Item>
              <InputSelect
                label={ t('type of diagnosis') }
                name="diagnosisTypeId"
                options={ getAnimalPregnancyDiagnosticsTypes }
                urlParams={ { animalId: currentAnimal.id } }
                asyncOptionLabelField="typeName"
                asyncOptionValueField="id"
                defaultValue={ defaultValues.diagnosisTypeId }
              />
            </Item>
            <Item>
              <InputDate
                label={ t('accomplished date') }
                name="realizationDate"
                defaultValue={ defaultValues.realizationDate }
                pickerProps={ {
                  maxDate: moment().format(),
                  minDate: currentInsemination?.inseminationDate
                } }
                fullWidth
              />
            </Item>
            <Item>
              <InputSelect
                label={ t('result') }
                name="result"
                defaultValue={ defaultValues.result }
                options={ [
                  {
                    label: t('pregnant'),
                    value: 'Positivo'
                  },
                  {
                    label: t('empty'),
                    value: 'Vazia'
                  },
                  {
                    label: t('inconclusive'),
                    value: 'Inconclusivo'
                  }
                ] }
              />
            </Item>
          </Grid>
        </FormContainer>

        {!withoutSubmitButton && (
          <ButtonsContainer>
            <Button
              id="web-cancel-form-button"
              onClick={ onCancel }
              color={ colors.white }
              disabled={ loading }
              fullWidth
              style={ { marginRight: 5 } }
            >
              <I18n>cancel</I18n>
            </Button>
            <Button
              id="web-save-form-button"
              onClick={ () => formRef.current.submit() }
              disabled={ loading }
              fullWidth
              style={ { marginLeft: 5 } }
            >
              <I18n>register</I18n>
            </Button>
          </ButtonsContainer>
        )}
      </Form>
    </Container>
  )
})

RegisterDiagnosisActionsForm.propTypes = {
  loading: PropTypes.bool,
  onSubmit: PropTypes.func,
  onCancel: PropTypes.func,
  withoutSubmitButton: PropTypes.bool,
  defaultValues: PropTypes.object.isRequired
}

RegisterDiagnosisActionsForm.defaultProps = {
  loading: false,
  onSubmit: () => {},
  onCancel: () => {},
  withoutSubmitButton: false
}

export default RegisterDiagnosisActionsForm
