import React, { useMemo, useCallback, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import PropTypes from 'prop-types'

import { useDialog } from '@smartcoop/dialog'
import { useT } from '@smartcoop/i18n'
import { pencil, plantAndHand, trash } from '@smartcoop/icons'
import { getCostsManagementItems } from '@smartcoop/services/apis/smartcoopApi/resources/costsManagement'
import { useSnackbar } from '@smartcoop/snackbar'
import { PropertyActions } from '@smartcoop/stores/property'
import { selectCurrentPropertyId } from '@smartcoop/stores/property/selectorProperty'
import Button from '@smartcoop/web-components/Button'
import DataTable from '@smartcoop/web-components/DataTable'
import Icon from '@smartcoop/web-components/Icon'
import Modal from '@smartcoop/web-components/Modal'
import ConfirmModal from '@smartcoop/web-components/Modal/ConfirmModal'
import RegisterRubricModal from '@smartcoop/web-containers/modals/RegisterRubricModal'

import { Container, ButtonRow, RowHeader } from './styles'

const RubricsListModal = ({ open, id }) => {
  const t = useT()
  const { createDialog, removeDialog } = useDialog()
  const dispatch = useCallback(useDispatch(), [])
  const snackbar = useSnackbar()
  const tableRef = useRef(null)

  const propertyId = useSelector(selectCurrentPropertyId)

  const columns = useMemo(
    () => [
      {
        title: t('description'),
        field: 'description',
        align: 'left'
      },
      {
        title: t('rubric'),
        field: 'rubricGroup',
        align: 'left',
        render: (row) => row?.rubricGroup?.description
      }
    ], [t]
  )

  const reloadDataTable = useCallback(
    () => {
      tableRef.current.onQueryChange()
    },
    []
  )

  const onSuccessEditingRubric = useCallback(
    () => {
      snackbar.success(
        t('your {this} was edited', {
          howMany: 1,
          gender: 'female',
          this: t('rubric')
        })
      )
      reloadDataTable()
      removeDialog({ id: 'register-rubric' })
    },
    [reloadDataTable, removeDialog, snackbar, t]
  )


  const handleDelete = useCallback(
    (row) => {
      createDialog({
        id: 'confirm-delete',
        Component: ConfirmModal,
        props: {
          onConfirm: () => {
            dispatch(PropertyActions.deleteRubric(
              row.id,
              () => {
                reloadDataTable()
                snackbar.success(
                  t('your {this} was deleted', {
                    howMany: 1,
                    gender: 'female',
                    this: t('rubric')
                  })
                )
              }
            ))
          },
          textWarning: t('are you sure you want to delete the {this}?', {
            howMany: 1,
            gender: 'female',
            this: t('rubric')
          })
        }
      })
    },
    [createDialog, dispatch, reloadDataTable, snackbar, t]
  )

  const onSuccessRegisterRubric = useCallback(
    () => {
      snackbar.success(
        t('your {this} was registered', {
          howMany: 1,
          gender: 'female',
          this: t('rubric')
        })
      )
      reloadDataTable()
      removeDialog({ id: 'register-rubric' })
    },
    [reloadDataTable, removeDialog, snackbar, t]
  )

  const openRegisterRubric = useCallback((rubric = {}) => {
    createDialog({
      id: 'register-rubric',
      Component: RegisterRubricModal,
      props: {
        onSuccess: rubric?.id ? onSuccessEditingRubric : onSuccessRegisterRubric,
        defaultValues: rubric
      }
    })
  }, [createDialog, onSuccessEditingRubric, onSuccessRegisterRubric])

  const actions = useMemo(
    () => [
      (row) => ({
        position: 'row',
        onClick: () => row?.propertyId ? openRegisterRubric(row) : null,
        iconButtonProps: {},
        tooltip: row?.propertyId ? t('edit') : '',
        icon: row?.propertyId ? () => (<Icon size={ 16 } icon={ pencil }/>) : () => (<></>)
      }),
      (row) => ({
        position: 'row',
        onClick: () => row?.propertyId ? handleDelete(row) : null,
        iconButtonProps: {},
        tooltip: row?.propertyId ? t('delete') : '',
        icon: row?.propertyId ? () => (<Icon size={ 16 } icon={ trash } color="red" />) : () => (<></>)
      })
    ],
    [handleDelete, openRegisterRubric, t]
  )

  return(
    <Modal
      id={ id }
      open={ open }
      title={ t('rubrics') }
      maxWidth="md"
      fullWidth
      disableFullScreen
    >
      <Container>
        <RowHeader>
          <ButtonRow>
            <Button
              id="register-rubric"
              color="secondary"
              style={ {
                padding: '5.5px 10px',
                fontSize: '0.875rem'
              } }
              onClick={ openRegisterRubric }
            >
              <>
                <Icon
                  icon={ plantAndHand }
                  size={ 17 }
                  style={ { marginRight: 5 } }
                />
                {t('register rubric')}
              </>
            </Button>
          </ButtonRow>
        </RowHeader>
        <DataTable
          tableRef={ tableRef }
          columns={ columns }
          data={ getCostsManagementItems }
          params={ { propertyId } }
          id="technical-visit-table"
          hasPagination={ false }
          hidePagination
          pageSize={ 9999 }
          actions={ actions }
        />
      </Container>
    </Modal>
  )
}

RubricsListModal.propTypes = {
  open: PropTypes.bool.isRequired,
  id: PropTypes.string.isRequired
}

export default RubricsListModal
