import React, { useState, useCallback, useMemo } from 'react'

import withObservables from '@nozbe/with-observables'
import PropTypes from 'prop-types'

import get from 'lodash/get'
import map from 'lodash/map'
import orderBy from 'lodash/orderBy'
import uniqWith from 'lodash/uniqWith'

import { contactService } from '@smartcoop/database/services/contactService'
import { syncService } from '@smartcoop/database/services/syncService'
import { database } from '@smartcoop/database/web-database'
import { useDialog } from '@smartcoop/dialog'
import { useT } from '@smartcoop/i18n'
import { addContact, arrowRightSimple, emptyFilter } from '@smartcoop/icons'
import { addChatContact, getChatContact , deleteChatContact } from '@smartcoop/services/apis/smartcoopApi/resources/chat'
import { useSnackbar } from '@smartcoop/snackbar'
import { colors } from '@smartcoop/styles'
import EmptyState from '@smartcoop/web-components/EmptyState'
import Icon from '@smartcoop/web-components/Icon'
import ConfirmModal from '@smartcoop/web-components/Modal/ConfirmModal'


import IconButton from '../../../components/IconButton'
import { useAuthenticatedUser } from '../../../hooks/useAuthenticatedUser'
import ContactListItem from '../ContactListItem'
import {
  AddContactWrapper,
  Card,
  CustomInput,
  GroupDropdown,
  GroupTitle,
  GroupWrapper,
  Title,
  ButtonLoader,
  CustomLoader
} from './styles'

const ContactList = ({ contacts, onStartConversation }) => {
  const [loading, setLoading] = useState(false)
  const [openedCategories, setOpenedCategories] = useState({
    smartcoop: false,
    organizationContacts: false,
    producer: false,
    technician: false,
    walletProducers: false,
    producersOutOfWallet: false,
    cooperative: false,
    ccgl: false,
    family: false,
    added: true
  })

  const localUser = useAuthenticatedUser()
  const userProfiles = useMemo(() => {
    if (localUser?.profiles) {
      return JSON.parse(localUser?.profiles, '[]')
        .map(item => Number(item))
    }
    return []
  }, [localUser])

  const userProfileMap = {
    1: 'supplierGroup',
    2: 'producer',
    3: 'family',
    6: 'technician'
  }

  const [code, setCode] = useState('')

  const t = useT()
  const snackbar = useSnackbar()
  const { createDialog } = useDialog()

  const handleAddContact = useCallback(async (contactCode) => {
    try {
      setLoading(true)

      await addChatContact(contactCode)
      await syncService(database).syncContacts()

      snackbar.success(t('contact successfully added'))
    } catch(error) {
      const message = get(error, 'response.data.message', 'error on add contact')

      snackbar.error(t(message?.toLowerCase()))
    } finally {
      setCode('')
      setLoading(false)
    }
  }, [snackbar, t])

  const onAddContact = useCallback(async () => {
    try {
      if (code?.length !== 8) {
        return snackbar.warning(t('invalid contact code'))
      }

      setLoading(true)

      const { data: response } = await getChatContact(code.toUpperCase())

      return createDialog({
        id: 'confirm-add-modal',
        Component: ConfirmModal,
        props: {
          onConfirm: () => handleAddContact(code),
          textWarning: t('do you want to add {name} to your contacts?', {
            name: response?.chatNickname
          })
        }
      })
    } catch(error) {
      const message = get(error, 'response.data.message', 'error on search contact')

      return snackbar.error(t(message?.toLowerCase()))
    } finally {
      setLoading(false)
    }
  }, [code, createDialog, handleAddContact, snackbar, t])

  const handleRemoveContact = useCallback(async (contactCode) => {
    try {
      await deleteChatContact(contactCode)
      await contactService(database).deleteContactByCode(contactCode)

      snackbar.success(t('contact successfully removed'))
    } catch(error) {
      const message = get(error, 'response.data.message', 'error on remove contact')

      snackbar.error(t(message?.toLowerCase()))
    }
  }, [snackbar, t])

  const onRemoveContact = useCallback(async(contact) => createDialog({
    id: 'confirm-deletion-modal',
    Component: ConfirmModal,
    props: {
      onConfirm: () => handleRemoveContact(contact?.userCode),
      textWarning: t('do you want to remove {name} from your contacts?', {
        name: contact?.chatNickname
      })
    }
  }), [createDialog, handleRemoveContact, t])

  const uniqMembers = useMemo(() => uniqWith(
    contacts,
    (a, b) => (
      a.contactId === b.contactId &&
      a.contactType === b.contactType
    )
  ), [contacts])

  const categories = [
    { description: t('smartcoop contacts'), field: 'smartcoop' },
    { description: t('organization contacts'), field: 'organizationContacts' },
    { description: t('producer contacts'), field: 'producer' },
    { description: t('technician contacts'), field: 'technician' },
    { description: t('wallet producers'), field: 'walletProducers' },
    { description: t('out of wallet producers'), field: 'producersOutOfWallet' },
    { description: t('cooperative group'), field: 'cooperative' },
    { description: t('ccgl'), field: 'ccgl' },
    { description: t('family contacts'), field: 'family' },
    { description: t('added contacts'), field: 'added' }
  ]

  const contactsByCategory = useMemo(() => ({
    added: uniqMembers?.filter(item => item.contactType === 'added'),
    family: uniqMembers?.filter(item => item.contactType === 'family'),
    organizationContacts: uniqMembers?.filter(item => item.contactType === 'organizationContacts'),
    producer: uniqMembers?.filter(item => item.contactType === 'producer'),
    walletProducers: uniqMembers?.filter(item => item.contactType === 'walletProducers'),
    producersOutOfWallet: uniqMembers?.filter(item => item.contactType === 'producersOutOfWallet'),
    cooperative: uniqMembers?.filter(item => item.contactType === 'cooperative'),
    ccgl: uniqMembers?.filter(item => item.contactType === 'CCGL'),
    smartcoop: uniqMembers?.filter(item => item.contactType === 'smartcoop'),
    technician: uniqMembers?.filter(item => item.contactType === 'technician')
  }), [uniqMembers])

  const categoriesToShow = useMemo(
    () => categories.filter(
      category => uniqMembers.some(contact => contact.contactType?.toLowerCase() === category.field?.toLowerCase())
    ),
    [categories, uniqMembers]
  )

  const categoryOrdenator = (categoryList, profiles) => {

    const customOrder = {
      smartcoop: 0,
      technician: 1,
      walletProducers: 2,
      producersOutOfWallet: 3,
      cooperative: 4,
      ccgl: 5,
      family: 6,
      added: 7
    }

    const contactsForAllProfiles = new Set()

    map(profiles, profile => {
      const currentProfile = userProfileMap[profile]

      if (!currentProfile) {
        contactsByCategory.ccgl.length > 0 && contactsForAllProfiles.add(
          categories.filter(category => category.field === 'ccgl')[0]
        )
      }

      map(categoryList, category =>
        contactsForAllProfiles.add(category)
      )
    })

    const sortedContacts = orderBy(Array.from(contactsForAllProfiles), item => customOrder[item.field] || 0)

    return sortedContacts
  }

  return (
    <Card>
      <Title>{t('add contact')}</Title>

      <AddContactWrapper>
        <CustomInput
          placeholder={ t('user code') }
          value={ code }
          onChange={ (e) => setCode(e.target.value) }
        />
        {loading ? (
          <ButtonLoader>
            <CustomLoader size={ 25 } />
          </ButtonLoader>
        ) : (
          <IconButton icon={ addContact } onClick={ onAddContact } />
        )}
      </AddContactWrapper>

      {!contacts.length && (
        <EmptyState
          text={ t('no results found') }
          icon={ emptyFilter }
        />
      )}

      { categoryOrdenator(categoriesToShow, userProfiles)?.map(category => (
        <GroupWrapper key={ category?.field }>
          <GroupDropdown
            opened={ openedCategories[category?.field] }
            onClick={ () => setOpenedCategories(state => ({
              ...state,
              [category?.field]: !state[category?.field]
            })) }
          >
            <Icon icon={ arrowRightSimple } size={ 15 } color={ colors.darkGrey } />
            <GroupTitle>{category?.description}</GroupTitle>
          </GroupDropdown>

          {openedCategories[category?.field] && (
            contactsByCategory[category?.field]?.map(contact => (
              <ContactListItem
                key={ contact?.id }
                contact={ contact }
                userId={ contact?.contactId }
                onStartConversation={ onStartConversation }
                onRemoveContact={ onRemoveContact }
              />
            ))
          )}
        </GroupWrapper>
      )) }
    </Card>
  )
}

ContactList.propTypes = {
  onStartConversation: PropTypes.func.isRequired,
  contacts: PropTypes.array
}

ContactList.defaultProps = {
  contacts: []
}


const enhance = withObservables(['contactName'], ({ contactName }) => ({
  contacts: contactService(database).observeContactsByFilter(contactName)
}))

const EnhancedContactList = enhance(ContactList)

export default EnhancedContactList
