import React, { useCallback, useState, useMemo, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import PropTypes from 'prop-types'

import  I18n, { useT } from '@smartcoop/i18n'
import { useSnackbar } from '@smartcoop/snackbar'
import { DairyFarmActions } from '@smartcoop/stores/dairyFarm'
import { selectModuleIsTechnical } from '@smartcoop/stores/module/selectorModule'
import { selectFamilyGroupAccess, selectFamilyGroupFinancialDataAccess } from '@smartcoop/stores/property/selectorProperty'
import { selectCurrentOwnerExtraAttributes, selectTechnicalFinancialDataAccess } from '@smartcoop/stores/technical/selectorTechnical'
import { selectUserExtraAttributes } from '@smartcoop/stores/user/selectorUser'
import Loader from '@smartcoop/web-components/Loader'
import Modal from '@smartcoop/web-components/Modal'
import RegisterMonthlyDataHistoryForm from '@smartcoop/web-containers/forms/digitalProperty/dairyFarm/RegisterMonthlyDataHistoryForm'

const RegisterMonthlyDataHistoryModal = (props) => {
  const {
    id,
    open,
    handleClose,
    defaultValues: defaultValuesProp,
    handleSuccess
  } = props

  const userExtraAttributes = useSelector(selectUserExtraAttributes)
  const currentOwnerExtraAttributes = useSelector(selectCurrentOwnerExtraAttributes)

  const technicalFinancialDataAccess = useSelector(selectTechnicalFinancialDataAccess)
  const familyGroupFinancialDataAccess = useSelector(selectFamilyGroupFinancialDataAccess)

  const familyGroupAccess = useSelector(selectFamilyGroupAccess)
  const isTechnical = useSelector(selectModuleIsTechnical)

  const extraAttributes = useMemo(() => userExtraAttributes || {}, [userExtraAttributes])

  // eslint-disable-next-line no-nested-ternary
  const shouldDisplayCosts = useMemo(() => familyGroupAccess ? familyGroupFinancialDataAccess : isTechnical ? technicalFinancialDataAccess && currentOwnerExtraAttributes?.enabledCosts : extraAttributes?.enabledCosts,[currentOwnerExtraAttributes, extraAttributes, familyGroupAccess, familyGroupFinancialDataAccess, isTechnical, technicalFinancialDataAccess])

  const formRef = useRef(null)
  const snackbar = useSnackbar()
  const dispatch = useCallback(useDispatch(), [])
  const t = useT()

  const [isLoading, setIsLoading] = useState(false)
  const [formValues, setFormValues] = useState({})

  const defaultValues = useMemo(
    () => (
      {
        ...formValues,
        ...defaultValuesProp
      }
    ), [formValues, defaultValuesProp]
  )

  const closeModal = useCallback(
    () => {
      setIsLoading(false)
      handleClose()
    }, [handleClose]
  )

  const onSuccess = useCallback(
    () => {
      setIsLoading(false)

      snackbar.success(
        t(`your {this} was ${ defaultValues?.id ? 'edited' :'registered' }`, {
          howMany: 2,
          gender: 'male',
          this: t('production data')
        })
      )

      handleSuccess()
      closeModal()

    }, [closeModal, defaultValues.id, handleSuccess, snackbar, t]
  )

  const handleSubmit = useCallback(
    (data) => {
      setIsLoading(true)
      setFormValues(data)
      dispatch(DairyFarmActions.saveMonthlyDataHistory(
        {
          id: defaultValues?.id,
          ...data
        },
        () => onSuccess(),
        () => setIsLoading(false)
      ))
    },
    [defaultValues, dispatch, onSuccess]
  )

  return (
    <Modal
      id={ id }
      open={ open }
      title={
        <>
          <I18n>monthly data logging</I18n>
        </>
      }
      disableFullScreen
      disableEsc
      contentContainerStyle={ { padding: 0, minWidth: 300 } }
      maxWidth={ shouldDisplayCosts ? 'md' : 'sm' }
      fullWidth
    >
      <>
        {
          isLoading ? <Loader width={ 100 } /> :
            <RegisterMonthlyDataHistoryForm
              ref={ formRef }
              defaultValues={ defaultValues }
              onSubmit={ handleSubmit }
              onCancel={ closeModal }
              shouldDisplayCosts={ shouldDisplayCosts }
            />
        }
      </>
    </Modal>
  )}

RegisterMonthlyDataHistoryModal.propTypes = {
  id: PropTypes.string.isRequired,
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func,
  defaultValues: PropTypes.object,
  handleSuccess: PropTypes.func
}

RegisterMonthlyDataHistoryModal.defaultProps = {
  handleClose: () => {},
  defaultValues: {},
  handleSuccess: () => {}
}

export default RegisterMonthlyDataHistoryModal
